import React, { useState, useEffect, useRef } from "react";
import Links from "./../../../data/link";
import DatePickerRangeAtm from "./../../component/DatePickerRangeAtm";
import MapCatastroPublic from "./../../../components/component/MapCatastroPublic";   //MapCatastroPublic
import MapCatastroPublicModal from "./../../component/MapCatastroPublicModal";
import HeaderTitleCard from "./../_common/HeaderTitleCard";
import DescriptionZonaComunaMapAdv from "./../_common/DescriptionZonaComunaMapAdv";
import Constant from "./../../../data/constant";
import Consulta from "./../Consulta";
import Funciones from "./../_common/Funciones";

// import DefaultInmuebleCatastro from "./../VisacionMinutas/DefaultCatatroInmueble";

// dataCategoryPublicity (tipo publicidad)
const Step1EventualFormValideDatosTecnicos = (props) => {
    const { fetch, toast, nextClick, auth, history, dataClasification, dataCategoryPublicity,
        dataAdvertising, calculoSuperficieM2, stepNumber, Texto, urlPdfRepositorioMain,
        loadInmuebleCatastro, findInmueble, findActEcoByNumero, findContribuyenteByCodContribuyente } = props;

    const idMapAdvertising = "idMapCatastroPublicAdvertising";
    const idMapActEco = "idMapCatastroPublicAdvertising";
    const TYPE_MAP_ACT_ECO = "ACT_ECO";
    const TYPE_MAP_ADV = "ADV";

    const [optionsCategory, setOptionsCategory] = useState([]);
    const [showMapAdvertising, setShowMapAdvertising] = useState(false);
    const [dataTechnicalModificado, setDataTechnicalModificado] = useState(false);
    const [dataActEconomicaModificado, setDataActEconomicaModificado] = useState(false);

    //const [dataInmuebleCatastro, setDataInmuebleCatastro] = useState({});
    const [dataInmuebleAdvertising, setDataInmuebleAdvertising] = useState({});
    const [dataActividadEconomicaRuat, setDataActividadEconomicaRuat] = useState(undefined);
    const [dataInmuebleCatastroInfo, setDataInmuebleCatastroInfo] = useState(null);
    const [zonaInfo, setZonaInfo] = useState(null);

    //const arrayZonaProhibidaPasacalle = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

    // message
    const [messageError, setMessageError] = useState(null);

    const refSelectCategory = useRef(dataCategoryPublicity.id);
    const refInputNroActividadEco = useRef(null);
    const refInputAncho = useRef(0);
    const refInputAlto = useRef(0);
    const refInputDimension = useRef(0);
    const refInputDireccion = useRef(null);
    const refInputDireccionY = useRef(null);
    const refInputDireccionEntre = useRef(null);
    const refInputRazonSocial = useRef(null);

    const consultaSQL = new Consulta();
    const funciones = new Funciones(fetch);
    const maxDateDatePicker = window.moment().add(29, 'days');
    const resultadoDivPasacalle = document.getElementById('resultado_pasacalle');
    const resultadoDivGeoreferencial = document.getElementById('resultado_georeferencial');

    // datos de la publicidad
    const dateStart = dataAdvertising.dateStart;
    const dateExpires = dataAdvertising.dateExpires;
    const totalDay = window.moment(dateExpires).diff(window.moment(dateStart), 'days') + 1;
    const [totalDias, setTotalDias] = useState({ mes: 0, dias: 0 });

    // ancho - alto
    const ancho = (dataAdvertising.AdDataTechnical.ancho);
    const alto = (dataAdvertising.AdDataTechnical.alto);
    const superficie = (dataAdvertising.AdDataTechnical.dimension);
    // datos ubicacion
    const AdUbicacions = dataAdvertising.AdDataTechnical.AdUbicacions[0];
    const direccion = AdUbicacions.direccion;
    const direccionEntre = AdUbicacions.direccionEntre;
    const direccionY = AdUbicacions.direccionY;
    // act. economica
    const nroActividad = dataAdvertising.AdActividadEconomicaRuat ? dataAdvertising.AdActividadEconomicaRuat.nroActividad : null

    const idImgLocationImg = "imgAdvertising01";

    useEffect(() => {
        if (!auth.loggedIn()) history.replace(Links[4].url);

        debugger
        if (dataClasification) {
            optionsSelectCategory(dataClasification.id);
            refInputAncho.current.value = (ancho);
            refInputAlto.current.value = (alto);
            refInputDimension.current.value = (superficie);
            refInputDireccion.current.value = direccion;
            refInputDireccionEntre.current.value = direccionEntre;
            refInputDireccionY.current.value = direccionY;

            refInputNroActividadEco.current.value = nroActividad;

            const dataInfo = {
                comuna: dataAdvertising.AdDataTechnical.AdUbicacions[0].comuna,
                zonaTributaria: dataAdvertising.AdDataTechnical.AdUbicacions[0].zonaTributaria,
                codCatastral: dataAdvertising.AdDataTechnical.AdUbicacions[0].codigoCatastral
            };

            setDataInmuebleCatastroInfo(dataInfo);
            setZonaInfo(dataInfo.zonaTributaria);
            setDataInmuebleAdvertising(dataAdvertising.AdDataTechnical);
            handleImageDefault();
            if (nroActividad) {
                eventInputSearchActEconomica();
            }
        }

    }, [dataCategoryPublicity]);


    // tipos de publicidades
    const optionsSelectCategory = (idClasification) => {

        const query = consultaSQL.queryCategoryPublicity(idClasification);
        fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {

            if (data.categoryPublicities && data.categoryPublicities.result) {
                const category = data.categoryPublicities.data.clasificacionCategoryPublicity;

                // Mapeo para crear <optgroup> y <option> en un solo proceso
                const listItems = category.map((item, index) => {
                    const categoryPublicity = item.categoryPublicity;

                    // Si tiene hijos, creamos un <optgroup>
                    if (categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity) {
                        const children = category
                            .filter((itemC) => itemC.categoryPublicity.idFatherCategoryPublicity === categoryPublicity.id)
                            .map((itemC, indexC) => (
                                <option key={indexC} value={itemC.categoryPublicity.id} name={itemC.categoryPublicity.name}>
                                    {itemC.categoryPublicity.name}
                                </option>
                            ));

                        return (
                            <optgroup key={index} value={categoryPublicity.id} label={categoryPublicity.name}>
                                {children}
                            </optgroup>
                        );
                    }

                    // Si no tiene hijos, creamos una opción individual
                    if (!categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity) {
                        return (
                            <option key={index} value={categoryPublicity.id} name={categoryPublicity.name}>
                                {categoryPublicity.name}
                            </option>
                        );
                    }
                }).filter(Boolean); // Filtramos elementos nulos

                // Establecemos las opciones en el estado
                setOptionsCategory(listItems);
                refSelectCategory.current.value = dataCategoryPublicity.id;
            }
        });
    };

    const handleImageDefault = () => {
        debugger
        const urlImg = AdUbicacions?.urlImagen;
        const urlFileImage = urlPdfRepositorioMain + urlImg;

        if (urlImg && urlFileImage) {
            const imgIconButton = document.getElementById(idImgLocationImg);
            imgIconButton.src = urlFileImage;
            imgIconButton.classList.remove("d-none");
        }
    }

    //  image
    const configureData = (data, typeMap) => {
        setDataTechnicalModificado(false)
        debugger

        if (typeMap === TYPE_MAP_ADV) {
            setDataInmuebleAdvertising(data);
            setDataTechnicalModificado(true);
        }
    }

    const handleEventMapAdvertising = async (dataInmuebleCatastro) => {
        debugger

        const imgIconButton = document.getElementById("imgAdvertising01");
        imgIconButton.src = dataInmuebleCatastro.fullBase6image4;
        imgIconButton.classList.remove("d-none");

        setShowMapAdvertising(false);
        setDataInmuebleCatastroInfo(null);
        setZonaInfo(null);

        if (dataInmuebleCatastro.nroInmueble && parseInt(dataInmuebleCatastro.nroInmueble) > 0) {
            const inmueble = await findInmueble(dataInmuebleCatastro.nroInmueble, "NIM");
            debugger
            if (inmueble) {
                const data = {
                    inmueble: inmueble.data.resInmueble,
                    datosTecnicos: inmueble.data.resTecnico,
                    datosCatastro: dataInmuebleCatastro
                };
                setDataInmuebleCatastroInfo({
                    comuna: dataInmuebleCatastro.comuna,
                    zonaTributaria: dataInmuebleCatastro.zonaTributaria,
                    codCatastral: inmueble.data.resInmueble.codigoCatastral
                });
                setZonaInfo(dataInmuebleCatastro.zonaTributaria)
                configureData(data, TYPE_MAP_ADV);
            } else {
                //aqui cargar el por default
                configureData({}, TYPE_MAP_ADV);
            }
        }
    }

    const handleOpenMapGeneric = async (event, type) => {
        debugger
        handleHiddenDivMessage();
        setZonaInfo(null)

        if (type === TYPE_MAP_ACT_ECO) {
            setShowMapAdvertising(false);
        }

        if (type === TYPE_MAP_ADV) {
            setShowMapAdvertising(true);
        }
        const dataInmueble = {
            codigoCatastral: undefined
        }
        loadInmuebleRuat(dataInmueble, type);
    }

    const loadInmuebleRuat = async (dataInmueble, type) => {
        debugger
        if (
            !dataInmueble.codigoCatastral
        ) {
            setTimeout(() => {
                const idMap = type === TYPE_MAP_ADV ? idMapAdvertising : idMapActEco;
                const modalCatastro = new window.bootstrap.Modal(
                    document.getElementById(idMap)
                );
                modalCatastro.show();
            }, 100);
        } else {
            await loadInmuebleCatastro(dataInmueble.codigoCatastral);
        }
    };

    const handleOnSearchPorNroActEconomica = async (event) => {
        event.preventDefault();
        refInputRazonSocial.current.value = "";
        setMessageError(null);

        debugger
        await eventInputSearchActEconomica();
    }

    const eventInputSearchActEconomica = async () => {
        debugger
        let input = refInputNroActividadEco.current;
        let numeroActividad = input.value;
        setDataActividadEconomicaRuat(undefined);
        setDataActEconomicaModificado(false);
        if (numeroActividad && numeroActividad.length >= 3) {
            debugger
            const actividadEconomicaRuat = await findActEcoByNumero(numeroActividad);

            if (actividadEconomicaRuat) {
                debugger
                console.log('findActEcoByNumero : ', actividadEconomicaRuat, actividadEconomicaRuat.razonSocial); //tipoActividad
                //aqui buscar 
                const propietariosActEco = actividadEconomicaRuat.propiedades;   //solo tomar el primero
                //const ubicacionActEco = actividadEconomicaRuat.datosUbicacion;
                // console.log("ubicacionActEco.comuna ", ubicacionActEco.comuna);
                if (actividadEconomicaRuat.razonSocial !== undefined) {
                    refInputRazonSocial.current.value = actividadEconomicaRuat.tipoActividad + "(" + actividadEconomicaRuat.razonSocial + ")";
                }

                if (propietariosActEco.length > 0 && propietariosActEco[0].codigoContribuyente) {
                    const contribuyente = await findContribuyenteByCodContribuyente(propietariosActEco[0].codigoContribuyente);
                    let ci = 0;
                    if (contribuyente && contribuyente.natural)
                        ci = contribuyente.natural.numeroDocumento;
                    setDataActividadEconomicaRuat(actividadEconomicaRuat);

                    if (numeroActividad != nroActividad) {
                        setDataActEconomicaModificado(true);
                    }
                }
            } else {
                debugger
                setMessageError('No existe el Número de Actividad Económica');
                toast.error('No existe el Número de Actividad Económica');
            }
        }
    }

    const handleOpenImageAdvertisingBase64 = (event) => {
        const link = event.target;
        const image = new Image();
        image.src = link.src;
        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    const nextForm = async (e) => {
        debugger;
        e.persist();
        handleHiddenDivMessage();
        const inputRangeDate = document.getElementsByName("datosTecnicos-tiempo");
        const selectCategory = refSelectCategory.current;
        const inputAncho = refInputAncho.current;
        const inputAlto = refInputAlto.current;
        const inputDireccion = refInputDireccion.current;
        const inputDireccionEntre = refInputDireccionEntre.current;
        const inputDireccionY = refInputDireccionY.current;

        let next = true;
        const data = {
            advertising: {
                clasification: dataClasification.id,
                inmueble: dataInmuebleAdvertising,
                category: selectCategory.selectedOptions[0].value,
                categoryNombre: selectCategory.options[selectCategory.selectedIndex].text,
                ancho: inputAncho.value,
                alto: inputAlto.value,
                direccion: inputDireccion.value,
                direccionEntre: inputDireccionEntre.value,
                directionY: inputDireccionY.value,
                rangeDate: inputRangeDate[0].value
            },
            actividadEconomica: {
                actividadEconomicaRuat: dataActividadEconomicaRuat,   //esto ver de como fusionar
            },
            modificado: {
                datostecnicos: dataTechnicalModificado,
                actividadEconomica: dataActEconomicaModificado
            }
        };

        if (!zonaInfo && dataTechnicalModificado) {
            resultadoDivGeoreferencial.classList.remove("d-none");
            next = false;
        }
        
        const tieneRestricction = await funciones.hasRestriction(zonaInfo, selectCategory.selectedOptions[0].value);
        if (tieneRestricction) {
            resultadoDivPasacalle.classList.remove("d-none");
            next = false;
        }else {
            if (dataInmuebleAdvertising.AdUbicacions && !dataInmuebleAdvertising.AdUbicacions[0].zonaTributaria) {
                next = false;
                toast.error("Zona Tributaria, es obligatorio");
            }
        }

        // validar datos de la ubicacion geoespacial
        if (Object.keys(dataInmuebleAdvertising).length === 0 && dataInmuebleAdvertising.constructor === Object) {
            debugger
            next = false;
            toast.error("Ubicación Geoespacial de la Publicidad, es obligatorio");
        }

        if (dataActividadEconomicaRuat && Object.keys(dataActividadEconomicaRuat).length === 0 && dataActividadEconomicaRuat.constructor === Object) {
            debugger
            next = false;
            toast.error("Actividad Económica no localizada, debe proceder con el trámite de la licencia.");
        }

        if (next) {
            //window.scrollTo(0, 0);
            nextClick(e, stepNumber, true, data)
        }
    }

    /*const hasRestriction = async (zona, idCategoryPublicity) => {
        const query = consultaSQL.queryAreaHomogeneaRestricction(zona, idCategoryPublicity);
        const restriction = await fetch.fetchPostGraphQl(query, "/graphql", null);

        if (restriction && restriction.findRectricctionByZone && restriction.findRectricctionByZone.result) {
            return true;
        }
        return false;
    }*/

    // calculo de superficie en M2
    const onChangeSuperficie = () => {
        if (refInputAlto.current.value && refInputAncho.current.value) {
            const area = calculoSuperficieM2(refInputAlto.current.value, refInputAncho.current.value);
            refInputDimension.current.value = area;
        }
    }

    // hiden div message
    const handleHiddenDivMessage = () => {
        if (!resultadoDivPasacalle.classList.contains("d-none") || !resultadoDivGeoreferencial.classList.contains("d-none")) {
            resultadoDivPasacalle.classList.add("d-none");
            resultadoDivGeoreferencial.classList.add("d-none");
        }
    }

    return (
        <>
            <div className="main-wizard active-f-w">
                <HeaderTitleCard titulo={'Datos Técnicos'} descripcion={Constant[0].publicidad_step_new[1]} visibleRequired={true} />

                <form
                    className="needs-validation"
                    id={"formValideDatosTecnicos"}
                    noValidate
                >
                    <div className="mt-2">
                        <label htmlFor="datosTecnicos-category" className="form-label">(*) Tipo Publicidad</label>
                        <select className="form-select" ref={refSelectCategory}
                            data-parsley-required="true"
                            name="datosTecnicos-category" >
                            <option
                                key={0}
                                defaultValue
                                value=""
                            >
                                Seleccione Tipo Publicidad
                            </option>
                            {optionsCategory}
                        </select>
                    </div>

                    {/* fechas */}
                    <div className="row mt-2 mb-3">
                        <div className="col-sm-12 col-md-6 ">
                            <label htmlFor="datosTecnicos-tiempo" className="form-label">(*) Fechas</label>
                            <DatePickerRangeAtm
                                placeHolder={"Rango de Fechas (días)"}
                                name={"datosTecnicos-tiempo"}
                                id={"datosTecnicos-tiempo"}
                                maxDate={maxDateDatePicker}
                                minDate={new Date(dateStart)}
                                setTotalDias={setTotalDias}
                                mandatory={true}
                                startDateSelected={dateStart}
                                endDateSelected={dateExpires}
                            />
                        </div>
                        <div className="col-sm-12 col-md-6" >
                            <label htmlFor="total_dias_info" className="form-label">(*) Días Autorización</label>
                            <input type="text" id="total_dias_info" value={totalDias.dias ? totalDias.dias : (window.moment(dateExpires).diff(window.moment(dateStart), 'days')) + 1} data-parsley-required="true" readOnly={true} />
                        </div>
                    </div>

                    <fieldset className="">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.dimension_publicidad}
                        </label>
                        </legend>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-ancho" className="form-label">(*) Ancho(metros)</label>
                                <input type="text"
                                    id="datosTecnicos-ancho"
                                    name="datosTecnicos-ancho"
                                    ref={refInputAncho}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    data-parsley-trigger="keyup"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="datosTecnicos-alto" className="form-label">(*) Alto (metros)</label>
                                <input type="text"
                                    id="datosTecnicos-alto"
                                    name="datosTecnicos-alto"
                                    ref={refInputAlto}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <label htmlFor="datosTecnicos-superficie-m2" className="form-label">(*) Superficie M2</label>
                                <input type="text"
                                    id="datosTecnicos-superficie-m2"
                                    name="datosTecnicos-superficie-m2"
                                    min={0.01}
                                    max={999}
                                    readOnly={true}
                                    ref={refInputDimension}
                                    data-parsley-required="true"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.ubicacion_publicidad}
                        </label>
                        </legend>

                        <div className="form-group text-center mt-1 mb-1">
                            <button type="button" className="next_button btn btn-violeta"
                                onClick={(e) => handleOpenMapGeneric(e, TYPE_MAP_ADV)}>
                                {Texto.ubicacion_geoespacial_publicidad}
                                <i className="fas fa-map-marker-alt ps-2"></i>
                            </button>
                        </div>

                        <div className="form-group">
                            <img
                                id={"imgAdvertising01"}
                                className="img-fluid mx-auto d-block pointer rounded border border-secondary d-none mb-2"
                                style={{ height: '70%' }}
                                onClick={(e) => handleOpenImageAdvertisingBase64(e)}
                                alt="img-domicilio-advertising"
                                src={""}
                            />

                            {
                                dataInmuebleCatastroInfo ? <DescriptionZonaComunaMapAdv dataInmuebleCatastroInfo={dataInmuebleCatastroInfo} /> : ""
                            }
                            <div id="resultado_pasacalle" style={{ fontSize: 11.5 }} className="alert alert-danger p-2 mb-2 d-none" role="alert">
                                <b>Atención: </b> Zona no autorizada para la categoria seleccionada.
                            </div>

                            <div id="resultado_georeferencial" style={{ fontSize: 11.5 }} className="alert alert-danger p-2 mb-2  d-none" role="alert">
                                <b>Atención: </b> Inmueble no actualizado, consigne el inmueble maś cercano.
                            </div>
                        </div>

                        <div className="">
                            <label htmlFor="datosTecnicos-direccion" className="form-label">(*) Dirección</label>
                            <input
                                type="text"
                                id="datosTecnicos-direccion"
                                name="datosTecnicos-direccion"
                                ref={refInputDireccion}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección donde se emplazará la publicidad"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                autoComplete="one-time-code"
                                placeholder="Calle/Avenida/Pasaje/Parque/Plazuela/etc."
                            />
                        </div>

                        <div className=" mt-2">
                            <label htmlFor="adyacentes" className="form-label">(*) Adyacentes</label>
                        </div>

                        <div className="input-group">
                            <input
                                type="text"
                                id="ubicacion-adyacente_entre"
                                name="ubicacion-adyacente_entre"
                                ref={refInputDireccionEntre}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />

                            <div className="input-group-prepend">
                                <span className="input-group-text" id="">Y</span>
                            </div>
                            <input
                                type="text"
                                id="ubicacion-adyacente_y"
                                name="ubicacion-adyacente_y"
                                ref={refInputDireccionY}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />
                        </div>

                        <div className="row">
                            <div className="col-6" id="error_adyacente_entre"></div>
                            <div className="col-6" id="error_adyacente_y"></div>
                        </div>
                    </fieldset>

                    {/* datos domicilio publicidad */}
                    <fieldset className="">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            Datos de la Actividad Económica
                        </label>
                        </legend>

                        <div className="row mb-3 mt-2">
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="datosTecnicos-nroActividad" className="form-label">Licencia Funcionamiento (Opcional)</label>
                                <div className="input-group ">
                                    <input
                                        type="text"
                                        className="form-control text-uppercase"
                                        id={"input-nroactividad"}
                                        name="datosTecnicos-nroActividad"
                                        aria-label="Nro. Actividad Económica"
                                        aria-describedby="basic-addon2"
                                        data-parsley-validate-if-empty={false}
                                        data-parsley-type="number"
                                        data-parsley-number-message="Ingrese solo ńumeros"
                                        ref={refInputNroActividadEco}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={handleOnSearchPorNroActEconomica}
                                        >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                {messageError && <div className="col-12 parsley-errors-list filled"> {messageError}</div>}
                            </div>
                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="DatosTecnicos-actividad" className="form-label">Nombre o Razón Social</label>
                                <input
                                    type={"text"}
                                    name="DatosTecnicos-actividad"
                                    readOnly
                                    ref={refInputRazonSocial}
                                    className="form-control"
                                    data-parsley-required-if="#input-nroactividad" />
                            </div>
                        </div>
                    </fieldset>

                    <div className="buttons text-end">
                        <button type="button" className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
                    </div>
                </form>
            </div>

            {showMapAdvertising ? <MapCatastroPublicModal
                module={"antenas"}
                id={idMapAdvertising}
                nameForm={""}
                fillPaint={true}
                width="724"
                height="261"
                //mode="basic"
                title="Ubicación Publicidad"
                handleEventMap={handleEventMapAdvertising}
            /> : ""}
        </>
    );
};

export default Step1EventualFormValideDatosTecnicos;
