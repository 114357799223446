import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Toast from "../../components/utils/Toast";
import TitlePage from "../../components/utils/TitlePage";
import Constant from "../../data/constant";
import Fetch from "../../components/utils/Fetch";
import Links from "../../data/link";
import Languaje from "../../data/es";
import AuthService from "../../components/Usuario/AuthService";
import DataTable from "react-data-table-component";
import styled from "styled-components"; //styled for data table
import "../style/input-file-drag.css";
import "../style/parsley.css";
import PerfilOptionVehiculo from "./PerfilOptionVehiculo";
import PerfilOptionInmueble from "./PerfilOptionInmueble";
import PerfilOptionUsuario from "./PerfilOptionUsuario";
import PerfilOptionVarios from "./PerfilOptionVarios";
import PerfilForm from "./PerfilForm";

const columns = [
  {
    name: Languaje.creado,
    selector: "created_at",
    sortable: true,
    maxWidth: "150px",
  },

  {
    name: Languaje.ip,
    center: false,
    sortable: true,
    maxWidth: "170px",
    cell: (row) => <div>{JSON.parse(row.data_new).ip}</div>,
  },
  {
    name: Languaje.dispositivo,
    center: false,
    sortable: true,
    hide: "sm",
    cell: (row) => <div>{JSON.parse(row.data_new).device}</div>,
  },
  {
    name: Languaje.navegador,
    center: false,
    sortable: true,
    hide: "sm",
    cell: (row) => <div>{JSON.parse(row.data_new).navegador}</div>,
  },
  {
    name: Languaje.action,
    selector: "action",
    sortable: true,
    hide: "md",
    maxWidth: "50px",
  },
];

const detector = new window.MobileDetect(window.navigator.userAgent);
const columnsActivity = [
  {
    name: Languaje.creado,
    selector: "created_at",
    sortable: true,
    maxWidth: "150px",
  },
  {
    name: Languaje.description,
    center: false,
    sortable: true,
    cell: (row) => (
      <>
        {detector.mobile()
          ? window.innerWidth < 768
            ? row.description.substring(0, 16) + "..."
            : window.innerWidth >= 768 && window.innerWidth < 1024
            ? row.description.substring(0, 60) + "..."
            : row.description
          : row.description}
      </>
    ),
  },
];

const DivExpandedStyle = styled.div`
  padding: 10px;
  display: block;
  width: 100%;
  p {
    font-size: 12px;
    word-break: break-all;
  }
`;

const HistoryExpandedComponent = ({ data }) => (
  <DivExpandedStyle>
    <p>
      <strong>{Languaje.creado}: </strong>
      {data.created_at}
    </p>
    <p>
      <strong>{Languaje.ip}: </strong>
      {JSON.parse(data.data_new).ip}
    </p>
    <p>
      <strong>{Languaje.navegador}: </strong>
      {JSON.parse(data.data_new).navegador}
    </p>
    <p>
      <strong>{Languaje.action}: </strong>
      {data.action}
    </p>
    <p>
      <strong>{Languaje.dispositivo}: </strong>
      {JSON.parse(data.data_new).device}
    </p>
  </DivExpandedStyle>
);

const ActivityExpandedComponent = ({ data }) => (
  <DivExpandedStyle>
    <p>
      <strong>{Languaje.creado}: </strong>
      {data.created_at}
    </p>
    <p>
      <strong>{Languaje.description}: </strong>
      {data.description}
    </p>
  </DivExpandedStyle>
);

var _usuario = [];
var sessionUser = null;
var fetch = null;
class Perfil extends Component {
  constructor(props, context) {
    super(props, context);

    this.hanldePerfilClick = this.hanldePerfilClick.bind(this);
    this.onSubmitFormDatos = this.onSubmitFormDatos.bind(this);
    this.onSubmitFormChangePassword =
      this.onSubmitFormChangePassword.bind(this);
    this.onSubmitFormImage = this.onSubmitFormImage.bind(this);

    //this.handleVehiculos = this.handleVehiculos.bind(this);
    //this.hanldeInmuebles = this.hanldeInmuebles.bind(this);
    this.handleObjetoTributario = this.handleObjetoTributario.bind(this);

    this.fetch = new Fetch();
    this.toast = new Toast();
    this.fetch.setToast(this.toast.getToast());

    fetch = this.fetch;
    this.Auth = new AuthService();

    this.state = {
      _usuario: [],
      showPerfil: true,
      showHistorySession: false,
      showActivity: false,
      optionsSelect: [],
      startDate: null,
      totalRows: 0,
      perPage: 10,
      totalRowsActivity: 0,
      data: [],
      loading: false,
      dataActivity: [],
      loadingActivity: false,
      rol: undefined,
      showVehiculos: false,
      showInmuebles: false,
      showVarios: false,
      showDataUsuario: false,
    };
    this.titlePage = Languaje.perfil;
  }

  componentDidMount() {
    if (this.Auth.loggedIn()) {
      this.getDatausuario();
      let userAuth = this.Auth.getProfile();
      this.setState({ rol: userAuth.code });
    } else this.props.history.replace(Links[4].url);
  }

  componentDidUpdate() {
    window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
  }

  hanldePerfilClick(event) {
    event.preventDefault();
    this.setState({
      showPerfil: true,
      showHistorySession: false,
      showActivity: false,
      showDataUsuario: false,
    });
    this.getDatausuario();
  }

  handleLoginHistory = async (event) => {
    event.preventDefault();
    this.setState({
      loading: true,
      showPerfil: false,
      showHistorySession: true,
      showActivity: false,
      showInmuebles: false,
      showVehiculos: false,
      showDataUsuario: false,
    });

    const response = await this.fetch.fetchGet(
      `api/log/history-login-by-token-user/1/${this.state.perPage}/${sessionUser.token}`
    );
    if (response && response.status) {
      this.setState({
        data: response.Log,
        totalRows: response.total,
        loading: false,
      });
    }
  };

  handleActivity = async (event) => {
    event.preventDefault();
    this.setState({
      loadingActivity: true,
      showPerfil: false,
      showHistorySession: false,
      showActivity: true,
      showInmuebles: false,
      showVehiculos: false,
      showDataUsuario: false,
    });
    const response = await this.fetch.fetchGet(
      `api/log/activity-by-token-user/1/${this.state.perPage}/${sessionUser.token}`
    );
    if (response && response.status) {
      this.setState({
        dataActivity: response.Log,
        totalRowsActivity: response.total,
        loadingActivity: false,
      });
    }
  };

  handleObjetoTributario = async (event, objetoTributario) => {
    event.preventDefault();
    if (objetoTributario === "VARIOS")
      this.setState({
        loadingActivity: true,
        showPerfil: false,
        showHistorySession: false,
        showActivity: false,
        showInmuebles: false,
        showVehiculos: false,
        showDataUsuario: false,
        showVarios: true,
      });

    if (objetoTributario === "INMUEBLES")
      this.setState({
        loadingActivity: true,
        showPerfil: false,
        showHistorySession: false,
        showActivity: false,
        showInmuebles: true,
        showVehiculos: false,
        showDataUsuario: false,
        showVarios: false,
      });

    if (objetoTributario === "VEHICULOS")
      this.setState({
        loadingActivity: true,
        showPerfil: false,
        showHistorySession: false,
        showActivity: false,
        showInmuebles: false,
        showVehiculos: true,
        showDataUsuario: false,
        showVarios: false,
      });
  };

  handleUsuarioClick = (event) => {
    event.preventDefault();
    this.setState({
      loadingActivity: true,
      showPerfil: false,
      showHistorySession: false,
      showActivity: false,
      showInmuebles: false,
      showVehiculos: false,
      showDataUsuario: true,
    });
  };

  getDatausuario = () => {
    sessionUser = this.Auth.getProfile();
    var self = this;
    _usuario = [];
    const response = this.fetch.fetchGet(
      `api/usuario/get-by-token/${sessionUser.token}`
    );
    response.then((res) => {
      if (res && res.status) {
        if (Boolean(res.data) && Boolean(res.data.Usuario)) {
          let usuario = res.data.Usuario;
          _usuario.push(
            <li key={1}>
              {" "}
              <span>Username: </span>
              {usuario.username}
            </li>
          );
        }

        if (Boolean(res.data) && Boolean(res.data.DatosUsuario)) {
          let datos = res.data.DatosUsuario;
          _usuario.push(
            <li key={2}>
              {" "}
              <span>Nombre: </span>
              {datos.name}
            </li>
          );
          _usuario.push(
            <li key={3}>
              {" "}
              <span>Apellido Paterno: </span>
              {datos.apellido_paterno}
            </li>
          );
          _usuario.push(
            <li key={4}>
              {" "}
              <span>Apellido Materno: </span>
              {datos.apellido_materno}
            </li>
          );
          _usuario.push(
            <li key={5}>
              {" "}
              <span>Documento de Identificación: </span>
              {datos.ci}
            </li>
          );
          _usuario.push(
            <li key={6}>
              {" "}
              <span>Institución: </span>
              {datos.company}
            </li>
          );
          _usuario.push(
            <li key={7}>
              {" "}
              <span>Dirección: </span>
              {datos.address}
            </li>
          );

          let prefijo = "";
          if (Boolean(res.data) && Boolean(res.data.Pais)) {
            _usuario.push(
              <li key={8}>
                {" "}
                <span>Pais: </span>
                {res.data.Pais.name}
              </li>
            );
            prefijo = res.data.Pais.code_phone;
          }

          _usuario.push(
            <li key={9}>
              {" "}
              <span>Teléfono: </span>
              {prefijo + "-" + datos.phone}
            </li>
          );

          if (Boolean(datos.image))
            document.getElementById("avatarPerfilUser").src = datos.image;
        }
        self.setState({
          usuario: _usuario,
        });
        this.toast.success(res.message, "RIGHT");
      }
    });
  };

  onSubmitFormDatos(event) {
    event.preventDefault();
    const form = new FormData(event.target);
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();

    var li_error = window.jQuery("#inputDatosPhone").siblings("ul");
    window.jQuery("#inputDatosPhone").siblings("ul").remove();
    window
      .jQuery("#inputDatosPhone")
      .parent("div")
      .parent("div")
      .append(li_error);

    if (
      window
        .jQuery("#" + event.target.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      this.fetch
        .fetchPost(form, "api/datos-usuario/create")
        .then((dataJson) => {
          if (dataJson && dataJson.status) {
            this.toast.success(dataJson.message, "RIGHT");
          }
        });
    } else {
    }
  }

  onSubmitFormChangePassword(event) {
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();
    const form = new FormData(event.target);
    if (
      window
        .jQuery("#" + event.target.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      this.fetch
        .fetchPost(form, "api/usuario/change-password-members")
        .then((dataJson) => {
          if (dataJson && dataJson.status) {
            this.toast.success(dataJson.message, "RIGHT");
          }
        });
    }
  }

  onSubmitFormImage(event) {
    event.preventDefault();
    window
      .jQuery("#" + event.target.getAttribute("id"))
      .parsley()
      .validate();
    const form = new FormData(event.target);

    if (
      window
        .jQuery("#" + event.target.getAttribute("id"))
        .parsley()
        .isValid()
    ) {
      var input = document.getElementsByName("usuariofile")[0];
      var file = input.files[0],
        self = this;

      if (file) {
        window.blobToBase64(file, function (base64) {
          form.append("usuario[mime_type_image]", file.type);
          form.append("usuario[image]", base64.replace(/\s/g, ""));
          self.fetch
            .fetchPost(form, "api/usuario/change-image")
            .then((dataJson) => {
              if (dataJson && dataJson.status) {
                this.toast.success(dataJson.message, "RIGHT");
              }
            });
        });
      } else this.toast.warning("Imagen es obligatorio", "RIGHT");
    }
  }

  handlePerRowsChange = async (perPage, page) => {
    this.setState({ loading: true });
    sessionUser = this.Auth.getProfile();
    await this.getRequestHistoryLogin(page, perPage, sessionUser.token, true);
  };

  handlePageChange = async (page) => {
    const { perPage } = this.state;
    this.setState({ loading: true });
    sessionUser = this.Auth.getProfile();
    await this.getRequestHistoryLogin(page, perPage, sessionUser.token, false);
  };

  getRequestHistoryLogin = async (page, perPage, token_usr, state_per_page) => {
    const response = await this.fetch.fetchGet(
      `api/log/history-login-by-token-user/${page}/${perPage}/${token_usr}`
    );
    if (response && response.status) {
      if (state_per_page) {
        this.setState({
          loading: false,
          data: response.Log,
          perPage,
        });
      } else {
        this.setState({
          loading: false,
          data: response.Log,
        });
      }
    }
  };

  handleActivityPerRowsChange = async (perPage, page) => {
    this.setState({ loadingActivity: true });
    sessionUser = this.Auth.getProfile();
    await this.getRequestActivities(page, perPage, sessionUser.token, true);
  };

  handleActivityPageChange = async (page) => {
    const { perPage } = this.state;
    this.setState({ loadingActivity: true });
    sessionUser = this.Auth.getProfile();
    await this.getRequestActivities(page, perPage, sessionUser.token, false);
  };

  getRequestActivities = async (page, perPage, token_usr, state_per_page) => {
    const response = await this.fetch.fetchGet(
      `api/log/activity-by-token-user/${page}/${perPage}/${token_usr}`
    );
    if (response && response.status) {
      if (state_per_page) {
        this.setState({
          loadingActivity: false,
          dataActivity: response.Log,
          perPage,
        });
      } else {
        this.setState({
          loadingActivity: false,
          dataActivity: response.Log,
        });
      }
    }
  };

  render() {
    const breadcrumbs = [
      {
        title: Links[0].title,
        url: Links[0].url,
      },
      {
        title: Links[8].title,
        url: Links[8].url,
      },
    ];

    const {
      loading,
      data,
      totalRows,
      loadingActivity,
      dataActivity,
      totalRowsActivity,
    } = this.state;

    return (
      <section className="items-grid section mt-5">
        <div className="container mt-4">
          <TitlePage
            titlePage={Languaje.perfil}
            breadcrumbs={breadcrumbs}
            position={"left"}
          />

          <section className="panel-menu-info">
            <div className="panel-menu-info-content">
              <div className="row">
                <div className="col-4 col-md-2 col-lg-2"></div>
                <div className="col-4 col-md-2 col-lg-2"></div>
                {this.state.rol === Constant[0].grupos.funcionario ||
                this.state.rol === Constant[0].grupos.administrador ? (
                  <div className="col-4 col-md-2 col-lg-2">
                    <div
                      className="single-contact-info pointer"
                      onClick={(e) => this.handleObjetoTributario(e, "VARIOS")}
                    >
                      <i className="fas fa-stream"></i>
                      <p>{Languaje.varios}</p>
                    </div>
                  </div>
                ) : (
                  <div className="col-4 col-md-2 col-lg-2"></div>
                )}

                {this.state.rol === Constant[0].grupos.funcionario ||
                this.state.rol === Constant[0].grupos.administrador ? (
                  <div className="col-4 col-md-2 col-lg-2">
                    <div
                      className="single-contact-info pointer"
                      onClick={(e) =>
                        this.handleObjetoTributario(e, "VEHICULOS")
                      }
                    >
                      <i className="fas fa-car" aria-hidden="true"></i>
                      <p>{Languaje.vehiculos}</p>
                    </div>
                  </div>
                ) : (
                  <div className="col-4 col-md-2 col-lg-2"></div>
                )}

                {this.state.rol === Constant[0].grupos.funcionario ||
                this.state.rol === Constant[0].grupos.administrador ? (
                  <div className="col-4 col-md-2 col-lg-2">
                    <div
                      className="single-contact-info pointer"
                      onClick={(e) =>
                        this.handleObjetoTributario(e, "INMUEBLES")
                      }
                    >
                      <i className="fas fa-laptop-house" aria-hidden="true"></i>
                      <p>{Languaje.inmuebles}</p>
                    </div>
                  </div>
                ) : (
                  <div className="col-4 col-md-2 col-lg-2"></div>
                )}

                <div className="col-4 col-md-2 col-lg-2">
                  <div
                    className="single-contact-info pointer"
                    onClick={this.handleUsuarioClick}
                  >
                    <i className="fas fa-user-tie" aria-hidden="true"></i>
                    <p>{Languaje.account}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {this.state.showPerfil ? (
            <PerfilForm
              onSubmitFormChangePassword={this.onSubmitFormChangePassword}
              onSubmitFormImage={this.onSubmitFormImage}
              usuario={_usuario}
              onSubmitFormDatos={this.onSubmitFormDatos}
              fetch={this.fetch}
              toast={this.toast}
            />
          ) : (
            ""
          )}

          {this.state.showHistorySession ? (
            <div className="row" style={{ marginTop: "-60px" }}>
              <div className="col-12 col-md-12 col-lg-12">
                <DataTable
                  columns={columns}
                  data={data}
                  progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  noDataComponent={Languaje.there_are_no_records_to_display}
                  expandableRowsComponent={<HistoryExpandedComponent />}
                  highlightOnHover
                  expandableRows
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.showActivity ? (
            <div className="row" style={{ marginTop: "-60px" }}>
              <div className="col-12 col-md-12 col-lg-12">
                <DataTable
                  columns={columnsActivity}
                  data={dataActivity}
                  progressPending={loadingActivity}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRowsActivity}
                  onChangeRowsPerPage={this.handleActivityPerRowsChange}
                  onChangePage={this.handleActivityPageChange}
                  noDataComponent={Languaje.there_are_no_records_to_display}
                  highlightOnHover
                  expandableRowsComponent={<ActivityExpandedComponent />}
                  expandableRows
                />
              </div>
            </div>
          ) : (
            ""
          )}

          {this.state.showDataUsuario ? (
            <PerfilOptionUsuario
              hanldePerfilClick={this.hanldePerfilClick}
              handleLoginHistory={this.handleLoginHistory}
              handleActivity={this.handleActivity}
            />
          ) : (
            ""
          )}

          {this.state.showInmuebles ? <PerfilOptionInmueble /> : ""}
          {this.state.showVehiculos ? <PerfilOptionVehiculo /> : ""}
          {this.state.showVarios ? <PerfilOptionVarios /> : ""}
        </div>

        <ToastContainer
          enableMultiContainer
          containerId={"Z"}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
      </section>
    );
  }
}

export default Perfil;
