import Consulta from "./../Consulta";
const consultaSQL = new Consulta();

class Funciones {

  constructor(fetch, toast) {
    this.fetch = fetch;
    this.toast = toast;
  }

  async hasRestriction(zona, idCategoryPublicity) {
    const query = consultaSQL.queryAreaHomogeneaRestricction(zona, idCategoryPublicity);
    const restriction = await this.fetch.fetchPostGraphQl(query, "/graphql", null);

    if (restriction && restriction.findRectricctionByZone && restriction.findRectricctionByZone.result) {
      return true;
    }
    return false;
  }

  handleClickDeleteFile(event) {
    event.preventDefault();
    debugger
    const butonClose = event.target;
    const inputFile = butonClose.closest("div").querySelector("input");
    if (inputFile.files.length > 0) {
      inputFile.value = null;
      inputFile.dispatchEvent(new Event('change'));
      const iconPdfImge = butonClose.closest("div").querySelector("i[class='fas fa-file-pdf icon']");
      iconPdfImge.remove();
    }
  }

  handleOnchangeInputFile(event, toast) {
    debugger
    const inputFile = event.target;
    if (inputFile.files.length > 0) {
      let sizeFileBytes = inputFile.files[0].size;
      const divContainerFather = inputFile.closest(".row");
      let iconFile = inputFile.parentElement.parentElement.querySelector("i");
      if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);
      if (sizeFileBytes > 0 && sizeFileBytes <= 7000000) {

        if (inputFile.files[0].name.includes("https") || inputFile.files[0].name.includes("http")) {
          let nameFile = inputFile.files[0].name.split("/");
          nameFile = nameFile[nameFile.length - 1];
          divContainerFather.firstChild.children[3].innerHTML = `${nameFile.substring(
            nameFile.length - 20,
            nameFile.length
          )}`;
        } else {
          divContainerFather.firstChild.children[3].innerHTML = `${inputFile.files[0].name.substring(inputFile.files[0].name.length, 15)}`;
        }

        const format = inputFile.files[0].type.split("/");

        const imagenInstitucional = inputFile.getAttribute("imageinstitucional");
        if (parseInt(imagenInstitucional) === 1 && /^(pdf)$/i.test(format[1])) {
          toast.warning("Imagen es requerido");
          inputFile.value = '';
          return;
        }

        if (/^(pdf|gif|jpe?g|png)$/i.test(format[1])) {
          const icono = document.createElement("i");
          if (format[1] === "pdf") {
            icono.className = "fas fa-file-pdf icon";
          }

          if (/^(gif|jpe?g|png)$/i.test(format[1])) {
            icono.className = "fas fa-file-image icon";
          }
          inputFile.parentElement.parentElement.appendChild(icono);
        }
      } else {
        inputFile.value = null;
        divContainerFather.firstChild.children[3].innerHTML = "";
        toast.error("Formato no valido o excede los 7MB el archivo");
      }
    }
  }

  requisitosHtml(respRequistos, isNew) {
    return respRequistos.map(
      ({ requiredRequirement, requiredImagenInstitucional, name, id }, index) => {
        return (
          <div className="row" key={index + "-" + id} index={index}>
            <div
              className="col-12 col-md-12"
              id={"row-first-requisito-" + id}
            >
              <div
                className={
                  "row row-table-custom pt-2 pb-2  me-1 " +
                  " justify-content-center align-items-center requisitos-" + id
                }
              >
                <div className="col-9 col-md-9">
                  <span className="text-justify"> {name} <br /> url: { }</span>
                  <br />
                  <strong> {requiredRequirement && <span className="text-danger" title="Requisito Obligatorio"> (*) </span>} Archivo: </strong>
                  <span className={`doc-name-${id}`}></span>
                </div>
                <div className="col-3 col-md-3">
                  <div className="form-group files text-center">
                    <label tabIndex={id} className="form-control">
                      <input
                        type="file"
                        className="form-control"
                        id={isNew ? `requirement_site_${id}` : `requirement_site[${id}]`}
                        name={`requirement_site[]`}
                        imageinstitucional={requiredImagenInstitucional ? 1 : 0}
                        onChange={e => this.handleOnchangeInputFile(e, this.toast)}
                        data-parsley-required={requiredRequirement}
                        data-parsley-required-message="Por favor, selecciona un archivo"
                        data-parsley-filemimetypes={requiredImagenInstitucional ? "image/jpeg,image/png" : "application/pdf"}
                        data-parsley-filemimetypes-message={requiredImagenInstitucional ? "Solo se permiten imágenes (JPEG/PNG)" : "Solo se permiten archivos PDF"}
                      />
                      
                    </label>
                    <span className="fs-small8em text-maroon-flush small-description">
                      7MB, Limite Máximo
                    </span>
                    {/* <span className="fas fa-times icon-close" onClick={this.handleClickDeleteFile}></span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
  }
}

export default Funciones;