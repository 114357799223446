import React, { useEffect, useState } from "react";
import HeaderCarroucel from "./../../components/Home/HeaderCarroucel";
import Services from "./../../components/Home/Services";
import Departamentos from "./../../components/Home/Departamentos";
import News from "../utils/News";
import Ayuda from "../utils/Ayuda";
//import MessageDescount from "../utils/MessageDescount";

const Home = (props) => {
  const [showAuxiliary, setShowAuxiliary] = useState(false);
  useEffect(() => {
    setShowAuxiliary(true);
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <HeaderCarroucel />
      {/* <Features links={links} />*/}
      <Services />
      <Departamentos />
      {/*<Autoridades />
            <MisionVision />>*/}
      {/* <UnderContruction /> */}
      {showAuxiliary ? (
        <>
          {/*<News />*/}
          {/*<CookieMessage />*/}
          <Ayuda />
          {/*  <MessageDescount /> */}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
