
const InfoContribuyente = (props) => {

  const { data, Texto } = props;
  return (
    <>
      <span>
        <strong>{Texto.contribuyente.toUpperCase()} </strong>
      </span>
      <hr className="mt-0 " />
      <p>
        <strong>{Texto.contribuyente}: </strong>
        {data ? (data.titular_juridico_nit_numero_documento ? "JURIDICO" : "NATURAL") : ""}
      </p>
      <p>
        <strong>{Texto.numero_documento}: </strong>
        {data ? data.titular_juridico_nit_numero_documento : ""}
      </p>
      <p>
        <strong>{Texto.razon_social}: </strong>
        {data ? data.titular_juridico_nit_razon_social : ""}
      </p>
      <p>
        <strong>{Texto.representante_legal} </strong>
      </p>
      <p>
        <strong>{Texto.nombre}: </strong>
        {data ? data.rep_legal_nombre : ""}
      </p>
      <p>
        <strong>{Texto.numero_documento}: </strong>
        {data ? data.rep_legal_numero_documento : ""}
      </p>
    </>
  );
}

export default InfoContribuyente;
