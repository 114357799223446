
import React from "react";
import PropTypes from "prop-types";

const DatosTecnico = (props) => {

  const { data, Texto } = props;

  return (
    <>
      <span>
        <strong>{Texto.datos_tecnicos.toUpperCase()} </strong>
      </span>
      <hr className="mt-0 " />
      <p>
        <strong>{Texto.zona_homogenea}: </strong>
        {data.data_technical_zona}
      </p>
      <p>
        <strong>{Texto.vigencia}: </strong>
        {data.adv_date_vigencia}
      </p>
      <p>
        <strong>Alto M2: </strong>
        {data.data_technical_alto}
      </p>
      <p>
        <strong>Ancho M2: </strong>
        {data.data_technical_ancho}
      </p>
      <p>
        <strong>Dimesión M2: </strong>
        {data.data_technical_dimension}
      </p>

      <p>
        <strong>Dirección: </strong>
        {data.data_technical_direccion + " " + data.data_technical_direccion_descriptiva}
      </p>

      <p>
        <strong>Nro Actividad Económica: </strong>
        {data.act_eco_numero}
      </p>

      <p>
        <strong>Tipo Actividad Económica: </strong>
        {data.act_eco_tipo_actividad}
      </p>

    </>
  );
}


DatosTecnico.prototype = {
  dataTechnical: PropTypes.object.isRequired,
  // descripcion: PropTypes.string.isRequired,
  // clasificador:PropTypes.string,
  // categoria:PropTypes.string
};

export default DatosTecnico;
