import React, { Component } from "react";

class ValidateTipoVisaciones extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleSearchInmuebleClick = this.handleSearchInmuebleClick.bind(this);
    this.handleNumeroOnChange = this.handleNumeroOnChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.state = {
      optionsTiposVisacionMinutas: [],
      searchData: false,
      message: "",
    };

    this.refSlcTipoVisacionMinutas = React.createRef();
    this.refInputSearchObjectTributari = React.createRef();
    this.refInputCancelObjectTributari = React.createRef();
    this.refInputSearchMap = React.createRef();
  }

  componentDidMount() {
    this.loadTiposVisacionMinutas();
  }

  loadTiposVisacionMinutas = () => {
    let self = this;
    const tipoVisacionMinutas = this.props.fetch.fetchGet(
      `api/visacion-minutas/clasificadores`
    );
    tipoVisacionMinutas.then((res) => {
      if (res && res.status && res.TipoVisacionMinutas) {
        const listItems = res.TipoVisacionMinutas.map((item, index) => {
          return (
            <option
              key={index}
              value={item.id}
              name={item.name}
              showamount={item.show_amount}
              code={item.code}
              active={item.active}
            >
              {item.name}
            </option>
          );
        });
        self.setState({ optionsTiposVisacionMinutas: listItems });
      }
    });
  };

  async handleSearchInmuebleClick(event) {
    event.preventDefault();
    await this.eventInputSearch();
    /*
    this.props.validateForm();
    if (
      window
        .jQuery(
          "#" + document.getElementById("formModalVisacion").getAttribute("id")
        )
        .parsley()
        .isValid()
    ) {
      debugger;
      let input = this.refInputSearchMap.current;
      let selectTipoVisacionMinuta = this.refSlcTipoVisacionMinutas.current;
      if (input.value !== "" && selectTipoVisacionMinuta.selectedIndex > 0) {
        let buttonCancel = this.refInputCancelObjectTributari.current;
        this.setState({ searchData: true });
        input.setAttribute("readonly", true);
        selectTipoVisacionMinuta.setAttribute("disabled", true);

        buttonCancel.classList.add("btn-disabled");
        buttonCancel.setAttribute("disabled", true);

        const respInmueble = await this.props.fetch.axiosAsyncGet(
          `api-ruat/inmuebles/nro-inmueble/${input.value}`
        );
        if (Boolean(respInmueble) && respInmueble.status) {
          const propietarios = respInmueble.data.resInmueble.propiedades;
          const tipoVisacionMinuta = {
            id: parseInt(selectTipoVisacionMinuta.selectedOptions[0].value),
            name: selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
              "name"
            ),
            code: selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
              "code"
            ),
            active:
              selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
                "active"
              ) === "1"
                ? true
                : false,
            showAmount:
              selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
                "showamount"
              ) === "1"
                ? true
                : false,
          };
          if (propietarios.length === 1) {
            const form = new FormData();
            form.append(
              "propiedades[codigoPropiedad]",
              propietarios[0].codigoPropiedad
            );
            const resDeudas = await this.props.fetch.fetchPost(
              form,
              `api-ruat/inmuebles/deudas`,
              undefined
            );
            if (
              resDeudas &&
              resDeudas.status &&
              resDeudas.data.gestionesDeuda <= 0
            ) {
              input.removeAttribute("readonly");
              selectTipoVisacionMinuta.removeAttribute("disabled");
              buttonCancel.classList.remove("btn-disabled");
              buttonCancel.removeAttribute("disabled");

              const data = {
                showForm: true,
                inmueble: respInmueble.data.resInmueble,
                datosTecnicos: respInmueble.data.resTecnico,
                gestionDeuda: resDeudas.data.gestionesDeuda,
                tipoVisacionMinuta: tipoVisacionMinuta,
                derechoAdmision: this.props.dataDerechoAdmision,
                typeSearch: "nro_ruat", // selectSearch.selectedOptions[0].value
              };
              this.props.nextPageCurrent(2, data);
            } else {
              if (resDeudas) {
                let deudasMessage = "";
                const deudasInmueble = resDeudas.data.gestionesDeuda.map(
                  (deuda) => {
                    return deuda.gestion;
                  }
                );
                if (deudasInmueble.length > 9) {
                  deudasMessage =
                    deudasInmueble[0] +
                    "," +
                    deudasInmueble[1] +
                    "," +
                    deudasInmueble[2] +
                    ", ... ," +
                    deudasInmueble[deudasInmueble.length - 3] +
                    "," +
                    deudasInmueble[deudasInmueble.length - 2] +
                    "," +
                    deudasInmueble[deudasInmueble.length - 1];
                } else deudasMessage = deudasInmueble.join(",");
                this.setState({
                  message:
                    "No es posible realizar la visación debido a que el inmueble adeuda las gestiones [" +
                    deudasMessage +
                    "]",
                  searchData: false,
                });
              } else
                this.setState({
                  message:
                    "Verifique el número de inmueble o consulte con la administración",
                  searchData: false,
                });

              selectTipoVisacionMinuta.removeAttribute("disabled");
              buttonCancel.classList.remove("btn-disabled");
              buttonCancel.removeAttribute("disabled");
              input.removeAttribute("readonly");
            }
          } else {
            const data = {
              showForm: true,
              inmueble: respInmueble.data.resInmueble,
              datosTecnicos: respInmueble.data.resTecnico,
              gestionDeuda: undefined,
              tipoVisacionMinuta: tipoVisacionMinuta,
              derechoAdmision: this.props.dataDerechoAdmision,
              typeSearch: "nro_ruat", //selectSearch.selectedOptions[0].value
            };
            this.props.nextPageCurrent(2, data);
          }
        } else {
          input.removeAttribute("readonly");
          selectTipoVisacionMinuta.removeAttribute("disabled");

          buttonCancel.classList.remove("btn-disabled");
          buttonCancel.removeAttribute("disabled");
          this.setState({ searchData: false });
        }
      } else {
        this.props.fetch.toast.warning(
          "El formulario tiene campos obligatorio",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
    */
  }

  handleNumeroOnChange(event) {
    event.preventDefault();
    const button = document.getElementById("btnModalSmallSubmit");

    if (event.currentTarget.value.length >= 3)
      button.classList.remove("btn-disabled");
    else button.classList.add("btn-disabled");
  }

  async handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      await this.eventInputSearch();
    }
  }

  async eventInputSearch() {
    this.props.validateForm();
    if (
      window
        .jQuery(
          "#" + document.getElementById("formModalVisacion").getAttribute("id")
        )
        .parsley()
        .isValid()
    ) {
      let input = this.refInputSearchMap.current;
      let selectTipoVisacionMinuta = this.refSlcTipoVisacionMinutas.current;
      if (input.value !== "" && selectTipoVisacionMinuta.selectedIndex > 0) {
        let buttonCancel = this.refInputCancelObjectTributari.current;
        this.setState({ searchData: true });
        input.setAttribute("readonly", true);
        selectTipoVisacionMinuta.setAttribute("disabled", true);

        buttonCancel.classList.add("btn-disabled");
        buttonCancel.setAttribute("disabled", true);

        const respInmueble = await this.props.fetch.axiosAsyncGet(
          `api-ruat/inmuebles/nro-inmueble/${input.value}`
        );
        if (Boolean(respInmueble) && respInmueble.status) {
          const propiedades = respInmueble.data.resInmueble.propiedades;
          const tipoVisacionMinuta = {
            id: parseInt(selectTipoVisacionMinuta.selectedOptions[0].value),
            name: selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
              "name"
            ),
            code: selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
              "code"
            ),
            active:
              selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
                "active"
              ) === "1"
                ? true
                : false,
            showAmount:
              selectTipoVisacionMinuta.selectedOptions[0].getAttribute(
                "showamount"
              ) === "1"
                ? true
                : false,
          };
          if (propiedades.length === 1) {
            const form = new FormData();
            form.append(
              "propiedades[codigoPropiedad]",
              propiedades[0].codigoPropiedad
            );
            const resDeudas = await this.props.fetch.fetchPost(
              form,
              `api-ruat/inmuebles/deudas`,
              undefined
            );
            resDeudas.data.gestionesDeuda = [];  //para evitar la deuda
            if (
              resDeudas &&
              resDeudas.status &&
              resDeudas.data.gestionesDeuda <= 0
            ) {
              input.removeAttribute("readonly");
              selectTipoVisacionMinuta.removeAttribute("disabled");
              buttonCancel.classList.remove("btn-disabled");
              buttonCancel.removeAttribute("disabled");

              const data = {
                showForm: true,
                inmueble: respInmueble.data.resInmueble,
                datosTecnicos: respInmueble.data.resTecnico,
                gestionDeuda: resDeudas.data.gestionesDeuda,
                tipoVisacionMinuta: tipoVisacionMinuta,
                derechoAdmision: this.props.dataDerechoAdmision,
                typeSearch: "nro_ruat", // selectSearch.selectedOptions[0].value
              };
              this.props.nextPageCurrent(2, data);
            } else {
              if (resDeudas) {
                let deudasMessage = "";
                const deudasInmueble = resDeudas.data.gestionesDeuda.map(
                  (deuda) => {
                    return deuda.gestion;
                  }
                );
                if (deudasInmueble.length > 9) {
                  deudasMessage =
                    deudasInmueble[0] +
                    "," +
                    deudasInmueble[1] +
                    "," +
                    deudasInmueble[2] +
                    ", ... ," +
                    deudasInmueble[deudasInmueble.length - 3] +
                    "," +
                    deudasInmueble[deudasInmueble.length - 2] +
                    "," +
                    deudasInmueble[deudasInmueble.length - 1];
                } else deudasMessage = deudasInmueble.join(",");
                this.setState({
                  message:
                    "No es posible realizar la visación debido a que el inmueble adeuda las gestiones [" +
                    deudasMessage +
                    "]",
                  searchData: false,
                });
              } else
                this.setState({
                  message:
                    "Verifique el número de inmueble o consulte con la administración",
                  searchData: false,
                });

              selectTipoVisacionMinuta.removeAttribute("disabled");
              buttonCancel.classList.remove("btn-disabled");
              buttonCancel.removeAttribute("disabled");
              input.removeAttribute("readonly");
            }
          } else {
            const data = {
              showForm: true,
              inmueble: respInmueble.data.resInmueble,
              datosTecnicos: respInmueble.data.resTecnico,
              gestionDeuda: undefined,
              tipoVisacionMinuta: tipoVisacionMinuta,
              derechoAdmision: this.props.dataDerechoAdmision,
              typeSearch: "nro_ruat", //selectSearch.selectedOptions[0].value
            };
            this.props.nextPageCurrent(2, data);
          }
        } else {
          input.removeAttribute("readonly");
          selectTipoVisacionMinuta.removeAttribute("disabled");

          buttonCancel.classList.remove("btn-disabled");
          buttonCancel.removeAttribute("disabled");
          this.setState({ searchData: false });
        }
      } else {
        this.props.fetch.toast.warning(
          "El formulario tiene campos obligatorio",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    }
  }

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12 padding-right-0">
            <img
              className="rounded mx-auto d-block"
              alt="extension-prescripcion"
              src={"/static/img/clasficador.png"}
              width="150"
            />
            <h4 className="text-center mt-3 mb-3">Tipos de Visaciones</h4>
          </div>
        </div>

        {this.state.message !== "" ? (
          <div className="row">
            <div className="col-12">
              <div
                className="alert alert-warning alert-dismissible fade show text-justify"
                role="alert"
              >
                <strong>Atención!</strong> {this.state.message}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-12 mb-3">
            <select
              className="form-select"
              name={"[id_tipo_visacion_minuta]"}
              required
              data-parsley-required="true"
              ref={this.refSlcTipoVisacionMinutas}
            >
              <option defaultValue value="">
                Seleccione Tipo Visación
              </option>
              {this.state.optionsTiposVisacionMinutas}
            </select>
          </div>

          <div className="col-12">
            <div className="form-group">
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text bg-butterfl-bush text-white"
                    id="basic-addon1"
                  >
                    Nro. Inmueble
                  </span>
                </div>
                <input
                  name={"tipo_visacion[numero]"}
                  id={"tipo_visacion[numero]"}
                  type="text"
                  className="form-control input-uppercase"
                  placeholder="Número"
                  aria-label="Número"
                  aria-describedby="Número"
                  onChange={this.handleNumeroOnChange}
                  onKeyDown={this.handleKeyDown}
                  data-parsley-required="true"
                  ref={this.refInputSearchMap}
                />
              </div>
            </div>
          </div>
        </div>

        {this.state.searchData ? (
          <div className="row">
            <div className="col-12 mb-1">
              <img
                className="rounded mx-auto d-block"
                alt="pulse-loading"
                src={"/static/img/pulse_200.gif"}
                width="70"
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row mt-3">
          <div className="col-12 col-md-6 col-lg-6 mb-3 text-start ">
            <div className="col-12 search-btn button ">
              <input
                className="btn bg-maroon-flush btn-full-screen"
                name="submit"
                type="submit"
                value="Cancelar"
                ref={this.refInputCancelObjectTributari}
                onClick={this.props.handleCancelClick}
              />
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-6 mb-3 text-end ">
            <div className="col-12 search-btn button ">
              <input
                className="btn btn-disabled btn-full-screen"
                name="submit"
                id="btnModalSmallSubmit"
                type="submit"
                value="Buscar"
                onClick={this.handleSearchInmuebleClick}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ValidateTipoVisaciones;
