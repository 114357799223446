import React, { Component } from "react";
import Config from "../../data/config";
import LinkUrl from "../../data/link";
import { useHistory } from "react-router-dom";

const Departamentos = (props) => {
  const history = useHistory();

  const redirectPage = (event, url) => {
    event.preventDefault();
    history.push(url);
  };

  return (
    <section className="pricing-table section bg-gallery pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h4 className=" text-birch">
                {" "}
                ADMINISTRACION TRIBUTARIA MUNICIPAL
              </h4>
              <h3 className=" text-birch">
                ALCALDIA DE COCHABAMBA
              </h3>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col block bg-teklet-deep text-center">
            <img
              className="img-thumbnail rounded mx-auto d-block img-thumbnail-simat"
              alt="img-legal-tributario"
              width="78"
              height="80"
              src={
                Config[2].url +
                "/static/img/offcanvas/serv-ingresos-tributarios.png"
              }
            />
            <div className="text">
              <h4 className="text-white mt-4">INGRESOS TRIBUTARIOS</h4>
            </div>
          </div>

          <div className="col block bg-teklet-deep text-center">
            <img
              className="img-thumbnail rounded mx-auto d-block img-thumbnail-simat"
              alt="img-fiscalizacion"
              width="78"
              height="80"
              src={
                Config[2].url + "/static/img/offcanvas/serv-fiscalizacion.png"
              }
            />
            <div className="text">
              <h4 className="text-white mt-4">FISCALIZACION</h4>
            </div>
          </div>

          <div
            className="col block bg-teklet-deep text-center pointer"
            style={{ border: "2px solid white" }}
            onClick={(event) => redirectPage(event, LinkUrl[94].url)}
          >
            <img
              className="img-thumbnail rounded mx-auto d-block img-thumbnail-simat"
              alt="img-plataforma"
              width="78"
              height="80"
              src={
                Config[2].url +
                "/static/img/offcanvas/serv-legal-tributario.png"
              }
            />
            <div className="text">
              <h4 className="text-white mt-4">LEGAL TRIBUTARIO</h4>
            </div>
          </div>

          <div
            className="col block bg-teklet-deep text-center pointer"
            style={{ border: "2px solid white" }}
            onClick={(event) => redirectPage(event, LinkUrl[93].url)}
          >
            <img
              className="img-thumbnail rounded mx-auto d-block img-thumbnail-simat"
              alt="movilidad urbana"
              width="78"
              height="80"
              src={Config[2].url + "/static/img/offcanvas/movilidad_urbana.png"}
            />
            <div className="text">
              <h4 className="text-white mt-4">MOVILIDAD URBANA</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Departamentos;
