import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import datepicker from "react-datepicker/dist/react-datepicker.css";
import Constant from "../../data/constant";
import Config from "../../data/config";
import Texto from "../../data/es";
import Fetch from "../../components/utils/Fetch";
import es from "date-fns/locale/es"; // the locale you want

registerLocale("es", es); // register it with the name you want
class DataPersona extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleTipoDocumentoOnChange =
      this.handleTipoDocumentoOnChange.bind(this);
    this.handleEstadoCivilOnChange = this.handleEstadoCivilOnChange.bind(this);
    this.handleSearchByCiOnClick = this.handleSearchByCiOnClick.bind(this);
    this.handleDatePickerDatosJuridicosChange =
      this.handleDatePickerDatosJuridicosChange.bind(this);
    this.handleSearchByNitOnClick = this.handleSearchByNitOnClick.bind(this);
    this.handleDatePickerPersonaChange =
      this.handleDatePickerPersonaChange.bind(this);

    this.state = {
      showInputCi: false,
      showApellidoCasada: false,
      ciExpedido: "EX",
      apellidoCasada: "",
      startDateJuridico: null,
      startDatePersona: null,
      optionsTipoDocumento: "",
      optionsEstadoCivil: "",
      optionsNacionalidad: "",
      optionsGenero: "",
      optionsTipoSociedad: "",
    };

    this.toast = this.props.toast;
    this.fetch = new Fetch();
    this.fetch.setToast(this.toast);
  }

  componentDidMount() {
    window.inputTextUpperCase();

    const tipoDocRes = this.fetch.fetchGet(
      `api/tipo-documentos/actividades_economicas`
    );
    tipoDocRes.then((res) => {
      if (res && res.status) {
        if (res.TipoDocumento) {
          const listItems = res.TipoDocumento.map((item, index) => {
            return (
              <option key={index} value={item.id} code={item.code}>
                {item.name}
              </option>
            );
          });

          if (this.props.solicitanteDb) {
            if (
              this.props.solicitanteDb.persona &&
              Boolean(this.props.solicitanteDb.persona.id_tipo_documento)
            ) {
              this.setState({
                optionsTipoDocumento: listItems,
                ciExpedido: this.props.solicitanteDb.persona.expedido_en,
              });
              window
                .jQuery("input[name='persona[numero_documento]']")
                .val(this.props.solicitanteDb.persona.numero_documento);
              window
                .jQuery("select[name='persona[id_tipo_documento]']")
                .val(this.props.solicitanteDb.persona.id_tipo_documento)
                .trigger("change");

              var $_dropdown_menu_per_sol = window.jQuery(
                "#persona-dropdown-solicitante-tipo-doc"
              );
              $_dropdown_menu_per_sol
                .parent()
                .children(":first")
                .text(this.props.solicitanteDb.persona.expedido_en);
              document.getElementsByName("persona[expedido_en]")[0].value =
                this.props.solicitanteDb.persona.expedido_en;
            } else this.setState({ optionsTipoDocumento: listItems });
          } else this.setState({ optionsTipoDocumento: listItems });
        }
      }
    });

    const estadoCivilResp = this.fetch.fetchGet(`api/list/estado-civil`);
    estadoCivilResp.then((res) => {
      if (res && res.status && res.estadoCivil) {
        const listEC = res.estadoCivil.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });

        this.setState({ optionsEstadoCivil: listEC });
        if (this.props.solicitanteDb && this.props.solicitanteDb.persona)
          window
            .jQuery("select[name='persona[estado_civil]']")
            .val(this.props.solicitanteDb.persona.estado_civil)
            .trigger("change");
      }
    });

    const nacionalidadResp = this.fetch.fetchGet(`api/list/nacionalidad`);
    nacionalidadResp.then((res) => {
      if (res && res.status && res.nacionalidad) {
        const listNacionlidad = res.nacionalidad.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });
        this.setState({ optionsNacionalidad: listNacionlidad });

        if (this.props.solicitanteDb && this.props.solicitanteDb.persona)
          window
            .jQuery("select[name='persona[nacionalidad]']")
            .val(this.props.solicitanteDb.persona.nacionalidad)
            .trigger("change");
      }
    });

    const generoResp = this.fetch.fetchGet(`api/list/genero`);
    generoResp.then((res) => {
      if (res && res.status && res.genero) {
        const listGenero = res.genero.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });
        this.setState({ optionsGenero: listGenero });

        if (this.props.solicitanteDb && this.props.solicitanteDb.persona)
          window
            .jQuery("select[name='persona[genero]']")
            .val(this.props.solicitanteDb.persona.genero)
            .trigger("change");
      }
    });

    const tipoSociedadResp = this.fetch.fetchGet(`api/tipo-sociedad/all`);
    tipoSociedadResp.then((res) => {
      if (res && res.status && res.TipoSociedad) {
        const listItems = res.TipoSociedad.map((item, index) => {
          return (
            <option key={index} value={item.id} code={item.code}>
              {item.name}
            </option>
          );
        });

        this.setState({ optionsTipoSociedad: listItems });
        if (this.props.solicitanteDb)
          if (
            this.props.solicitanteDb.datos_juridicos &&
            this.props.solicitanteDb.datos_juridicos
          )
            window
              .jQuery("select[name='datos_juridicos[id_tipo_sociedad]']")
              .val(this.props.solicitanteDb.datos_juridicos.id_tipo_sociedad)
              .trigger("change");
      }
    });

    if (this.props.solicitanteDb) {
      this.loadDataForm(this.props.solicitanteDb, this, true);
    }
  }

  handleDatePickerDatosJuridicosChange(date) {
    this.setState({
      startDateJuridico: date,
    });
  }

  handleDatePickerPersonaChange(date) {
    this.setState({
      startDatePersona: date,
    });
  }

  handleSearchByCiOnClick(event) {
    event.preventDefault();
    let ci = "";
    const self = this;
    let input_search = "";
    if (event.target.tagName === "I") {
      ci = event.target.parentElement.parentElement.firstElementChild.value;
      input_search =
        event.target.parentElement.parentElement.firstElementChild.getAttribute(
          "name"
        );
    } else {
      ci = event.target.parentElement.firstElementChild.value;
      input_search =
        event.target.parentElement.firstElementChild.getAttribute("name");
    }

    if (ci !== "") {
      window
        .jQuery(
          "input[name*='persona']:not(input[name='persona[numero_documento]']):not(input[type=hidden])"
        )
        .val("");
      window
        .jQuery(
          "select[name*='persona']:not(select[name='persona[id_tipo_documento]'])"
        )
        .prop("selectedIndex", 0);
      const searchPersona = this.fetch.fetchGet(
        `api/recaudaciones/persona-by-ci/${ci}`
      );
      searchPersona.then((dataJson) => {
        if (dataJson && dataJson.status && dataJson.persona_natural) {
          if (input_search.indexOf("persona") >= 0) {
            self.loadDataForm(
              dataJson.persona_natural,
              self,
              dataJson.persona_natural.recaudacion ? false : true
            );
          }
          this.toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        window.inputConvertUpperCaseForm("formSolicitante");
      });
    }
  }

  handleSearchByNitOnClick(event) {
    event.preventDefault();
    let nit = "";
    var self = this;
    if (event.target.tagName === "I")
      nit =
        event.target.parentElement.parentElement.parentElement.firstElementChild
          .value;
    else nit = event.target.parentElement.parentElement.firstElementChild.value;

    if (nit !== "") {
      window
        .jQuery(
          "input[type='text']:not(input[name='persona[numero_documento]']):not(input[name='datos_juridicos[nit]'])"
        )
        .val("");
      window
        .jQuery("select:not(select[name='persona[id_tipo_documento]'])")
        .prop("selectedIndex", 0);
      const searchPersona = this.fetch.fetchGet(
        `api/recaudaciones/persona-by-nit/${nit}`
      );
      searchPersona.then((dataJson) => {
        if (dataJson && dataJson.status && dataJson.persona_natural) {
          self.loadDataForm(
            dataJson.representante_legal,
            self,
            dataJson.representante_legal.recaudacion ? false : true
          );

          document.getElementsByName("persona[numero_documento]")[0].value =
            dataJson.representante_legal.ci
              ? dataJson.representante_legal.ci
              : dataJson.representante_legal.numero_documento;
          document.getElementsByName("datos_juridicos[razon_social]")[0].value =
            dataJson.persona_juridica.razon_social;
          if (dataJson.persona_juridica.fecha_constitucion) {
            let fecha = dataJson.persona_juridica.fecha_constitucion.replace(
              /-/g,
              "/"
            );
            this.setState({ startDateJuridico: new Date(fecha) });
          }

          if (
            dataJson.persona_juridica.sociedad &&
            dataJson.persona_juridica.sociedad
          ) {
            window
              .jQuery(
                "select[name='datos_juridicos[id_tipo_sociedad]'] option[code='" +
                  dataJson.persona_juridica.sociedad +
                  "']"
              )
              .prop("selected", true);
          } else {
            window
              .jQuery("select[name='datos_juridicos[id_tipo_sociedad]']")
              .val(parseInt(dataJson.persona_juridica.id_tipo_sociedad))
              .trigger("change");
          }

          if (
            dataJson.representante_legal.num_sec_tipo_documento &&
            dataJson.representante_legal.num_sec_tipo_documento === 1
          )
            window
              .jQuery("select[name='persona[id_tipo_documento]']")
              .val(dataJson.representante_legal.num_sec_tipo_documento)
              .trigger("change");
          else if (
            dataJson.representante_legal.id_tipo_documento &&
            dataJson.representante_legal.id_tipo_documento
          )
            window
              .jQuery("select[name='persona[id_tipo_documento]']")
              .val(dataJson.representante_legal.id_tipo_documento)
              .trigger("change");
          else
            window
              .jQuery("select[name='persona[id_tipo_documento]']")
              .val(2)
              .trigger("change");

          this.toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
        window.inputConvertUpperCaseForm("formSolicitante");
      });
    }
  }

  loadDataForm(persona, self, local) {
    debugger;
    if (local) {
      let persona_db = undefined;
      if (persona.hasOwnProperty("persona")) {
        if (persona.persona) persona_db = persona.persona;
      } else persona_db = persona;

      if (persona_db) {
        window.jQuery("input[name='persona[nombre]']").val(persona_db.nombre);
        window
          .jQuery("input[name='persona[apellido_paterno]']")
          .val(persona_db.apellido_paterno);
        window
          .jQuery("input[name='persona[apellido_materno]']")
          .val(persona_db.apellido_materno);

        if (Boolean(persona_db.apellido_casada)) {
          self.setState({
            showApellidoCasada: true,
            startDatePersona: new Date(persona_db.fecha_nacimiento),
            apellidoCasada: persona_db.apellido_casada,
            ciExpedido: persona_db.expedido_en,
          });
          window
            .jQuery("select[name='persona[estado_civil]']")
            .val(Constant[0].estado_civil.casado)
            .trigger("change");
        } else {
          self.setState({
            startDatePersona: new Date(persona_db.fecha_nacimiento),
            ciExpedido: persona_db.expedido_en,
          });
        }

        if (persona_db.nacionalidad)
          window
            .jQuery("select[name='persona[nacionalidad]']")
            .val(parseInt(persona_db.nacionalidad))
            .trigger("change");

        if (persona_db.genero)
          window
            .jQuery("select[name='persona[genero]']")
            .val(parseInt(persona_db.genero))
            .trigger("change");

        if (persona_db.estado_civil && !Boolean(persona_db.apellido_casada))
          window
            .jQuery("select[name='persona[estado_civil]']")
            .val(parseInt(persona_db.estado_civil))
            .trigger("change");
      }

      if (persona.datos_juridicos && persona.datos_juridicos) {
        window
          .jQuery("input[name='datos_juridicos[nit]']")
          .val(persona.datos_juridicos.nit);
        window
          .jQuery("input[name='datos_juridicos[razon_social]']")
          .val(persona.datos_juridicos.razon_social);

        self.setState({
          startDateJuridico: new Date(
            persona.datos_juridicos.fecha_constitucion
          ),
        });
      }
    } else {
      document.getElementsByName("persona[nombre]")[0].value = persona.nombres;
      document.getElementsByName("persona[apellido_paterno]")[0].value =
        persona.ap_paterno;
      document.getElementsByName("persona[apellido_materno]")[0].value =
        persona.ap_materno;

      if (Boolean(persona.ap_casada)) {
        self.setState({
          showApellidoCasada: true,
          ciExpedido: persona.lugar_ci,
        });
        window
          .jQuery("select[name='persona[estado_civil]']")
          .val(Constant[0].estado_civil.casado)
          .trigger("change");
        document.getElementsByName("persona[apellido_casada]")[0].value =
          persona.ap_casada;
      } else
        self.setState({
          ciExpedido: persona.lugar_ci,
          startDatePersona: null,
          showApellidoCasada: false,
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let dropdownMenuPerSol = window.jQuery(
      "#persona-dropdown-solicitante-tipo-doc"
    );
    const self = this;
    const inputPersonaExpedidoEn = document.getElementsByName(
      "persona[expedido_en]"
    )[0];
    if (dropdownMenuPerSol.length > 0) {
      let li = window.jQuery(dropdownMenuPerSol.find("li"));
      window.event_drop_down(li, function (code_ci_exp, target) {
        if (self.state.ciExpedido === "EX")
          inputPersonaExpedidoEn.value = code_ci_exp;
      });
    }

    if (this.state.showApellidoCasada && this.state.apellidoCasada !== "") {
      document.getElementsByName("persona[apellido_casada]")[0].value =
        this.state.apellidoCasada;
    }

    if (this.state.ciExpedido !== "EX") {
      const ciExpedido = Constant[0].ci_expedido;
      switch (this.state.ciExpedido) {
        case ciExpedido.cbba.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.cbba.new);
          inputPersonaExpedidoEn.value = ciExpedido.cbba.new;
          break;
        case ciExpedido.lapaz.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.lapaz.new);
          inputPersonaExpedidoEn.value = ciExpedido.lapaz.new;
          break;
        case ciExpedido.santacruz.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.santacruz.new);
          inputPersonaExpedidoEn.value = ciExpedido.santacruz.new;
          break;
        case ciExpedido.oruro.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.oruro.new);
          inputPersonaExpedidoEn.value = ciExpedido.oruro.new;
          break;
        case ciExpedido.potosi.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.potosi.new);
          inputPersonaExpedidoEn.value = ciExpedido.potosi.new;
          break;
        case ciExpedido.pando.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.pando.new);
          inputPersonaExpedidoEn.value = ciExpedido.pando.new;
          break;
        case ciExpedido.beni.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.beni.new);
          inputPersonaExpedidoEn.value = ciExpedido.beni.new;
          break;
        case ciExpedido.tarija.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.tarija.new);
          inputPersonaExpedidoEn.value = ciExpedido.tarija.new;
          break;
        case ciExpedido.sucre.old:
          dropdownMenuPerSol
            .parent()
            .children(":first")
            .next()
            .text(ciExpedido.sucre.new);
          inputPersonaExpedidoEn.value = ciExpedido.sucre.new;
          break;
        default:
          if (Boolean(this.state.ciExpedido)) {
            dropdownMenuPerSol
              .parent()
              .children(":first")
              .next()
              .text(this.state.ciExpedido);
            inputPersonaExpedidoEn.value = this.state.ciExpedido;
          } else
            dropdownMenuPerSol.parent().children(":first").next().text("EX");
          break;
      }
      this.setState({ ciExpedido: "EX" });
    }
  }

  handleTipoDocumentoOnChange(event) {
    event.preventDefault();
  }

  handleEstadoCivilOnChange(event) {
    event.preventDefault();
    const optionSelected = event.target.selectedOptions;
    if (optionSelected.length > 0) {
      var constant = Constant[0];
      if (optionSelected[0].value === constant.estado_civil.casado)
        this.setState({ showApellidoCasada: true });
      else this.setState({ showApellidoCasada: false });
    }
  }

  render() {
    return (
      <>
        {this.props.natural === false ? (
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label htmlFor="datos_juridicos[nit]" className="form-label">
                NIT *
              </label>
              <div className="input-group ">
                <input
                  type="text"
                  className="form-control input-uppercase"
                  name="datos_juridicos[nit]"
                  placeholder="Número de Nit"
                  aria-label="Número de Nit"
                  aria-describedby="basic-addon2"
                  required
                  data-parsley-required="true"
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={this.handleSearchByNitOnClick}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="datos_juridicos[razon_social]"
                className="form-label"
              >
                Nombre o Razón Social *
              </label>
              <input
                name="datos_juridicos[razon_social]"
                type="text"
                className="form-control input-uppercase"
                placeholder="Razón Social"
                required
                data-parsley-required="true"
              />
            </div>

            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="datos_juridicos[fecha_constitucion]"
                className="form-label"
              >
                Fecha de Constitución (DD/MM/YYYY) *
              </label>
              <DatePicker
                locale="es"
                dateFormat={Config[4].format}
                selected={this.state.startDateJuridico}
                onChange={this.handleDatePickerDatosJuridicosChange}
                maxDate={new Date()}
                className="form-control"
                name="datos_juridicos[fecha_constitucion]"
                data-parsley-required="true"
                showYearDropdown
                showMonthDropdown
                dropdownMode="select"
                required
              />
            </div>
          </div>
        ) : (
          ""
        )}

        {this.props.natural === false ? (
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor="datos_juridicos[id_tipo_sociedad]"
                className="form-label"
              >
                Tipo de Sociedad:
              </label>
              <select
                className="form-select"
                name="datos_juridicos[id_tipo_sociedad]"
                required
                data-parsley-required="true"
              >
                <option defaultValue value="">
                  Tipo de Sociedad
                </option>
                {this.state.optionsTipoSociedad}
              </select>
            </div>

            <br />
          </div>
        ) : (
          ""
        )}

        {this.props.natural === false ? (
          <div className="row ">
            <div className="col-12  ">
              <h5 className="color-gris">{Texto.representante_legal}</h5>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[id_tipo_documento]"}
              className="form-label"
            >
              Tipo Documento *
            </label>
            <select
              className="form-select"
              name={this.props.nameForm + "[id_tipo_documento]"}
              onChange={this.handleTipoDocumentoOnChange}
              data-parsley-required="true"
              required
            >
              <option defaultValue value="">
                Seleccione Tipo Documento
              </option>
              {this.state.optionsTipoDocumento}
            </select>
          </div>

          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[numero_documento]"}
              className="form-label"
            >
              Número de Documento *
            </label>
            <div className="input-group ">
              <input
                type="text"
                className="form-control input-uppercase"
                name={this.props.nameForm + "[numero_documento]"}
                placeholder="Número Documento"
                data-parsley-required="true"
                required
                pattern="[a-zA-Z0-9-]+"
                data-parsley-pattern="[a-zA-Z0-9-]+"
              />

              <button type="button" className="btn btn-outline-secondary">
                EX
              </button>
              <input
                type="hidden"
                name={this.props.nameForm + "[expedido_en]"}
              />
              <button
                type="button"
                className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="visually-hidden">Toggle Dropdown</span>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                id={this.props.nameForm + "-dropdown-solicitante-tipo-doc"}
              >
                <li>
                  <a className="dropdown-item" href="#" code="CB">
                    Cochabamba
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="LP">
                    La Paz
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="SC">
                    Santa Cruz
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="OR">
                    Oruro
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="CH">
                    Chuquisaca
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="PO">
                    Potosi
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="BN">
                    Beni
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="PD">
                    Pando
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#" code="TJ">
                    Tarija
                  </a>
                </li>
              </ul>
              <button
                className="btn btn-outline-secondary "
                type="button"
                data-toggle="tooltip"
                data-placement="top"
                title="Buscar por CI"
                onClick={this.handleSearchByCiOnClick}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
              </button>
            </div>
          </div>

          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[nacionalidad]"}
              className="form-label"
            >
              Nacionalidad *
            </label>
            <select
              className="form-select"
              name={this.props.nameForm + "[nacionalidad]"}
              required
              data-parsley-required="true"
            >
              <option defaultValue value="">
                Seleccione Nacionalidad
              </option>
              {this.state.optionsNacionalidad}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[nombre]"}
              className="form-label"
            >
              Nombre *
            </label>
            <input
              name={this.props.nameForm + "[nombre]"}
              type="text"
              className="form-control input-uppercase"
              placeholder="Nombre"
              required
              data-parsley-required="true"
              pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
              data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[apellido_paterno]"}
              className="form-label"
            >
              Apellido Paterno *
            </label>
            <input
              name={this.props.nameForm + "[apellido_paterno]"}
              type="text"
              className="form-control input-uppercase"
              placeholder="Apellido Paterno"
              required
              data-parsley-required="true"
              pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
              data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[apellido_materno]"}
              className="form-label"
            >
              Apellido Materno:
            </label>
            <input
              name={this.props.nameForm + "[apellido_materno]"}
              type="text"
              className="form-control input-uppercase"
              placeholder="Apellido Materno"
              pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
              data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[estado_civil]"}
              className="form-label"
            >
              Estado Civil *
            </label>
            <select
              className="form-select"
              name={this.props.nameForm + "[estado_civil]"}
              required
              onChange={this.handleEstadoCivilOnChange}
            >
              <option defaultValue value="">
                Estado Civil
              </option>
              {this.state.optionsEstadoCivil}
            </select>
          </div>
          {this.state.showApellidoCasada ? (
            <div className="col-12 col-md-4 col-lg-4 mb-3">
              <label
                htmlFor={this.props.nameForm + "[apellido_casada]"}
                className="form-label"
              >
                Apellido Casada:
              </label>
              <input
                name={this.props.nameForm + "[apellido_casada]"}
                type="text"
                className="form-control input-uppercase"
                placeholder="Apellido Casada"
                pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
                data-parsley-pattern="[a-zA-Z À-ÿ\u00f1\u00d1]+"
              />
            </div>
          ) : (
            ""
          )}

          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[genero]"}
              className="form-label"
            >
              Género *
            </label>
            <select
              className="form-select"
              name={this.props.nameForm + "[genero]"}
              required
              data-parsley-required="true"
            >
              <option defaultValue value="">
                Género
              </option>
              {this.state.optionsGenero}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-4 col-lg-4 mb-3">
            <label
              htmlFor={this.props.nameForm + "[fecha_nacimiento]"}
              className="form-label"
            >
              Fecha de Nacimiento ( > 18, DD/MM/YYYY) *
            </label>
            <DatePicker
              locale="es"
              dateFormat={Config[4].format}
              selected={this.state.startDatePersona}
              onChange={this.handleDatePickerPersonaChange}
              maxDate={Config[1].anio}
              className="form-control"
              name={this.props.nameForm + "[fecha_nacimiento]"}
              data-parsley-required="true"
              showYearDropdown
              showMonthDropdown
              dropdownMode="select"
              style={{ with: "100%" }}
              required
            />
          </div>
        </div>
      </>
    );
  }
}

export default DataPersona;
