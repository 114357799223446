import React, { useEffect, useState, useRef } from "react";
import AuthService from "./../../../components/Usuario/AuthService";
import { useHistory, Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Config from "./../../../data/config";
import Texto from "./../../../data/es";
import "./../../style/style-simat.css";
import TitlePage from "./../../utils/TitlePage";
import Constant from "./../../../data/constant";
import CustomLoader from "./../../utils/SpinnerDataTable";
import { ToastContainer, toast } from "react-toastify";
import Fetch from "../../utils/Fetch";
import styled from "styled-components";
import ModalPdf from "../../utils/ModalPdf";
import InfoPublicidad from "../ColumnInfo/InfoPublicidad";
import DatosTecnico from "../ColumnInfo/DatosTecnico";
import InfoContribuyente from "../ColumnInfo/InfoContribuyente";
import ModalRequisitos from "./../ModalRequisitos";
import Acciones from "../ColumnInfo/Acciones";
import Links from "./../../../data/link";
import Consulta from "../Consulta";
import InfoTopAdvertising from "./InfoTopAdvertising";
import constant from "./../../../data/constant";
import Columns from "../Columns";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[133].title,
        url: Links[133].url,
    }
];

const ListPublicidadDDJJ = (props) => {
    const fetch = new Fetch();
    fetch.setToast(toast);

    const auth = new AuthService();
    const userAuth = auth ? auth.getProfile() : null;
    const userCode = userAuth ? userAuth.code : null;
    const columnsClass = new Columns(userAuth);
    const esContribuyente = userCode === "CONTRIBUYENTE" ? true : false;

    const history = useHistory();
    const modalPdf = new ModalPdf();
    const consultaSQL = new Consulta();

    const titlePage = Texto.publicity_list;
    const idFormSearch = 'formSearch';
    const [loading, setLoading] = useState(false);

    const [dataAdvertisings, setDataAdvertisings] = useState([]);
    const [dataAdvertisingEstados, setDataAdvertisingEstados] = useState([]);
    const [titleRequisitos, setTitleRequisitos] = useState();
    const [advertisingTemp, setAdvertisingTemp] = useState(undefined);

    const [typeSearch, setTypeSearch] = useState();
    const [codeEstadoUse, setCodeEstadoUse] = useState();

    const tipoSearchRef = useRef();
    const textoSearchRef = useRef();
    // pagination 
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    let listEstadoContribuyente = [
        '"' + Constant[0].estado.en_proceso + '"',
        '"' + Constant[0].estado.aprobado + '"',
        '"' + Constant[0].estado.revisado + '"',
        '"' + Constant[0].estado.certificado + '"',
    ];

    let listEstadoContribuyenteByEstado = [
        Constant[0].estado.en_proceso,
        Constant[0].estado.aprobado,
        Constant[0].estado.revisado,
        Constant[0].estado.certificado,
    ];

    // contribuyente
    if (esContribuyente) {
        listEstadoContribuyente = [
            '"' + Constant[0].estado.en_proceso + '"',
            '"' + Constant[0].estado.aprobado + '"',
            '"' + Constant[0].estado.revisado + '"'
        ];

        listEstadoContribuyenteByEstado = [
            Constant[0].estado.en_proceso,
            Constant[0].estado.aprobado,
            Constant[0].estado.revisado
        ];
    }

    if (!auth.loggedIn()) history.replace(Links[4].url);

    useEffect(() => {

        if (auth.loggedIn()) {
            getDataAdvertising();
            window.jQuery(".sc-kAzzGY").remove(); //pertenece al datatable
            window.jQuery(".cOaGrx").remove();
            window.jQuery(".sc-chPdSV").remove();
            window.jQuery(".rdt_TableHeader").css("padding-left", "0");
            // rdt_TableHeader
            setTimeout(() => {
                window.jQuery(".rdt_Table").css("width", "98%");
                window.jQuery(".hAZHBB").css("width", "30px");
            }, 500);


        } else window.location.href = Links[4].url;
        window.scrollTo(0, 0);
    }, []);


    const formatDateDMY = (fecha) => {
        return window.moment(fecha).format("DD/MM/YYYY");
    };

    const formatDateDMYHIS = (fechahora) => {
        return window.moment(fechahora).format("DD-MM-YYYY H:mm");
    };

    const handleClickViewCrape = async (row) => {
        debugger
        if (row.adv_numero && row.adv_token) {
            window.createBootbox("¿Se ejecutará dos acciones? <br>\n 1. Digitalización de Archivos <br>\n 2. Generación del CRAPE", async function (result) {
                if (result) {
                    const query = consultaSQL.queryDigitalizacion(row.adv_numero, row.adv_token);
                    const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
                    //console.log("*********************************************************** handleClickViewCrape digitalizado ", data, " ************************* ");
                    debugger
                    if (data && data.digitalizarAdvertising && data.digitalizarAdvertising.result) {
                        debugger
                        const url = `${Config[10].url}/api/advertising/report-ddjj/${row.adv_numero}/${row.adv_token}/crape`;
                        modalPdf.showPdf(
                            `${url}?report=preview&auth=${auth.getToken()}`,
                            `Certificado de Registro de Autorización (CRAPE) de Publicidad Nro. Fur: ${row.adv_fur}`,
                            row.adv_token,
                            undefined,
                            `${url}?report=download&auth=${auth.getToken()}`
                        );
                        setTimeout(async () => {
                            await getDataAdvertising();
                        }, 4000);
                    } else
                        toast.warn(data.digitalizarAdvertising ? data.digitalizarAdvertising.message : "Consulte con el administrador, digitalización no realizada");
                }
            });
        } else
            toast.warn("Debe seleccionar una Publicidad");
    };

    // LIQUIDACION  acha
    const handleClickViewLiquidacion = async (row, event) => {
        debugger
        if (row.adv_numero && row.adv_token) {
            window.createBootbox("¿Está seguro de continuar.", function (result) {
                if (result) {
                    const url = `${Config[10].url}/api/advertising/report-ddjj/${row.adv_numero}/${row.adv_token}/liquidacion`;
                    //console.log("url pdf " + url);
                    modalPdf.showPdf(
                        `${url}?report=preview&auth=${auth.getToken()}`,
                        "Liquidación de la Publicidad Nro. de orden : " + row.adv_numero,
                        row.adv_token,
                        undefined,
                        `${url}?report=download&auth=${auth.getToken()}`
                    );
                    setTimeout(async () => {
                        await getDataAdvertising();
                    }, 4000);
                }
            });
        } else
            toast.warn("Debe seleccionar una Publicidad");
    };

    const handleClickViewCertificacion = async (row) => {
        debugger
        if (row.adv_numero && row.adv_token) {
            window.createBootbox("¿Está seguro de continuar, esto ejecutara dos acciones? <br>\n 1. Digitalización de Archivos <br>\n 2. Emisión del Certificado/Licencia", async function (result) {
                if (result) {
                    const queryCheckPaid = `query {
                        checkPaidSinot ( advertising: {numero: "${row.adv_numero}", token: "${row.adv_token}"} ) {
                            message
                            result
                            data{
                                advertising{ numero }
                            }
                        }
                    }`;
                    const checkPaid = await fetch.fetchPostGraphQl(queryCheckPaid, "/graphql", null)
                    //if (checkPaid && checkPaid.checkPaidSinot.result) {   //temporalmente comentado  la verificacion del pago
                    const query = consultaSQL.queryDigitalizacion(row.adv_numero, row.adv_token);
                    const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
                    console.log("......................................handleClickViewCertificacion digitalizado ", data, " ************************** ");
                    debugger
                    if (data && data.digitalizarAdvertising && data.digitalizarAdvertising.result) {
                        const url = `${Config[10].url}/api/advertising/report-ddjj/${row.adv_numero}/${row.adv_token}/elemento`;
                        modalPdf.showPdf(
                            `${url}?report=preview&auth=${auth.getToken()}`,
                            `Licencia de Publicidad/Autorización (Nro. de Orden: ${row.adv_numero})`,
                            row.adv_token,
                            undefined,
                            `${url}?report=download&auth=${auth.getToken()}`
                        );
                        setTimeout(async () => {
                            await getDataAdvertising();
                        }, 5000);
                    } else
                        toast.warn(data.digitalizarAdvertising ? data.digitalizarAdvertising.message : "Consulte con el administrador, digitalización no realizada");
                    /*} else
                        toast.info(checkPaid.checkPaidSinot.message);*/
                }
            });
        } else
            toast.warn("Debe seleccionar una Publicidad");
    };

    const handleclickViewLicenciaActividad = async (row, event) => {
        debugger
        if (row.adv_numero && row.adv_token) {

            const query = consultaSQL.queryDigitalizacion(row.adv_numero, row.adv_token);
            const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
            console.log("......................................handleclickViewLicenciaActividad digitalizado ", data, " ************************** ");
            debugger
            if (data && data.digitalizarAdvertising && data.digitalizarAdvertising.result) {
                const url = `${Config[10].url}/api/advertising/report-ddjj/${row.adv_numero}/${row.adv_token}/actEco`;
                modalPdf.showPdf(
                    `${url}?report=preview&auth=${auth.getToken()}`,
                    `Licencia de Publicidad (Nro. de Orden: ${row.adv_numero})`,
                    row.adv_token,
                    undefined,
                    `${url}?report=download&auth=${auth.getToken()}`
                );
                setTimeout(async () => {
                    await getDataAdvertising();
                }, 4000);
            } else
                toast.warn(data.digitalizarAdvertising ? data.digitalizarAdvertising.message : "Consulte con el administrador, digitalización no realizada");
        } else
            toast.warn("Debe seleccionar una Publicidad");
    };

    const getDataAdvertising = async () => {

        setLoading(true);
        setDataAdvertisings([])
        setTotalRows(0);
        const query = consultaSQL.queryAdvertisingsAll(currentPage, perPage, listEstadoContribuyente);
        fetch.fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                //console.log("----------------------------------------------getDataAdvertising ", data);
                debugger
                if (data && data.advertisings && data.advertisings.result) {
                    setDataAdvertisings(data.advertisings.data.dataSearch)
                    setTotalRows(data.advertisings.pagination.total);
                    getDataEstadosAdvertising(); // estados
                    toast.success(data.advertisings.message);
                }
            }).finally(() => {
                //console.log("----------------------------------------------getDataAdvertising finally ");
                setLoading(false);
            });
    }

    // listado de estados
    const getDataEstadosAdvertising = async () => {
        debugger
        const query = consultaSQL.totalesByAdvertisingAndEstado("", "");
        const response = await fetch.fetchPostGraphQl(query, "/graphql", null);
        if (response && response.totalAdvertisingByEstado && response.totalAdvertisingByEstado.result) {
            const data = response.totalAdvertisingByEstado.data.advertisingByEstadoCount;
            setDataAdvertisingEstados(data);
        }
    }

    // si contribuytente natural o juridico
    /*const contribuyente = (listaPersona) => {

        if (Array.isArray(listaPersona) && listaPersona.length > 0) {
            let objetoTitular = listaPersona[0];
            let natural = parseInt(objetoTitular.natural);
            if (natural === 1) {
                return objetoTitular.Persona != null ? objetoTitular.Persona.nombre + " " + objetoTitular.Persona?.apellidoPaterno + " " + objetoTitular.Persona?.apellidoMaterno : " - ";
            } else {
                debugger
                return objetoTitular.AdTitularJuridico ? objetoTitular.AdTitularJuridico.razonSocial : "";
            }
        }
        return "Sin datos";
    }*/

    const SampleStyle = styled.div`
    padding: 10px;
    display: block;
    width: 90%;
    p {
      font-size: 12px;
      word-break: break-all;
    }
  `;

    const IntentExpandedComponent = ({ data }) => {
        /*let provider = data.provider ? JSON.parse(data.provider) : {};
        provider = provider.length > 0 ? provider[0] : undefined;*/
        hanldeEventExpanded(data);

        return (
            <SampleStyle>
                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <InfoPublicidad data={data} Texto={Texto} formatDateDMY={formatDateDMY} />
                    </div>
                    <div className="col-12 col-md-4">
                        <InfoContribuyente data={data.titular ? data.titular[0] : undefined} Texto={Texto} />
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <Acciones permissions={data.permissions_user} data={data} Texto={Texto} handlePreviewClick={handlePreviewClick} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 mb-3">
                        <span>
                            <strong>{Texto.autorizacion.toUpperCase()} </strong>
                        </span>
                        <hr className="mt-0" />
                        <p>
                            <strong>{Texto.fur_preliquidacion}: </strong>
                            {data.adv_fur_preliquidacion}
                        </p>
                        <p>
                            <strong>Número Licencia: </strong>
                            {data.adv_nro_licencia}
                        </p>
                        <p>
                            <strong>Número Autorización: </strong> {data.adv_nro_autorization}
                        </p>
                        <p>
                            <strong>Usuario Autorización: </strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-4">
                        <DatosTecnico data={data} Texto={Texto} />
                    </div>

                    <div className="col-12 col-md-4 mb-3">
                        <span>
                            <strong>{Texto.requisito.toUpperCase()} </strong>
                        </span>
                        <hr className="mt-0" />
                        <p>
                            <strong>{Texto.requisito}: &nbsp;</strong>
                            <Link
                                to="#"
                                className="me-3"
                                onClick={(e) =>
                                    handleOpendModalRequirement(e, data)
                                }
                                title={`Visualizar todos los requisitos`}
                            > {data.requirements ? data.requirements.length : 0}
                                &nbsp;&nbsp; Ver{" "}
                                <i className="fas fa-clipboard-list h6"></i>
                            </Link>

                            {
                                data.requirements ? (data.requirements.filter(({ estado, token, url }) => estado === "RECHAZADO").length > 0 ? <i className="fas fa-info-circle text-danger h5 "></i> : "") : ""
                            }
                        </p>

                    </div>
                </div>
            </SampleStyle>
        );
    };

    const handlePageChange = async (page) => {
        // by codeEStado
        debugger
        setLoading(true);
        setCurrentPage(page);
        if (typeSearch === "ESTADO") {
            const query = consultaSQL.queryAdvertisingSearch(page, perPage, typeSearch, "", '"' + codeEstadoUse + '"');
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisings && data.advertisings.result) {
                        setDataAdvertisings(data.advertisings.data.dataSearch)
                        toast.success(data.advertisings.message);
                        setTotalRows(data.advertisingsSearch.pagination.total)
                        setLoading(false);
                    }

                    /*if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        debugger
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)
                        setTotalRows(data.advertisingsSearch.pagination.total)
                    } else {
                        toast.error(data.advertisingsSearch.message);
                    }*/
                });
        } else {
            const query = consultaSQL.queryAdvertisingsAll(page, perPage, listEstadoContribuyente);
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger
                    if (data.advertisings && data.advertisings.result) {
                        setDataAdvertisings(data.advertisings.data.dataSearch)
                        toast.success(data.advertisings.message);
                        setLoading(false);
                    }
                    /*if (data.advertisings && data.advertisings.result) {
                        debugger
                        toast.success(data.advertisings.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisings.data.advertising)
                    } else {
                        toast.error(data.advertisings.message);
                    }*/
                });
        }
    };

    const handlePerRowsChange = async (perPage, page) => {
        debugger
        const query = consultaSQL.queryAdvertisingsAll(page, perPage, listEstadoContribuyente);
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                debugger

                if (data.advertisings && data.advertisings.result) {
                    setDataAdvertisings(data.advertisings.data.dataSearch)
                    toast.success(data.advertisings.message);
                    setLoading(false);
                    setCurrentPage(page);
                    setPerPage(perPage);
                }

                /*if (data.advertisings && data.advertisings.result) {
                    debugger
                    toast.success(data.advertisings.message);
                    setLoading(false);
                    setDataAdvertisings(data.advertisings.data.advertising)

                    setCurrentPage(page);
                    setPerPage(perPage);
                } else {
                    toast.error(data.advertisings.message);
                }*/
            });
    };

    const handleSubmitSearchForm = (event) => {
        event.preventDefault();
        setLoading(true);
        const tipoBusqueda = tipoSearchRef.current.value;
        const textoBusqueda = textoSearchRef.current.value;
        if (textoBusqueda.length > 0) {
            const query = consultaSQL.queryAdvertisingSearch(1, perPage, tipoBusqueda, textoBusqueda, listEstadoContribuyente, "", "FUNCIONARIO");
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger

                    if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        setDataAdvertisings(data.advertisingsSearch.data.dataSearch)
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);
                        setTotalRows(data.advertisingsSearch.pagination.total)
                    }


                    /*if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        debugger
                        //console.log("--------------------- >>>> handleSubmitSearchForm data.advertisingsSearch >>>> ", data.advertisingsSearch);
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)

                        setTotalRows(data.advertisingsSearch.pagination.total)
                    } */
                });
        } else {
            getDataAdvertising();
        }
    };

    const handleSearchAdvertisingByEstado = async (event, codeEstadoActual) => {
        event.preventDefault();
        setLoading(true);
        setTypeSearch("");
        setCodeEstadoUse("");
        const tipoBusqueda = "ESTADO";
        const textoBusqueda = "";
        debugger

        if (codeEstadoActual.length > 0) {
            const query = consultaSQL.queryAdvertisingSearch(1, perPage, tipoBusqueda, textoBusqueda, '"' + codeEstadoActual + '"');
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((data) => {
                    debugger

                    if (data.advertisings && data.advertisings.result) {
                        setDataAdvertisings(data.advertisings.data.dataSearch)
                        toast.success(data.advertisingsSearch.message);
                        setLoading(false);

                        setTypeSearch(tipoBusqueda);
                        setCodeEstadoUse(codeEstadoActual);
                        setTotalRows(data.advertisingsSearch.pagination.total);
                        setLoading(false);
                    }

                    /*if (data.advertisingsSearch && data.advertisingsSearch.result) {
                        //console.log("--------------------- >>>> data.advertisingsSearch >>>> ", data.advertisingsSearch);
                        debugger
                        setTypeSearch(tipoBusqueda);
                        setCodeEstadoUse(codeEstadoActual);
                        toast.success(data.advertisingsSearch.message);
                        setDataAdvertisings(data.advertisingsSearch.data.advertising)
                        setTotalRows(data.advertisingsSearch.pagination.total)
                        setLoading(false);
                    } else {
                        toast.error(data.advertisingsSearch.message);
                    }*/
                });
        } else {
            getDataAdvertising();
        }
    };


    // digitalizar archivos de la publicidad, esto debe llevar al backend, deprecado
    /* const handleUploadFileByDigitalizacion = async (row) => {
         try {
             debugger;
             const urlPdfRepositorio = `${Config[10].url}/`;  //src/
 
             // Obtener los datos de publicidad
             const rowAdvertising = await dataPublicidad(row.token);
 
             if (!rowAdvertising) {
                 throw new Error("No se pudo obtener la información de la publicidad.");
             }
 
             const isCertificado = rowAdvertising.estado_code === constant[0].estado.certificado;
 
             if (isCertificado && rowAdvertising.digitalizado === 0) {
                 // Consultar los requisitos del sitio
                 const query = consultaSQL.queryRequirementsSiteAdvertising(row.token, "REQUIREMENT");
                 const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
 
                 if (!data || !data.requirementsSiteAdvertising || !data.requirementsSiteAdvertising.result) {
                     throw new Error("No se encontraron requisitos del sitio.");
                 }
 
                 const responseDataRequisitos = data.requirementsSiteAdvertising.data.advertisingRequirementSite;
                 const listUrlsRequirements = responseDataRequisitos.map(objeto => ({
                     url: urlPdfRepositorio + objeto.url,
                     tipo: objeto.AdRequirementSite.typeName
                 }));
 
                 const infoObjectContribuyente = contribuyenteInfoDigitalizacion(rowAdvertising.AdTitulars);
                 const tipoTramite = tipoTramitePublicidad(rowAdvertising.clasificationCategoriesAdv, parseInt(rowAdvertising.fur));
 
                 if (Object.keys(infoObjectContribuyente).length > 0 && tipoTramite) {
                     let formData = new FormData();
 
                     formData.append("contribuyente[tipo]", infoObjectContribuyente.tipoContribuyente.toUpperCase());
                     if (infoObjectContribuyente.tipoContribuyente.toUpperCase() === "NATURAL") {
                         formData.append("contribuyente[extension]", infoObjectContribuyente.extension);
                         formData.append("contribuyente[nrodocumento]", infoObjectContribuyente.nrodocumento);
                         formData.append("contribuyente[nombre]", infoObjectContribuyente.nombre);
                         formData.append("contribuyente[paterno]", infoObjectContribuyente.paterno);
                         formData.append("contribuyente[materno]", infoObjectContribuyente.materno);
                     } else {
                         formData.append("contribuyente[nrodocumento]", infoObjectContribuyente.nrodocumento);
                         formData.append("contribuyente[razon_social]", infoObjectContribuyente.razon_social);
                     }
 
                     // info publicidad                               
                     formData.append("publicidad[numero]", rowAdvertising.numeroLicencia);
                     formData.append("publicidad[nro_autorizacion]", rowAdvertising.numeroAutorization);
                     formData.append("publicidad[tipo_tramite]", tipoTramite);
                     formData.append("publicidad[requisitos]", JSON.stringify(listUrlsRequirements)); // listUrlsRequirements
 
                     const digitalizacionData = await fetch.fetchPost(formData, "api/publicidad/digitalizar-archivo");
 
                     if (digitalizacionData && digitalizacionData.status) {
                         debugger
                         //console.log("respuesta de datos - digitaliuzacion :   ", digitalizacionData)
                         const queryUpdate = consultaSQL.queryUpdateDigitalizacionAdvertising(rowAdvertising.token);
                         const updateData = await fetch.fetchPostGraphQl(queryUpdate, "/graphql", null);
                         console.log("updateData - ", updateData);
                         if (updateData.updateFilesUploadAdvertising && updateData.updateFilesUploadAdvertising.result) {
                             debugger
                             fetch.toast.success(digitalizacionData.message);
                         } else {
                             throw new Error("Error actualizando la digitalización de archivos.");
                         }
                     } else {
                         throw new Error(digitalizacionData.message || "Error al digitalizar los archivos.");
                     }
                 } else {
                     toast.error("No existen datos para realizar la digitalización.");
                 }
             } else {
                 console.error("No se digitalizaron los archivos de la publicidad, verifique si ya esta digitalizado.");
             }
         } catch (error) {
             console.log("catch - Ocurrió un error, vuelva a intentarlo.");
         }
     };
 */
    /* const contribuyenteInfoDigitalizacion = (listaPersona) => {
         let resultado = {};
 
         if (Array.isArray(listaPersona) && listaPersona.length > 0) {
             let objetoTitular = listaPersona[0];
             let natural = parseInt(objetoTitular.natural);
             if (natural === 1) {
                 resultado = {
                     tipoContribuyente: "NATURAL",
                     extension: objetoTitular.Persona != null ? objetoTitular.Persona.expedidoEn : "",  // expedidoEn
                     nombre: objetoTitular.Persona != null ? objetoTitular.Persona.nombre : "",
                     paterno: objetoTitular.Persona != null ? objetoTitular.Persona.apellidoPaterno : "",
                     materno: objetoTitular.Persona != null ? objetoTitular.Persona.apellidoMaterno : "",
                     nrodocumento: objetoTitular.Persona != null ? objetoTitular.Persona.numeroDocumento : "",
                 }
             } else {
                 debugger
                 resultado = {
                     tipoContribuyente: "JURIDICA",
                     nrodocumento: objetoTitular.AdTitularJuridico != null ? objetoTitular.AdTitularJuridico.numeroDocumento : "",
                     razon_social: objetoTitular.AdTitularJuridico != null ? objetoTitular.AdTitularJuridico.razonSocial : "",
                 }
             }
         }
         return resultado;
     }
 
     const tipoTramitePublicidad = (clasificationCategoriesAdv, fur) => {
 
         let resultado = null;
         const codeClasificador = clasificationCategoriesAdv.length > 0 ? clasificationCategoriesAdv[0].clasificationCategoryPublicity.clasification.code : null;
 
         if (codeClasificador === constant[0].publicidad_clasificador.code_empresarial && fur > 0) {
             resultado = "CRAPE";
         } else if (codeClasificador === constant[0].publicidad_clasificador.code_eventual && fur > 0) {
             resultado = "Pub. Eventual";
         } else if (codeClasificador === constant[0].publicidad_clasificador.code_actividad && fur > 0) {
             resultado = "Pub. Act. Económica";
         } else if (codeClasificador === constant[0].publicidad_clasificador.code_empresarial && (!Number(fur))) {
             resultado = "Pub. Empresarial";
         }
 
         return resultado;
     }
 
     const dataPublicidad = async (token) => {
         const query = consultaSQL.queryInfoAdvertisingByToken(token);
 
         try {
             const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
             if (data && data.advertising && data.advertising.result) {
                 // console.log('Returning advertising data:', data.advertising.data.advertising);
                 return data.advertising.data.advertising;
             }
             return null;
         } catch (error) {
             console.error('Error fetching data:', error);
             return null;
         }
     }*/

    // contar total, REVISADO , PENDIENTE_APROBACION, RECHAZADO (AdAdvertisingRequirementSite)
    /*const requisitosPublicidadTotales = (listAdRequeriments) => {
        const pendienteAprobacion = listAdRequeriments.filter(item => item.estado_code === "PENDIENTE_APROBACION");
        const revisado = listAdRequeriments.filter(item => item.estado_code === "REVISADO");
        const rechazado = listAdRequeriments.filter(item => item.estado_code === "RECHAZADO");

        // Obtener el total
        return {
            total: listAdRequeriments.length,
            pendiente: pendienteAprobacion.length,
            revisado: revisado.length,
            rechazado: rechazado.length
        }
    }*/

    const handleAprobarDatosClick = (row) => {
        debugger
        window.createBootbox("¿Está seguro de continuar con la confirmación de datos de la publicidad ? \n FUR: " + row.adv_fur + " Número:" + row.adv_numero, function (result) {
            if (result === true) {
                const query = consultaSQL.queryUpdateEstadoAdvertising(row.estado_code, "APROBADO", row.adv_token);
                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result) {
                            toast.success(data.updateEstadoAdvertising.message);
                            getDataAdvertising();
                        }
                    });

            }
        });
    }

    const handleReaperturarClick = (row) => {
        debugger
        //event.preventDefault();
        window.createBootbox("¿Está seguro de desbloquear la publicidad con número de orden : " + row.adv_numero + "?", function (result) {
            if (result) {
                debugger
                const query = consultaSQL.queryUpdateEstadoAdvertising(row.estado_code, Constant[0].estado.en_proceso, row.adv_token);
                fetch
                    .fetchPostGraphQl(query, "/graphql", null)
                    .then((data) => {
                        debugger
                        if (data.updateEstadoAdvertising && data.updateEstadoAdvertising.result) {
                            toast.success(data.updateEstadoAdvertising.message);
                            setTimeout(() => {
                                getDataAdvertising();
                            }, 300);

                        }
                    });
            }
        });
    }

    //preview pdf, actual acha
    const handlePreviewClick = (row) => {
        debugger
        if (row.adv_numero && row.adv_token) {
            const url = `${Config[10].url}/api/advertising/report-ddjj/${row.adv_numero}/${row.adv_token}/ddjj`;
            modalPdf.showPdf(
                `${url}?report=preview&auth=${auth.getToken()}`,
                "Declaración Jurada de la Publicidad Nro. de Orden : " + row.adv_numero,
                row.adv_token,
                undefined,
                `${url}?report=download&auth=${auth.getToken()}`
            );
        } else
            toast.warn("Debe seleccionar una Publicidad");
    }

    const handleDeleteClick = (row) => {
        window.createBootbox("Estamos Trabajando en ello!!!", function (result) {
            if (result) {
            }
        });
        debugger
    }

    const handleClickUpdateData = (row, event) => {
        debugger
        event.preventDefault();
        const rowHtml = event.currentTarget.closest(".rdt_TableRow");
        if (rowHtml) {
            rowHtml.firstElementChild.firstChild.click();
            setTimeout(async () => {
                const contentTableRow = rowHtml.nextElementSibling;
                if (
                    contentTableRow &&
                    contentTableRow.classList.contains("rdt_ExpanderRow")
                ) {
                    debugger
                    veryStateUpdateData(row.adv_numero, row.adv_token);
                }
            }, 100);
        }
    }

    const veryStateUpdateData = (numero, token) => {
        const linkUpdateCheckDT = document.getElementById("link-update-check-datos-tecnicos");
        const linkUpdateCheckReq = document.getElementById(
            "link-update-check-requisitos"
        );
        const linkUpdatecheckContribuyente = document.getElementById(
            "link-update-check-contribuyente"
        );
        if (linkUpdateCheckDT || linkUpdateCheckReq || linkUpdatecheckContribuyente) {

            const query = consultaSQL.queryStateAdvertising(numero, token);
            fetch
                .fetchPostGraphQl(query, "/graphql", null)
                .then((resp) => {
                    if (resp.advertisingDataState.result) {
                        const stateAdv = resp.advertisingDataState.data.stateAdvertising

                        linkUpdateCheckReq.classList.remove("fa-check-circle");
                        linkUpdateCheckReq.classList.remove("fa-times-circle");
                        if (stateAdv.isCompleteDataRequirement)
                            linkUpdateCheckReq.classList.add("fa-check-circle");
                        else linkUpdateCheckReq.classList.add("fa-times-circle");

                        linkUpdateCheckDT.classList.remove("fa-check-circle");
                        linkUpdateCheckDT.classList.remove("fa-times-circle");
                        if (stateAdv.isCompleteDataTechnical)
                            linkUpdateCheckDT.classList.add("fa-check-circle");
                        else linkUpdateCheckDT.classList.add("fa-times-circle");

                        linkUpdatecheckContribuyente.classList.remove("fa-check-circle");
                        linkUpdatecheckContribuyente.classList.remove("fa-times-circle");
                        if (stateAdv.isCompleteDataContribuyente)
                            linkUpdatecheckContribuyente.classList.add("fa-check-circle");
                        else linkUpdatecheckContribuyente.classList.add("fa-times-circle");
                    }
                });
        }
    };

    const handleOpendModalRequirement = (e, publicidad) => {
        e.preventDefault();
        debugger
        setAdvertisingTemp(publicidad);
        setTitleRequisitos("Requisitos de la Publicidad  " + publicidad.adv_numero);
    };

    const hanldeEventExpanded = (row) => {
        setTimeout(async () => {
            debugger
            //veryFiled(row.token, row.numero);
            veryStateUpdateData(row.adv_numero, row.adv_token);
            //findAllRequirements(row.adv_token);
        }, 100);
    };

    const handleEventClearRequisitos = () => {
        setAdvertisingTemp(undefined);
        setTitleRequisitos("");
    };

    return (
        <section className="items-grid section mt-5 mb-5">
            <TitlePage
                titlePage={titlePage}
                breadcrumbs={breadcrumbs}
                position={"left"}
                leftfull={false}
            />
            <div className="container mt-4">
                <InfoTopAdvertising
                    Links={Links}
                    fetch={fetch}
                    toast={toast}
                    history={history}
                    auth={auth}
                    listCodeEstado={listEstadoContribuyenteByEstado}
                    handleSearchAdvertisingByEstado={handleSearchAdvertisingByEstado}
                    dataAdvertisingEstados={dataAdvertisingEstados}
                />
                <form
                    action=""
                    className=""
                    name={idFormSearch}
                    id={idFormSearch}
                    method="post"
                    noValidate
                    onSubmit={handleSubmitSearchForm}
                >
                    <div className="d-flex flex-row-reverse">
                        <div className="p-2">
                            <div className="input-group mb-3">
                                <select
                                    className="form-select "
                                    name="type"
                                    ref={tipoSearchRef}
                                >
                                    <option key={1} value="NUMERO">
                                        Número de Orden
                                    </option>
                                    <option key={0} value="FUR">
                                        Número de FUR
                                    </option>
                                </select>
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    className="form-control"
                                    placeholder="FUR/NUMERO"
                                    aria-label="search"
                                    ref={textoSearchRef}
                                />
                                <button
                                    className="btn btn-outline-secondary"
                                    type="submit"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Buscar"
                                >
                                    <i className="fa fa-search" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

                <DataTable
                    columns={columnsClass.columns(
                        handleAprobarDatosClick,
                        handleReaperturarClick,
                        handlePreviewClick,
                        handleDeleteClick,
                        handleClickViewCrape,
                        handleClickViewLiquidacion,
                        handleClickViewCertificacion,
                        handleclickViewLicenciaActividad,
                        handleClickUpdateData,
                        formatDateDMYHIS,
                    )}
                    data={dataAdvertisings}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover
                    noDataComponent={Texto.there_are_no_records_to_display}
                    progressComponent={<CustomLoader />}
                    expandableRowsComponent={<IntentExpandedComponent />}
                    expandableRows
                />
            </div>

            <ModalPdf />

            {advertisingTemp && titleRequisitos ? (
                <ModalRequisitos
                    toast={toast}
                    title={titleRequisitos}
                    userAuth={userAuth}
                    Constant={Constant[0]}
                    advertising={advertisingTemp}
                    clearRequisitos={handleEventClearRequisitos}
                />
            ) : (
                ""
            )}

            <ToastContainer
                enableMultiContainer
                containerId={"Z"}
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
            <ToastContainer />
        </section >
    );
};

export default ListPublicidadDDJJ;
