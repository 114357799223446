import React, { useState, useEffect, useRef } from "react";
import Links from "../../data/link";
import MapCatastroPublicModal from "../component/MapCatastroPublicModal";
import DescriptionZonaComunaMapAdv from "./_common/DescriptionZonaComunaMapAdv";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import Constant from "../../data/constant";
import Consulta from "./Consulta";

const Step2ActEconomicaFormValideDatosTecnicos = (props) => {
    const { fetch, toast, nextClick, backClick, auth, history, dataForm, calculoSuperficieM2, stepNumber, eventCategory, Texto } = props;

    const idMapAdvertising = "idMapCatastroPublicAdvertising";
    const idMapActEco = "idMapCatastroPublicAdvertising";
    const TYPE_MAP_ACT_ECO = "ACT_ECO";
    const TYPE_MAP_ADV = "ADV";
    const consultaSQL = new Consulta();

    const [optionsCategory, setOptionsCategory] = useState([]);
    const [optionsCategorization, setOptionsCategorization] = useState([]);
    const [optionsCaracterization, setOptionsCaracterization] = useState([]);

    const [showMapAdvertising, setShowMapAdvertising] = useState(false);
    const [dataInmuebleAdvertising, setDataInmuebleAdvertising] = useState({});
    const [dataActividadEconomicaRuat, setDataActividadEconomicaRuat] = useState(undefined);
    const refSelectCategory = useRef(null);
    const [dataInmuebleCatastroInfo, setDataInmuebleCatastroInfo] = useState(null);
    const [zonaInfo, setZonaInfo] = useState(null);

    const refInputNroActividadEco = useRef(null);
    const refSelectCaracterization = useRef(null);
    const refSelectCategorization = useRef(null);
    const refInputDimension = useRef(0);
    const refInputAncho = useRef(0);
    const refInputAlto = useRef(0);
    const refInputDireccion = useRef(null);
    const refInputDireccionY = useRef(null);
    const refInputDireccionEntre = useRef(null);
    const refInputRazonSocial = useRef(null);

    const maxDateDatePicker = new Date();
    maxDateDatePicker.setMonth(maxDateDatePicker.getMonth() + 11);

    const minDateDatePicker = new Date();
    minDateDatePicker.setDate(1);
    // rango de fecha - 3 años

    const formattNow = window.moment().format('DD/MM/YYYY');
    const fechaVigencia = window.moment().add(36, 'months');
    const formattedDate = fechaVigencia.format('DD/MM/YYYY');
    const resultadoDivGeoreferencial = document.getElementById('resultado_georeferencial');

    useEffect(() => {

        if (!auth.loggedIn()) history.replace(Links[4].url);
        if (dataForm) {
            optionsClategory()
        }
    }, [dataForm.admision]);  //dataForm.admision

    const configureData = (data, typeMap) => {
        debugger
        if (typeMap === TYPE_MAP_ACT_ECO) {
            const nodeImage = document.getElementById("imgActEco01")
            nodeImage.src = "data:image/png;base64, " + data.image64;
            nodeImage.parentElement.classList.remove("d-none");
        }

        if (typeMap === TYPE_MAP_ADV) {
            setDataInmuebleAdvertising(data);   
        }
    }

    const optionsClategory = () => {
        debugger
        let idClasification = (dataForm ? dataForm.admision.clasification.id : null);
        const query = consultaSQL.queryCategoryPublicity(idClasification);

        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.categoryPublicities && data.categoryPublicities.result) {
                    const category = data.categoryPublicities.data.clasificacionCategoryPublicity;
                    let listItems = category.map((item, index) => {
                        const categoryPublicity = item.categoryPublicity;

                        const children = category.map((itemC, indexC) => {
                            const categoryPublicityint = itemC.categoryPublicity;
                            if (categoryPublicityint.idFatherCategoryPublicity === categoryPublicity.id) {
                                return (<option
                                    key={indexC}
                                    value={categoryPublicityint.id}
                                    name={categoryPublicityint.name}
                                >
                                    {categoryPublicityint.name}
                                </option>)
                            }
                        });

                        if (categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity)
                            return (<optgroup
                                key={index}
                                value={categoryPublicity.id}
                                label={categoryPublicity.name}
                            >
                                {children}
                            </optgroup>);

                    });

                    let listItemSingle = category.map((item, index) => {
                        const categoryPublicity = item.categoryPublicity;

                        if (!categoryPublicity.hasChildren && !categoryPublicity.idFatherCategoryPublicity)
                            return (<option
                                key={index}
                                value={categoryPublicity.id}
                                name={categoryPublicity.name}
                            >
                                {categoryPublicity.name}
                            </option>);
                    });
                    debugger
                    listItems = listItems.filter((item) => item);
                    listItemSingle = listItemSingle.filter((item) => item);
                    setOptionsCategory([...listItems, ...listItemSingle]);
                }
            });
    }

    const handleChangeCategory = (event) => {
        debugger
        const selectCategory = event.target;
        setOptionsCaracterization([]);
        setOptionsCategorization([]);
        //setOptionComunicationInstitutional([]);

        if (selectCategory && parseInt(selectCategory.selectedOptions[0].value) !== 0) {
            const currentCategory = selectCategory.selectedOptions[0];
            if (parseInt(currentCategory.value)) {
                loadOptionCaracterization(currentCategory.value);
                loadCategorization(currentCategory.value);
                //loadComunicationInstitutional(currentCategory.value);
            }
        }
    }

    const loadOptionCaracterization = (idCategoryPublicity) => {
        debugger
        const query = `query{ caracterizationByCategoryPublicity(categoryPublicity: {id: ${idCategoryPublicity}}) { message result data {caracterization {  id  name  description  AdClasificationCatPubliCaracterizations{ id idCaracterization idClasificationCategory valor  }} }  }}`;
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.caracterizationByCategoryPublicity && data.caracterizationByCategoryPublicity.result) {
                    const caracterization = data.caracterizationByCategoryPublicity.data.caracterization;
                    let listItems = caracterization.map((item, index) => {
                        const clasCatPubli = item.AdClasificationCatPubliCaracterizations[0];
                        return (<option
                            key={index}
                            value={item.id}
                            label={item.name}
                            valor={clasCatPubli.valor}
                            description={item.description}
                        >
                            {item.name}
                        </option>);

                    });
                    setOptionsCaracterization(listItems);
                }
            });
    }

    const loadCategorization = (idCategoryPublicity) => {
        debugger
        const query = `query{  categorizationByCategoryPublicity (categoryPublicity: {id: ${idCategoryPublicity}} ) {    message    result    data {      categorization {        name        id      }    }  }}`;
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.categorizationByCategoryPublicity && data.categorizationByCategoryPublicity.result) {
                    const categorizationList = data.categorizationByCategoryPublicity.data.categorization;
                    let listItems = categorizationList.map((item, index) => {
                        return (<option
                            key={index}
                            value={item.id}
                            label={item.name}
                        >
                            {item.name}
                        </option>);

                    });
                    setOptionsCategorization(listItems);
                }
            });
    }

    const handleClickInfoCaracterization = (event) => {
        event.preventDefault();
        debugger
        const button = event.target;
        const selectCaract = button.closest(".input-group").firstChild.selectedOptions[0];
        const description = selectCaract.getAttribute("description");
        if (description)
            window.alertBootBox(description);
    }

    const handleEventMapAdvertising = async (dataInmuebleCatastro) => {
        debugger
        const imgIconButton = document.getElementById("imgAdvertising01");
        imgIconButton.src = dataInmuebleCatastro.fullBase6image4;
        imgIconButton.classList.remove("d-none");
        setShowMapAdvertising(false);
        setDataInmuebleCatastroInfo(null);
        setZonaInfo(null)

        if (dataInmuebleCatastro.nroInmueble && parseInt(dataInmuebleCatastro.nroInmueble) > 0) {
            const inmueble = await findInmueble(dataInmuebleCatastro.nroInmueble, "NIM");
            debugger;

            if (inmueble) {
                const data = {
                    inmueble: inmueble.data.resInmueble,
                    datosTecnicos: inmueble.data.resTecnico,
                    datosCatastro: dataInmuebleCatastro
                };
                setDataInmuebleCatastroInfo(dataInmuebleCatastro);
                setZonaInfo(dataInmuebleCatastro.zonaTributaria)
                configureData(data, TYPE_MAP_ADV);
            } else {
                //aqui cargar el por default
                configureData({}, TYPE_MAP_ADV);
            }
        }
    }

    const handleOpenMapGeneric = async (event, type) => {
        debugger
        handleHiddenDivMessage();
        setZonaInfo(null)
        if (type === TYPE_MAP_ACT_ECO) {
            setShowMapAdvertising(false);

        }

        if (type === TYPE_MAP_ADV) {

            setShowMapAdvertising(true);
        }
        const dataInmueble = {
            codigoCatastral: undefined
        }
        loadInmuebleRuat(dataInmueble, type);
    }

    const loadInmuebleRuat = async (dataInmueble, type) => {
        debugger
        if (
            !dataInmueble.codigoCatastral
        ) {
            setTimeout(() => {
                const idMap = type === TYPE_MAP_ADV ? idMapAdvertising : idMapActEco;
                const modalCatastro = new window.bootstrap.Modal(
                    document.getElementById(idMap)
                );
                modalCatastro.show();
            }, 100);
        } else {
            await loadInmuebleCatastro(dataInmueble.codigoCatastral);
        }
    };

    const handlekClickSearchActEco = async (event) => {
        event.preventDefault();
        refInputRazonSocial.current.value = "";
        debugger
        await eventInputSearchActEconomica();
    }

    const handleOnchangeLicFuncionamiento = async () => {
        refInputRazonSocial.current.value = "";
    }

    /*
    const handlekeyDownSearchActEco = async (event) => {
        if (event.key === "Enter" && event.target.value.length >= 3) {
            debugger
            event.preventDefault();
            await eventInputSearchActEconomica();
        }
    }*/

    const eventInputSearchActEconomica = async (value) => {
        debugger
        let input = refInputNroActividadEco.current;
        setDataActividadEconomicaRuat(undefined);
        if (input.value && input.value.length >= 3) {
            const actividadEconomicaRuat = await findActEcoByNumero(input.value);

            if (actividadEconomicaRuat) {
                //aqui buscar 
                const propietariosActEco = actividadEconomicaRuat.propiedades;   //solo tomar el primero
                const ubicacionActEco = actividadEconomicaRuat.datosUbicacion;

                debugger
                refInputRazonSocial.current.value = actividadEconomicaRuat.tipoActividad + "(" + actividadEconomicaRuat.razonSocial + ")";

                if (propietariosActEco.length > 0 && propietariosActEco[0].codigoContribuyente) {
                    const contribuyente = await findContribuyenteByCodContribuyente(propietariosActEco[0].codigoContribuyente);
                    let ci = 0;
                    if (contribuyente && contribuyente.natural)
                        ci = contribuyente.natural.numeroDocumento;
                    actividadEconomicaRuat.tipoContribuyente = contribuyente.tipoContribuyente;
                    setDataActividadEconomicaRuat(actividadEconomicaRuat);
                }
            } 
        }
    }

    const handleOpenImageAdvertisingBase64 = (event) => {
        const link = event.target;
        const image = new Image();
        image.src = link.src;
        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    const loadInmuebleCatastro = async (codigo_catrastral) => {
        debugger
        const resInmuebleCatastro = await fetch.fetchGet(
            `api-ruat/inmuebles-catastro/codigo-catastral/${codigo_catrastral}`
        );
        if (resInmuebleCatastro && resInmuebleCatastro.status) {
            if (resInmuebleCatastro.data.inmueble.length > 0) {
                return resInmuebleCatastro.data.inmueble[0];
            }
        }
        return undefined;
    };

    const findInmueble = async (nroInmueble, identificador) => {
        let url = undefined;
        debugger
        if (identificador === "CAT")
            url = `api-ruat/inmuebles/codigo-catastral/${nroInmueble}`;
        if (identificador === "NIM")
            url = `api-ruat/inmuebles/nro-inmueble/${nroInmueble}`;

        if (url) {
            const respInmueble = await fetch.axiosAsyncGet(url);
            if (Boolean(respInmueble) && respInmueble.status)
                return respInmueble;
        }
        return null;
    }

    const findActEcoByNumero = async (numeroAct) => {
        const resActEco = await fetch.fetchGet(
            `api/actividad-economica/get-by-nro-act-eco-ruat/${numeroAct}`
        );
        if (resActEco && resActEco.status) {
            if (resActEco.ActividadEconomica)
                return resActEco.ActividadEconomica;
        }
        return undefined;
    }

    const findContribuyenteByCodContribuyente = async (codigoContribuyente) => {

        const resActEco = await fetch.fetchGet(
            `api-ruat/contribuyentes/cc/${codigoContribuyente}`
        );
        if (resActEco && resActEco.status) {
            debugger
            if (resActEco.data.contribuyente.length > 0) {
                return resActEco.data.contribuyente[0];
            }
        }
        return undefined;
    }

    // hiden div message
    const handleHiddenDivMessage = () => {
        if (!resultadoDivGeoreferencial.classList.contains("d-none")) {
            resultadoDivGeoreferencial.classList.add("d-none");
        }
    }

    const nextForm = (e) => {
        debugger
        handleHiddenDivMessage();
        const selectCategory = refSelectCategory.current;
        const selectCaracterization = refSelectCaracterization.current;
        const selectCategorization = refSelectCategorization.current;
        const inputAncho = refInputAncho.current;
        const inputAlto = refInputAlto.current;
        const inputDireccion = refInputDireccion.current;
        const inputDireccionEntre = refInputDireccionEntre.current;
        const inputDireccionY = refInputDireccionY.current;
        const inputRangeDate = document.getElementsByName("datosTecnicos-tiempo");
        let next = true;

        const data = {
            advertising: {
                inmueble: dataInmuebleAdvertising,
                category: selectCategory.selectedOptions[0].value,
                caracterization: selectCaracterization.selectedOptions[0].value,
                categorization: selectCategorization.selectedOptions[0].value,
                categoryNombre: selectCategory.options[selectCategory.selectedIndex].text,
                //superficie: superficie.value,
                ancho: inputAncho.value,
                alto: inputAlto.value,
                direccion: inputDireccion.value,
                direccionEntre: inputDireccionEntre.value,
                directionY: inputDireccionY.value,
                rangeDate: inputRangeDate[0].value
            },
            actividadEconomica: {
                actividadEconomicaRuat: dataActividadEconomicaRuat,   //esto ver de como fusionar
                //inmuebleActividadEconomicaSimat: dataInmuebleActEcoSimat,   //esto ver de como fusionar
                //inmuebleCatastro: dataInmuebleCatastro
            }
        }

        if (dataInmuebleAdvertising && Object.keys(dataInmuebleAdvertising).length === 0 && dataInmuebleAdvertising.constructor === Object) {
            next = false;
            toast.error("Ubicación Geoespacial de la Publicidad, es obligatorio.");
        }

        if (dataActividadEconomicaRuat && Object.keys(dataActividadEconomicaRuat).length === 0 && dataActividadEconomicaRuat.constructor === Object) {
            next = false;
            toast.error("Actividad Economica no localizada, debe proceder con el trámite de una licencia.");
        }

        const existeNumeroActividad = refInputNroActividadEco.current.value.length > 0;
        const noExisteFuncionamiento = refInputRazonSocial.current.value.length === 0

        if (existeNumeroActividad && noExisteFuncionamiento) {
            next = false;
            toast.error("El nombre o razón social es obligatorio.");
        }

        if (!zonaInfo) {
            resultadoDivGeoreferencial.classList.remove("d-none");
            next = false;
        }

        window.scrollTo(0, 0);
        if (next)
            nextClick(e, stepNumber, false, data)
        else   
            e.preventDefault();
    }

    const onChangeSuperficie = () => {
        if(refInputAlto.current.value && refInputAncho.current.value){
            let area = calculoSuperficieM2(refInputAlto.current.value, refInputAncho.current.value);
            refInputDimension.current.value = area;
        }
    }

    return (
        <>
            <div className="main-wizard ">
                <HeaderTitlecard titulo={'Datos Técnicos'} descripcion={Constant[0].publicidad_step_new[1]} visibleRequired={true} />
                <form
                    className="contact__form"
                    id={"formValideDatosTecnicos"}
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-category"} className="form-label">
                                (*) Tipo Publicidad
                            </label>
                            <select className="form-select" ref={refSelectCategory} onChange={handleChangeCategory}
                                data-parsley-required="true" required name="datosTecnicos-category">
                                <option
                                    key={0}
                                    defaultValue
                                    value=""
                                >
                                    Seleccione Tipo Publicidad
                                </option>
                                {optionsCategory}
                            </select>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-caracterization"} className="form-label">
                                (*) Caracterización
                            </label>
                            <div className="input-group ">
                                <select className="form-select" ref={refSelectCaracterization}
                                    data-parsley-required="true" required name="datosTecnicos-caracterization">
                                    <option
                                        key={0}
                                        defaultValue
                                        value=""
                                    >
                                        Seleccione Caracterización
                                    </option>
                                    {optionsCaracterization}
                                </select>

                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={handleClickInfoCaracterization}
                                >
                                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-categorization"} className="form-label">
                                (*) Categorización
                            </label>
                            <select className="form-select" ref={refSelectCategorization}
                                data-parsley-required="true" required name="datosTecnicos-categorization">
                                <option
                                    key={0}
                                    defaultValue
                                    value=""
                                >
                                    Seleccione Categorización
                                </option>
                                {optionsCategorization}
                            </select>

                        </div>
                    </div>

                    <div className="row mt-2 mb-3">
                        <div className="col-sm-12 col-md-6 ">
                            <label htmlFor="datosTecnicos-tiempo" className="form-label">(*) {Texto.periodo_autorizacion}</label>
                            <input type="text" value={formattNow + " - " + formattedDate} data-parsley-required="true" readOnly name="datosTecnicos-tiempo" />

                        </div>
                    </div>

                    <fieldset className="mt-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.dimension_publicidad}
                        </label>
                        </legend>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-ancho" className="form-label">(*) Ancho(metros)</label>
                                <input type="text"
                                    id="datosTecnicos-ancho"
                                    name="datosTecnicos-ancho"
                                    ref={refInputAncho}
                                    data-parsley-required="true"
                                    //data-parsley-type="number"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    min={0.2}
                                    max={999}
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    ata-parsley-trigger="keyup"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-alto" className="form-label">(*) Alto (metros)</label>
                                <input type="text"
                                    id="datosTecnicos-alto"
                                    name="datosTecnicos-alto"
                                    ref={refInputAlto}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    //data-parsley-type="integer"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    onChange={(e) => onChangeSuperficie()}
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-superficie-m2" className="form-label">(*) Superficie M2</label>
                                <input type="text"
                                    id="datosTecnicos-superficie-m2"
                                    name="datosTecnicos-superficie-m2"
                                    ref={refInputDimension}
                                    min={0.01}
                                    max={999}
                                    readOnly={true}
                                    //value={superficieM2}
                                    data-parsley-required="true"
                                    //data-parsley-type="integer"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark">
                            <label>{Texto.ubicacion_publicidad}</label>
                        </legend>

                        <div className="form-group text-center mt-1 mb-1">
                            <button type="button" className="next_button btn btn-violeta"
                                onClick={(e) => handleOpenMapGeneric(e, TYPE_MAP_ADV)}>
                                {Texto.ubicacion_geoespacial_publicidad}
                                <i className="fas fa-map-marker-alt ps-2"></i>
                            </button>
                        </div>

                        <div className="form-group">
                            <img
                                id={"imgAdvertising01"}
                                className="img-fluid mx-auto d-block pointer rounded border border-secondary d-none"
                                style={{ height: '70%' }}
                                onClick={(e) => handleOpenImageAdvertisingBase64(e)}
                                alt="img-domicilio-advertising"
                                src={""}
                            />
                            {
                                dataInmuebleCatastroInfo ? <DescriptionZonaComunaMapAdv dataInmuebleCatastroInfo={dataInmuebleCatastroInfo} /> : ""
                            }

                            <div id="resultado_georeferencial" style={{ fontSize: 11.5 }} className="alert alert-danger p-2 mb-2  d-none" role="alert">
                                <b>Atención: </b> Inmueble no actualizado, consigne el inmueble maś cercano.
                            </div>

                        </div>

                        <div className="">
                            <label htmlFor="datosTecnicos-direccion" className="form-label">(*) Dirección</label>
                            <input
                                type="text"
                                id="datosTecnicos-direccion"
                                name="datosTecnicos-direccion"
                                ref={refInputDireccion}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección donde se emplazará la publicidad"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                // data-parsley-maxlength-message="Debe ser un número de 4 hasta 15 dígitos."
                                autoComplete="one-time-code"
                                placeholder="Calle/Avenida/Pasaje/Parque/Plazuela/etc."
                            />
                        </div>

                        <div className="mt-2">
                            <label htmlFor="adyacentes" className="form-label">(*) Adyacentes</label>
                        </div>

                        <div className="input-group">
                            <input
                                type="text"
                                id="ubicacion-adyacente_entre"
                                name="ubicacion-adyacente_entre"
                                ref={refInputDireccionEntre}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />

                            <div className="input-group-prepend">
                                <span className="input-group-text" id="">Y</span>
                            </div>
                            <input
                                type="text"
                                id="ubicacion-adyacente_y"
                                name="ubicacion-adyacente_y"
                                ref={refInputDireccionY}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                            />
                        </div>

                        <div className="row">
                            <div className="col-6" id="error_adyacente_entre"></div>
                            <div className="col-6" id="error_adyacente_y"></div>
                        </div>
                    </fieldset>

                    <fieldset className="mt-3">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark">
                            <label> Información técnica y ubicación de la Actividad Económica. </label>
                        </legend>

                        <div className="input-text">
                            <div className="input-div">
                                <label htmlFor="datosTecnicos-nroActividad" className="form-label">(*) Licencia Funcionamiento</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        id={"input-nroactividad"}
                                        className="form-control input-uppercase"
                                        name="datosTecnicos-nroActividad"
                                        placeholder="Nro Actividad Económica"
                                        aria-label="Nro Actividad Económica"
                                        aria-describedby="basic-addon2"
                                        data-parsley-required="true"
                                        ref={refInputNroActividadEco}
                                        onChange={handleOnchangeLicFuncionamiento}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            onClick={handlekClickSearchActEco}
                                        >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-6 col-sm-12">
                                <label htmlFor="DatosTecnicos-actividad" className="form-label">Nombre o Razón Social</label>
                                <input
                                    type={"text"}
                                    name="DatosTecnicos-actividad"
                                    readOnly
                                    ref={refInputRazonSocial}
                                    className="form-control"
                                    data-parsley-required="true"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <div className="buttons button_space justify-content-between mt-2">
                        <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
                        <button className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
                    </div>
                </form>
            </div>
            {showMapAdvertising ? <MapCatastroPublicModal
                module={"antenas"}
                id={idMapAdvertising}
                nameForm={""}
                fillPaint={true}
                width="724"
                height="261"
                title="Ubicación Publicidad"
                handleEventMap={handleEventMapAdvertising}
            /> : ""}
        </>
    );
};

export default Step2ActEconomicaFormValideDatosTecnicos;
