import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import ModalVisacionMinutas from "./ModalVisacionMinutas";
import Form from "./Form";
import Links from "../../data/link";
import TitlePage from "../../components/utils/TitlePage";
import Texto from "../../data/es";
import AuthService from "../../components/Usuario/AuthService";

const Create = (props) => {
  const [initialState, setInitialState] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modalVisacion, setModalVisacion] = useState(undefined);
  const [dataInmueble, setDataInmueble] = useState(undefined);
  const auth = new AuthService();
  const history = useHistory();

  useEffect(() => {
    if (!auth.loggedIn()) history.replace(Links[4].url);
    const modalFur = new window.bootstrap.Modal(
      document.getElementById("modalVisacionFull"),
      {
        keyboard: true,
        backdrop: "static",
        focus: true,
      }
    );
    modalFur.show();
    setModalVisacion(modalFur);
  }, [initialState]);

  const closeModal = (liquidacion, message) => {
    modalVisacion.hide();
    document
      .getElementById("modalVisacionFull")
      .addEventListener("hidden.bs.modal", function (event) {
        console.log("modal se ha cerrado.....", liquidacion);
        setDataInmueble(liquidacion.dataInmueble);
        setShowForm(true);
        setTimeout(function () {
          window.jQuery('[data-toggle="tooltip"]').tooltip();
        }, 3000);
      });
  };

  const breadcrumbs = [
    {
      title: Links[0].title,
      url: Links[0].url,
    },
    {
      title: Links[88].title,
      url: Links[88].url,
    },
    {
      title: "Nuevo",
      url: "#",
    },
  ];

  return (
    <section className="items-grid section mt-5 mb-5">
      <div className="container mt-4">
        <TitlePage
          titlePage={Texto.visacion_minutas}
          breadcrumbs={breadcrumbs}
          position={"left"}
        />

        {showForm ? (
          <Form toast={toast} dataInmueble={dataInmueble} />
        ) : (
          <p>Esperando confirmación previsa solicitud de la prescripción...</p>
        )}
      </div>

      <ModalVisacionMinutas toast={toast} closeModal={closeModal} />

      <ToastContainer
        enableMultiContainer
        containerId={"Z"}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <ToastContainer />
    </section>
  );
};

export default Create;
