import Texto from "../../data/es";
import Constant from "../../data/constant";
import { Link } from "react-router-dom";
import Links from "./../../data/link";

const isObservado = (requirments) => {
  if (requirments) {
    const observados = requirments.filter((req) => req.estado === 'RECHAZADO');
    return observados.length > 0 ? true : false
  }
  return false;
}

const htmlLinkAfter = (row, handleClickViewCrape, handleClickViewLiquidacion, handleClickViewCertificacion, handleclickViewLicenciaActividad, userAuth) => {
  //debugger
  const codeEstadoAdvertising = row.estado_code;
  const clasificationCode = row.clasification_code ? row.clasification_code : null;
  const clasificationName = row.clasification ? row.clasification : null;

  //const furNumero = parseInt(row.adv_fur);
  //const digitalizado = parseInt(row.digitalizado);
  let viewButtonLiquidacion = false;
  let viewButtomCrape = false;
  let viewButtonAutorization = false;

  console.log("------------------------------- ", userAuth.code, " ---------------- ", Constant[0].grupos.funcionario);
  console.log("------------------------------- ", userAuth.code === Constant[0].grupos.funcionario);

  if (codeEstadoAdvertising === Constant[0].estado.revisado && (userAuth.code === Constant[0].grupos.funcionario ||
    userAuth.code === Constant[0].grupos.administrador)) {
    if (clasificationCode === Constant[0].publicidad_clasificador.code_empresarial) {

      //elemento
      if (!row.adv_fur && !row.adv_nro_licencia && !row.adv_nro_autorization ) {
        viewButtonLiquidacion = true;
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Generar Liquidación (${clasificationName})`}
              onClick={() => handleClickViewLiquidacion(row)}
            >
              <i className="fas fa-money-bill   me-2 " aria-hidden="true"></i>
              Generar Liquidación
            </Link>
          }
        </li>;
      }

      //emitimos la autorizacion
      if (!row.adv_fur && row.adv_fur_preliquidacion && row.adv_nro_licencia && row.adv_nro_autorization) {
        viewButtonAutorization = true;
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Certificación ${clasificationName}`}
              onClick={() => handleClickViewCertificacion(row)}
            >
              <i className="fas fa-certificate me-2 " aria-hidden="true"></i> Generar Autorización
            </Link>
          }
        </li>
      }

      //CRAPE
      if (row.adv_fur && !row.adv_nro_autorization && parseInt(row.adv_digitalizado) === 0) {
        viewButtomCrape = true;
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Generar CRAPE (${clasificationName})`}
              onClick={(event) => handleClickViewCrape(row, event)}
            >
              <i className="fas fa-file-pdf me-2" aria-hidden="true"></i>
              Generar CRAPE
            </Link>
          }
        </li>
      }
    }

    if (clasificationCode === Constant[0].publicidad_clasificador.code_actividad) {
      viewButtonAutorization = true;
      return <li>
        {
          <Link
            to="#"
            className="dropdown-item"
            title={`Certificación ${clasificationName}`}
            onClick={() => handleclickViewLicenciaActividad(row)}
          >
            <i className="fas fa-certificate me-2 " aria-hidden="true"></i>
            Generar Licencia
          </Link>
        }
      </li>;
    }

    if (clasificationCode === Constant[0].publicidad_clasificador.code_eventual) {
      if (!row.adv_fur_preliquidacion && !row.adv_nro_licencia && !row.adv_nro_autorization) {
        viewButtonLiquidacion = true;
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Generar Liquidación (${clasificationName})`}
              onClick={() => handleClickViewLiquidacion(row)}
            >
              <i className="fas fa-money-bill   me-2 " aria-hidden="true"></i>
              Generar Liquidación
            </Link>
          }
        </li>;
      }

      if (row.adv_fur_preliquidacion && row.adv_nro_licencia && row.adv_nro_autorization) {
        viewButtonAutorization = true;
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Certificación ${clasificationName}`}
              onClick={() => handleClickViewCertificacion(row)}
            >
              <i className="fas fa-certificate me-2 " aria-hidden="true"></i> Generar Autorización
            </Link>
          }
        </li>
      }
    }
  }


  // estado revisado
  //console.log("++++++++++++++++++++++++ ", Constant[0].estado);
  /*
  if (codeEstadoAdvertising === Constant[0].estado.revisado) {
    // estructura - empresarial
    debugger
    if (clasificationCode === Constant[0].publicidad_clasificador.code_empresarial && furNumero && !digitalizado) {
      return <li>
        {
          <Link
            to="#"
            className="dropdown-item"
            title={`Generar CRAPE (${clasificationName})`}
            onClick={(event) => handleClickViewCrape(row, event)}
          >
            <i className="fas fa-file-pdf me-2" aria-hidden="true"></i>
            Generar CRAPE
          </Link>
        }
      </li>
    } else
      // licencia - actividad economica
      if (clasificationCode === Constant[0].publicidad_clasificador.code_actividad && !digitalizado) {
        return <li>
          {
            <Link
              to="#"
              className="dropdown-item"
              title={`Certificación ${clasificationName}`}
            //onClick={() => handlePreviewPdfLicenciaActividad(row)}
            >
              <i className="fas fa-certificate me-2 " aria-hidden="true"></i>
              Generar Licencia
            </Link>
          }
        </li>;
      } else {
        if (!row.adv_fur_preliquidacion)
          return <li>
            {
              <Link
                to="#"
                className="dropdown-item"
                title={`Generar Liquidación (${clasificationName})`}
                onClick={() => handleClickViewLiquidacion(row)}
              >
                <i className="fas fa-money-bill   me-2 " aria-hidden="true"></i>
                Generar Liquidación
              </Link>
            }
          </li>;
      }

    //// estado certificado
  } else
    if ((furNumero && clasificationCode === Constant[0].publicidad_clasificador.code_empresarial) ||
      (furNumero && clasificationCode === Constant[0].publicidad_clasificador.code_eventual) && row.adv_fur_preliquidacion) {
      return <li>
        {
          <Link
            to="#"
            className="dropdown-item"
            title={`Certificación ${clasificationName}`}
          //onClick={() => handlePreviewPdfCertificacionClick(row)}
          >
            <i className="fas fa-certificate me-2 " aria-hidden="true"></i> Generar Autorización
          </Link>
        }
      </li>
    }
*/
}

export default class Columns {
  constructor(userAuth) {
    this.userAuth = userAuth;
  }

  columns(
    handleAprobarDatosClick,
    handleReaperturarClick,
    handlePreviewClick,
    handleDeleteClick,
    handleClickViewCrape,
    handleClickViewLiquidacion,
    handleClickViewCertificacion,
    handleclickViewLicenciaActividad,
    handleClickUpdateData,
    formatDateDMYHIS,
  ) {
    return [
      {
        name: "Número FUR",
        cell: (row) => <span>{row.adv_fur} </span>,
        sortable: false,
      },
      {
        name: "Número de Orden",
        sortable: false,
        cell: (row) => <span>{row.adv_numero}</span>,
        hide: "md",
      },
      {
        name: "Tipo",
        sortable: false,
        cell: (row) => <span>{row.clasification ? row.clasification.toUpperCase() : ""}</span>,
        wrap: true
      },
      {
        name: "Categoria",
        sortable: false,
        cell: (row) => <span>{row.category_publicity ? row.category_publicity.toUpperCase() : ""}</span>,
        wrap: true,
        ignoreRowClick: true,
        allowOverflow: true,
        hide: "md",
        style: {
          textAlign: 'justify', // Justifica el texto
        }
      },
      {
        name: 'Contribuyente',
        cell: row => <span> {
          row.titular && row.titular.length > 0 ?
            row.titular[0].titular_juridico_nit_razon_social ? row.titular[0].titular_juridico_nit_razon_social : row.titular[0].rep_legal_nombre
            : ""
        }</span>,
        sortable: false,
        wrap: true,
        style: {
          textAlign: 'justify', // Justifica el texto
        },
        ignoreRowClick: true,
        allowOverflow: true,
        hide: "md",
      },
      {
        name: 'Estado',
        cell: row => (<span>{row.estado_name.toUpperCase()}</span>),
        sortable: false,
        hide: "md",
      },
      {
        name: 'Fecha Registro',
        cell: row => (<span>{formatDateDMYHIS(row.adv_date_created)}</span>),
        sortable: false,
        hide: "md"
      },
      {
        name: "",
        sortable: false,
        cell: (row) => (
          <div className="text-sm-start text-md-end" style={{ width: "100px" }}>
            <span
              data-bs-toggle="dropdown"
              aria-expanded="false"
              className="pe-2 ps-2"
            >
              {
                row.requirements ? (row.requirements.filter(({ estado, token, url }) => estado === "RECHAZADO").length > 0 ? <i className="fas fa-info-circle text-danger h5 pe-2 mt-1"></i> : "") : ""
              }
              <i className="fas fa-ellipsis-v text-primary "></i>

            </span>
            <ul className="dropdown-menu">
              <li>
                {(row.permissions_user && row.permissions_user.includes(Constant[0].permission.aprobar)
                  && row.estado_code === Constant[0].estado.en_proceso) ?
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={() => handleAprobarDatosClick(row)}
                    title={`Aprobar los datos de la publicidad: ${row.adv_numero}`}
                  >
                    <i className="fa fa-unlock-alt me-2" aria-hidden="true" ></i>{" "} Confirmar Datos
                  </Link>
                  : ""}
              </li>

              <li>
                {row.estado_code && (row.estado_code === Constant[0].estado.en_proceso || row.estado_code === Constant[0].estado.aprobado
                  || row.estado_code === Constant[0].estado.revisado || row.estado_code === Constant[0].estado.certificado) && (
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={(event) => handlePreviewClick(row, event)}
                      title={`Autorización número: ${row.adv_numero}`}
                    >
                      <i className="far fa-file-pdf me-2" aria-hidden="true"></i>{" "}Ver DDJJ
                    </Link>
                  )}
              </li>
              <li>
                {row.permissions_user && row.permissions_user.includes(Constant[0].permission.update) && row.estado_code === Constant[0].estado.en_proceso ? (
                  <Link
                    to={`#`}
                    className="dropdown-item"
                    title={`Editar : ${row.nombre}`}
                    onClick={(event) => handleClickUpdateData(row, event)}
                  >
                    <i className="fas fa-pencil-alt me-2"></i> Modificar Datos
                  </Link>
                ) : ""}
              </li>

              <li>
                {row && (row.estado_code === Constant[0].estado.aprobado || row.estado_code === Constant[0].estado.en_proceso) && isObservado(row.requirements) && (this.userAuth.code === Constant[0].grupos.contribuyente) ?
                  <Link
                    to={{
                      pathname:
                        Links[138].url + "/" +
                        row.adv_token +
                        "/" +
                        row.adv_numero,
                    }}
                    className="dropdown-item"
                    title={`Requisitos Observados`}
                    id={`link-update-requirement`}
                  >
                    <i className="fas fa-list-alt me-2 "></i>Observados
                  </Link>
                  : ""}
              </li>

              <li>
                {row.estado_code === Constant[0].estado.aprobado && !isObservado(row.requirements) && (this.userAuth.code === Constant[0].grupos.funcionario ||
                  this.userAuth.code === Constant[0].grupos.administrador) ?
                  <Link
                    to={{
                      pathname:
                        Links[137].url +
                        "/" +
                        row.adv_token +
                        "/" +
                        row.adv_numero,
                    }}
                    className="dropdown-item"
                    title={`Revisar Requisitos`}
                  >
                    <i className="fas fa-list-alt me-2"></i> Revisar
                  </Link>
                  : ""}
              </li>

              <li>
                {row.permissions_user && (row.estado_code === Constant[0].estado.aprobado || row.estado_code === Constant[0].estado.revisado)
                  && row.permissions_user.includes(Constant[0].permission.reaperturar) && (this.userAuth.code === Constant[0].grupos.funcionario ||
                    this.userAuth.code === Constant[0].grupos.administrador) ?
                  <Link
                    to="#"
                    className="dropdown-item"
                    onClick={(event) => handleReaperturarClick(row, event)}
                    title={`Desbloquear publicidad`}
                  >
                    <i className="fa fa-lock me-2" aria-hidden="true"></i>{" "} Desbloquear
                  </Link>
                  : ""}
              </li>

              <li>
                {row.permissions_user ? (
                  row.permissions_user.includes(Constant[0].permission.delete) &&
                    row.estado_code !== Constant[0].estado.aprobado && row.estado_code !== Constant[0].estado.revisado &&
                    row.estado_code !== Constant[0].estado.eliminado && row.estado_code !== Constant[0].estado.certificado ? ( //&&
                    <Link
                      to="#"
                      className="dropdown-item"
                      onClick={() => handleDeleteClick(row)}
                      title={`Eliminar Autorización ${row.adv_numero}`}
                    >
                      <i className="far fa-trash-alt me-2" aria-hidden="true" ></i>{" "}
                      Eliminar
                    </Link>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </li>

              {htmlLinkAfter(row, handleClickViewCrape, handleClickViewLiquidacion, handleClickViewCertificacion, handleclickViewLicenciaActividad, this.userAuth)}

            </ul>
          </div >
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      }
    ]
  }
}
