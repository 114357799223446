import React from "react";
import { Route, Switch } from "react-router-dom";

import App from "./components/App";
import DeclacionJurada from "./components/DeclaracionJurada";
import DeclaracionJuradaCreate from "./components/DeclaracionJurada/DeclaracionJuradaCreate";
import DeclaracionJuradaEdit from "./components/DeclaracionJurada/DeclaracionJuradaEdit";
import DeclaracionJuradaEditTipoActividad from "./components/DeclaracionJurada/DeclaracionJuradaEditTipoActividad";
import CertificacionCreate from "./components/Certificacion/CertificacionCreate";
import CertificacionVehCreate from "./components/CertificacionVeh/CertificacionCreate";
import Home from "./components/Home";
import Page404 from "./components/Page404";
import Perfil from "./components/Usuario/Perfil";
import ForgotPassword from "./components/Usuario/ForgotPassword";
import TerminosCondiciones from "./components/Page/TerminosCondiciones";
import Ayuda from "./components/Page/Ayuda";
import Guia from "./components/Page/Guia";
import GuiaPatentes from "./components/Page/GuiaPatentes";
import PagosRuat from "./components/Page/PagosRuat";
import ConsultaTuDeuda from "./components/Page/ConsultaTuDeuda";
import Certificacion from "./components/Certificacion";
import CertificacionHome from "./components/Certificacion/Home";
import CertificacionAdm from "./components/Certificacion/CertificacionAdm";
import CertificacionVehAdm from "./components/CertificacionVeh/CertificacionVehAdm";
import Perdonazo2020 from "./components/Page/Perdonazo2020";
import Usuario from "./components/Usuario";
import ReActivacionCuenta from "./components/Usuario/SendMailActivationAccount";
import Gerencia from "./components/Gerencia";
import Cram from "./components/Cram";
import CramCreate from "./components/Cram/Create";
import Ruat from "./components/Ruat";
import Notificacion from "./components/Notificacion";
import Liquidacion from "./components/Notificacion/Liquidacion";
import LiquidacionLista from "./components/Notificacion/LiquidacionLista";
import LegalTributarioHome from "./components/LegalTributario";
//import IndexLegalTributario from "./components/Prescripcion/LegalTributario/IndexLegalTributario";
//import FormConsolidacionLegalTributario from "./components/Prescripcion/LegalTributario/FormConsolidacion";
//import DetalleLegalTributario from "./components/Prescripcion/LegalTributario/Detalle";
//import CreateRta from "./components/Prescripcion/LegalTributario/CreateRta";

import MovilidadUrbanaHome from "./components/MovilidadUrbana";
import Ras from "./components/MovilidadUrbana/Ras";
import RasVehiculos from "./components/MovilidadUrbana/Ras/Vehiculos";
import RasCoactivosVehiculos from "./components/MovilidadUrbana/Ras/VehiculosCoactivos";

import CertificacionTransportePublico from "./components/Certificacion/CertificacionTransportePublico";
import UpdateCertificacionTransportePublico from "./components/Certificacion/servicioPublico/UpdateCertification";
import DetalleCertificacion from "./components/Certificacion/servicioPublico/Detalle";
import MisionVision from "./components/Page/MisionVision";

import RequisitosInmuebles from "./components/Requisitos/Inmuebles";
import RequisitosVehiculos from "./components/Requisitos/Vehiculos";
import ActividadesEconomicas from "./components/Requisitos/ActividadesEconomicas";
import Publicidad from "./components/Requisitos/Publicidad";

import CreateCambioRadicatoria from "./components/CambioRadicatoria/Create";
import CambioRadicatoria from "./components/CambioRadicatoria";

import InfraccionesGamc from "./components/InfraccionesGamc";

import Piet from "./components/LegalTributario/Piet";
import PietActividadesEconomicas from "./components/LegalTributario/Piet/ActividadesEconomicas";
import PietVehiculos from "./components/LegalTributario/Piet/Vehiculos";
import PietInmuebles from "./components/LegalTributario/Piet/Inmuebles";

import VisacionMinuta from "./components/VisacionMinutas";
import VisacionMinutaCreate from "./components/VisacionMinutas/Create";
import VisacionMinutaEdit from "./components/VisacionMinutas/Edit";
import VisacionMinutaImto from "./components/VisacionMinutas/ImtoFormTransferencia";
import FurFree from "./components/FurFree/FurAdm";
import GoogleAnalytics from "./Analytics";

import Saat from "./components/Saat";
import SaatCreate from "./components/Saat/Create";
import SaatEdit from "./components/Saat/Edit";

import Inmuebles from "./components/Global/Inmuebles";
import Vehiculos from "./components/Global/Vehiculos";
import PatentesMunicipales from "./components/Global/PatentesMunicipales";
import GlobalActividadesEconomicas from "./components/Global/ActividadesEconomicas";

import GacetaTributaria from "./components/GacetaTributaria";
import GacetaTributariaAdmin from "./components/GacetaTributaria/Admin";

//import ChichaMaizCreate from "./components/ConsumosEspecificos/ChichaMaiz/Create";
// masiva
import LiquidacionTributariaInicioContribuyente from "./components/LiquidacionTributaria/ConsultaInicio";
import LiquidacioTributariaConsulta from "./components/LiquidacionTributaria/Contribuyente";
import LiquidacionTributariaLista from "./components/LiquidacionTributaria/TributarioLista";
import LiquidacionTributariaInicio from "./components/LiquidacionTributaria/TributarioInicio";
import LiquidacionTributariaHomeInmueble from "./components/LiquidacionTributaria/HomeMenuInmueble";
import LiquidacionTributariaHomeVehiculo from "./components/LiquidacionTributaria/HomeMenuVehiculo";
import HomeMenuActividadesEconomicas from "./components/LiquidacionTributaria/HomeMenuActividadesEconomicas";
import ResetPasswordSimat from "./components/Usuario/Simat/ResetPassword";
// 2024 - register user simat
import LoginSimat from "./components/Usuario/Simat/Login";
import RegisterUserSimat from "./components/Usuario/Simat/RegisterUser";
// interno
// 2024 - publicidad - inicio
import PublicidadHome from "./components/Publicidad/Home";
import PublicidadDeclaracionJurada from "./components/Publicidad/HomeDeclaracionJurada";
import PublicidadListDDJJ from "./components/Publicidad/usuario/ListPublicidadDDJJ";

import PublicidadCreate from "./components/Publicidad/Create";
import ReviewPublicidad from "./components/Publicidad/Review";
import ReviewRefusedPublicidad from "./components/Publicidad/ReviewRefused";
import DetailsReport from "./components/Publicidad/DetailsReport";

// 2024 - publicidad - fin

import ConfirmEmail from "./components/Usuario/Simat/ConfirmEmail";
import ConfirmEmailReset from "./components/Usuario/Simat/ConfirmEmailReset";
// ras
import AdminRas from "./components/Ras/AdminRas";
import ContribuyenteInicioVehiculo from "./components/Ras/ContribuyenteInicioVehiculo";
import EditPublicidadDDJJ from "./components/Publicidad/Edit";
import HomeFuncionamiento from "./components/DeclaracionJurada/HomeFuncionamiento";
import RequisitoFuncionamiento from "./components/DeclaracionJurada/Guia/RequisitoFuncionamiento";

const AppRoutes = () => (
	<App>
		<Switch>
			<Route exact path="/inmuebles" component={Inmuebles} />
			<Route exact path="/vehiculos" component={Vehiculos} />
			<Route exact path="/patentes-municipales" component={PatentesMunicipales} />

			{/* ====================== añadido 2024 ====================== */}
			{/* usuario init simat*/}
			<Route exact path="/login" component={LoginSimat} />
			<Route exact path="/login/reset" component={ResetPasswordSimat} />
			<Route exact path="/register" component={RegisterUserSimat} />
			{/* registro de usuario simat */}
			<Route exact path="/register/confirm-email" component={ConfirmEmail} />
			<Route exact path="/reset/confirm-email" component={ConfirmEmailReset} />

			{/* actividades economicas */}
			<Route exact path="/actividades-economicas" component={GlobalActividadesEconomicas} />
			{/* actividades economicas - publicidad */}
			<Route exact path="/actividades-economicas/publicidad" component={PublicidadHome} />
			<Route exact path="/actividades-economicas/publicidad/ddjj" component={PublicidadDeclaracionJurada} />
			<Route exact path="/actividades-economicas/publicidad/lista" component={PublicidadListDDJJ} />

			{/* actividades economicas - lic. funcionamiento */}
			<Route exact path="/actividades-economicas/funcionamiento" component={HomeFuncionamiento} />
			<Route exact path="/actividades-economicas/funcionamiento/requisitos" component={RequisitoFuncionamiento} />

			{/* ================================================================= */}

			<Route
				exact
				path="/patentes-municipales/licencia-actividad-economica"
				component={DeclacionJurada}
			/>
			<Route
				exact
				path="/patentes-municipales/licencia-actividad-economica-create"
				component={DeclaracionJuradaCreate}
			/>
			<Route
				exact
				path="/patentes-municipales/licencia-actividad-economica-edit"
				component={DeclaracionJuradaEdit}
			/>
			<Route
				exact
				path="/patentes-municipales/licencia-actividad-economica-edit-tipo-actividad"
				component={DeclaracionJuradaEditTipoActividad}
			/>
			<Route
				exact
				path="/patentes-municipales/licencia-actividad-economica-ruat"
				component={Ruat}
			/>

			{/* <Route exact path="/visacion" component={Visacion} /> */}
			<Route
				exact
				path="/inmuebles/visacion-minutas"
				component={VisacionMinuta}
			/>
			<Route
				exact
				path="/inmuebles/visacion-minutas/create"
				component={VisacionMinutaCreate}
			/>
			<Route
				exact
				path="/inmuebles/visacion-minutas/edit/:form/:token/:numero"
				component={VisacionMinutaEdit}
			/>
			<Route
				exact
				path="/inmuebles/visacion-minutas/imto/:token/:numero"
				component={VisacionMinutaImto}
			/>
			{/*
            <Route exact path="/fiscalizacion" component={Fiscalizacion} />
            <Route exact path="/prescripcion-fiscalizacion" component={IndexFiscalizacion} />
            <Route exact path="/prescripcion-fiscalizacion/consolidacion/:token" component={FormConsolidacionFisca} />
            <Route exact path="/prescripcion-fiscalizacion/detalle/:token" component={PrescripcionDetalle} />
        */}
			<Route
				exact
				path="/inicio-legal-tributario"
				component={LegalTributarioHome}
			/>
			<Route exact path="/legal-tributario/piets" component={Piet} />
			<Route
				exact
				path="/legal-tributario/piets/actividades-economicas"
				component={PietActividadesEconomicas}
			/>
			<Route
				exact
				path="/legal-tributario/piets/inmuebles"
				component={PietInmuebles}
			/>
			<Route
				exact
				path="/legal-tributario/piets/vehiculos"
				component={PietVehiculos}
			/>
			<Route
				exact
				path="/inicio-movilidad-urbana"
				component={MovilidadUrbanaHome}
			/>
			<Route exact path="/inicio-movilidad-urbana/ras" component={Ras} />
			<Route
				exact
				path="/inicio-movilidad-urbana/ras/vehiculos"
				component={RasVehiculos}
			/>
			<Route
				exact
				path="/inicio-movilidad-urbana/ras-coactivos/vehiculos"
				component={RasCoactivosVehiculos}
			/>
			{/*<Route
        exact
        path="/prescripcion-legal-tributario"
        component={IndexLegalTributario}
      /> 
      <Route
        exact
        path="/prescripcion-legal-tributario/consolidacion/:token"
        component={FormConsolidacionLegalTributario}
      />
      <Route
        exact
        path="/prescripcion-legal-tributario/detalle/:token"
        component={DetalleLegalTributario}
      />
      <Route
        exact
        path="/prescripcion-legal-tributario/registrar-rta/:token"
        component={CreateRta}
      />*/}
			{/*<Route exact path="/login" component={Login} />		*/}
			{/*<Route exact path="/usuario-create" component={RegisterUser} />*/}
			{/*<Route exact path="/usuario-confirmar-cuenta" component={ConfirmUser} />*/}
			<Route exact path="/usuario-perfil" component={Perfil} />
			<Route exact path="/usuario" component={Usuario} />
			{/*<Route exact path="/usuario-reactivacion-cuenta" component={ReActivacionCuenta} />*/}

			<Route exact path="/soporte-gerencial" component={Gerencia} />
			<Route exact path="/cram" component={Cram} />
			<Route exact path="/cram-create" component={CramCreate} />
			<Route exact path="/" component={Home} />
			<Route exact path="/recuperar-contrasenia" component={ForgotPassword} />
			{/*<Route exact path="/change-contrasenia" component={ChangePassword} />*/}
			<Route exact path="/terminos-condiciones" component={TerminosCondiciones} />
			<Route exact path="/guia" component={Guia} />
			<Route exact path="/guia-patentes-municipales" component={GuiaPatentes} />
			<Route exact path="/consulta-tu-deuda" component={ConsultaTuDeuda} />{" "}

			<Route exact path="/declaraciones-juradas" component={Perdonazo2020} />{" "}

			<Route exact path="/notificacion" component={Notificacion} />
			<Route exact path="/liquidaciones" component={Liquidacion} />
			<Route exact path="/liquidaciones/mixtas" component={LiquidacionLista} />
			<Route exact path="/inmuebles/certificaciones" component={Certificacion} />
			<Route exact path="/certificaciones" component={CertificacionHome} />

			{/*va a lista de certificaciones*/}
			<Route
				exact
				path="/inmuebles/certificaciones/emitir/:rubro"
				component={CertificacionCreate}
			/>
			<Route
				exact
				path="/vehiculos/certificacion/emitir/:rubro"
				component={CertificacionVehCreate}
			/>{" "}
			{"ha cambiado"}
			<Route exact path="/certificacion-admin" component={CertificacionAdm} />
			<Route
				exact
				path="/certificacion-vehiculos-admin"
				component={CertificacionVehAdm}
			/>
			<Route
				exact
				path="/certificacion-transporte-publico"
				component={CertificacionTransportePublico}
			/>
			<Route
				exact
				path="/certificacion-transporte-publico/:token/:numero"
				component={UpdateCertificacionTransportePublico}
			/>
			<Route
				exact
				path="/detalle-certificacion-transporte-publico/:token"
				component={DetalleCertificacion}
			/>
			<Route
				exact
				path="/guia/requisitos-inmuebles"
				component={RequisitosInmuebles}
			/>
			<Route
				exact
				path="/guia/requisitos-vehiculos"
				component={RequisitosVehiculos}
			/>
			<Route
				exact
				path="/guia/requisitos-actividades-economicas"
				component={ActividadesEconomicas}
			/>
			<Route exact path="/guia/requisitos-publicidad" component={Publicidad} />
			<Route
				exact
				path="/vehiculos-cambio-radicatoria/registrar"
				component={CreateCambioRadicatoria}
			/>
			<Route
				exact
				path="/vehiculos-cambio-radicatoria-admin"
				component={CambioRadicatoria}
			/>
			<Route exact path="/metodos-pago-ruat" component={PagosRuat} />{" "}
			{"eliminar"}
			<Route
				exact
				path="/infracciones-sanciones"
				component={InfraccionesGamc}
			/>

			<Route exact path="/gaceta-tributaria" component={GacetaTributaria} />
			<Route
				exact
				path="/gaceta-tributaria-admin"
				component={GacetaTributariaAdmin}
			/>
			<Route exact path="/fur-gratuito/:rubro" component={FurFree} />
			<Route exact path="/autorizacion-telecomunicaciones" component={Saat} />
			<Route
				exact
				path="/autorizacion-telecomunicaciones/create"
				component={SaatCreate}
			/>
			<Route
				exact
				path="/autorizacion-telecomunicaciones/edit/:form/:token/:numero"
				component={SaatEdit}
			/>
			{/* <Route exact path="/mixtas" component={LiquidacionTributariaInicioContribuyente} />        
      <Route exact path="/mixtas/consulta" component={LiquidacioTributariaConsulta} />        
      <Route exact path="/mixtas/inicio" component={LiquidacionTributariaInicio} />
      <Route exact path="/mixtas/lista/:objeto/:tipo" component={LiquidacionTributariaLista} /> */}
			<Route
				exact
				path="/mixtas"
				component={LiquidacionTributariaInicioContribuyente}
			/>
			<Route
				exact
				path="/mixtas/consulta/:objeto"
				component={LiquidacioTributariaConsulta}
			/>
			<Route
				exact
				path="/mixtas/inicio/inmuebles"
				component={LiquidacionTributariaHomeInmueble}
			/>
			<Route
				exact
				path="/mixtas/inicio/vehiculos"
				component={LiquidacionTributariaHomeVehiculo}
			/>
			<Route
				exact
				path="/mixtas/inicio/patentes"
				component={HomeMenuActividadesEconomicas}
			/>
			<Route
				exact
				path="/mixtas/gamc"
				component={LiquidacionTributariaInicio}
			/>
			<Route
				exact
				path="/mixtas/lista/:objeto/:tipo"
				component={LiquidacionTributariaLista}
			/>
			<Route
				exact
				path="/autorizacion-telecomunicaciones/create"
				component={SaatCreate}
			/>

			<Route
				exact
				path="/actividades-economicas/publicidad/create"
				component={PublicidadCreate}
			/>

			<Route
				exact
				path="/actividades-economicas/publicidad/revision/:token/:numero"
				component={ReviewPublicidad}
			/>
			<Route
				exact
				path="/actividades-economicas/publicidad/observados/:token/:numero"
				component={ReviewRefusedPublicidad}
			/>

			<Route
				exact
				path="/actividades-economicas/publicidad/details-reports/:token/:numero"
				component={DetailsReport}
			/>

			<Route
				exact
				path="/actividades-economicas/publicidad/editar/:token/:template"
				component={EditPublicidadDDJJ}
			/>


			{/* RAS - 25JUL2023 */}
			<Route
				exact
				path="/ras/admin"
				component={AdminRas}
			/>

			<Route
				exact
				path="/ras/vehiculo"
				component={ContribuyenteInicioVehiculo}
			/>

			<Route exact path="/analytics.txt" component={GoogleAnalytics} />
			<Route exact path="/ayuda" component={Ayuda} />
			<Route exact path="/quienes-somos" component={MisionVision} />
			<Route exact path="/page-404" component={Page404} />
			<Route component={Page404} />
		</Switch>
	</App>
);

export default AppRoutes;