class Consulta {

  /*
  advertising { id token fur numero dateStart dateExpires numeroLicencia numeroAutorization digitalizado createdAt
                  AdTitulars
                   {
                    id
                    natural
                    Persona{
                        nombre
                        apellidoPaterno
                        apellidoMaterno
                        apellidoCasada
                        numeroDocumento
                        expedidoEn
                      }
                      AdTitularJuridico{
                        id
                        razonSocial
                        numeroDocumento
                      }
                  }
                  AdActividadEconomicaRuat {
                    nroActividad
                    tipoActividad
                  }
                  AdDataTechnical {
                    id
                    alto
                    ancho
                    dimension
                    AdUbicacions{
                        direccion
                        direccionEntre
                        direccionY
                      }
                  }
                  Estado{
                    id
                    name 
                    code
                  }
                  clasificationCategoriesAdv {
                    clasificationCategoryPublicity {
                      clasification {
                        name
                        code
                      }
                      categoryPublicity {
                        name
                      }
                    }
                  }
                  AdAdvertisingRequirementSite{          
                    id
                    Estado{
                      id
                      name
                      code
                    }
                    AdRequirementSite{
                      id
                      name
                      code
                    }
                  }
                }
   */
  queryAdvertisingsAll(page, per_page, codeListEstado) {
    return `{advertisings(page: ${page}, per_page: ${per_page}, codeListEstado: [${codeListEstado}]) {
              result
              message
              pagination{
                total
                total_pages
                page
                per_page
              }
              data {
                dataSearch {
                  adv_token
                  adv_numero
                  adv_fur
                  adv_token
                  adv_numero
                  adv_fur
                  adv_date_approved
                  adv_date_created
                  adv_date_vigencia
                  adv_nro_autorization
                  adv_nro_licencia
                  adv_fur_preliquidacion
                  clasification
                  clasification_code
                  category_publicity
                  caracterization
                  categorization
                  comunication_institutinal
                  act_eco_numero
                  act_eco_licencia
                  act_eco_tipo_licencia
                  act_eco_tipo_actividad
                  data_technical_ancho
                  data_technical_alto
                  data_technical_dimension
                  data_technical_imagen
                  data_technical_direccion
                  data_technical_direccion_descriptiva
                  data_technical_zona
                  titular{
                    rep_legal_tipo_documento
                    rep_legal_pmc
                    rep_legal_numero_documento
                    rep_legal_nombre
                    titular_juridico_nit_razon_social
                    titular_juridico_nit_numero_documento
                    titular_juridico_nit_pmc
                  }
                  username
                  estado_name
                  estado_code
                  requirements{
                    token
                    url
                    required_imagen_institucional
                    estado
                  }
                  permissions_user,
                  adv_digitalizado
                }
              }
            }
          }`;
  }

  queryAdvertisingSearch(page, per_page, tipo, texto, codeListEstado) {
    return `query{advertisingsSearch(page:${page}, per_page:${per_page}, tipo:"${tipo}", texto:"${texto}", codeListEstado: [${codeListEstado}]){
              result
              message
              pagination{
                total
                total_pages
                page
                per_page
              }
              data {
                              
                dataSearch {
                  adv_token
                  adv_numero
                  adv_fur
                  adv_token
                  adv_numero
                  adv_fur
                  adv_date_approved
                  adv_date_created
                  adv_date_vigencia
                  adv_nro_autorization
                  adv_nro_licencia
                  adv_fur_preliquidacion
                  clasification
                  clasification_code
                  category_publicity
                  caracterization
                  categorization
                  comunication_institutinal
                  act_eco_numero
                  act_eco_licencia
                  act_eco_tipo_licencia
                  act_eco_tipo_actividad
                  data_technical_ancho
                  data_technical_alto
                  data_technical_dimension
                  data_technical_imagen
                  data_technical_direccion
                  data_technical_direccion_descriptiva
                  data_technical_zona
                  titular{
                    rep_legal_tipo_documento
                    rep_legal_pmc
                    rep_legal_numero_documento
                    rep_legal_nombre
                    titular_juridico_nit_razon_social
                    titular_juridico_nit_numero_documento
                    titular_juridico_nit_pmc
                  }
                  username
                  estado_name
                  estado_code
                  requirements{
                    token
                    url
                    required_imagen_institucional
                    estado
                  }
                  permissions_user
                  adv_digitalizado
                }
              }
            }
          }`;
  }

  // info requirements  advertising
  queryRequirementsSiteAdvertising(tokenAdvertising, codeRequirement) {
    // REQUIREMENT
    return `query {requirementsSiteAdvertising(advertising: {token: "${tokenAdvertising}"}, requirementSite:{id:0, code:"${codeRequirement}"}) {
            result
            message
            data {
              advertisingRequirementSite {
                id
                token
                url
                AdRequirementSite {
                  id
                  name
                  typeName
                }
                Estado{
                  id
                  name
                  code
                }
              }
            }
          }
        }`
  }

  // info advertising
  queryInfoAdvertisingByToken(tokenAdvertising) {
    return `query{ advertising(advertising: {token: "${tokenAdvertising}"}) {
              result
              message
              data {
                advertising { 
                  id 
                  token 
                  fur 
                  numero 
                  dateStart 
                  dateExpires 
                  numeroLicencia 
                  numeroAutorization 
                  digitalizado 
                  createdAt
                  AdActividadEconomicaRuat { nroActividad tipoActividad}
                  AdDataTechnical {
                    id
                    alto
                    ancho
                    dimension
                    AdUbicacions {
                      idAreaHomogenea
                      latitud
                      longitud
                      coordinate
                      comuna
                      direccion
                      direccionEntre
                      direccionY
                      urlImagen
                    }
                  }
                  AdTitulars {
                    id
                    natural
                    Persona {
                      nombre
                      apellidoPaterno
                      apellidoMaterno
                      apellidoCasada
                      numeroDocumento
                      expedidoEn
                    }
                    AdTitularJuridico {
                      id
                      razonSocial
                      numeroDocumento
                    }
                  }
                  Estado {
                    id
                    name
                    code
                  }
                  categoryPublicityCategorizationAdv{
                    AdCategorization{
                      id
                      name
                    }
                  }
                  categoryPublicityComunicationAdv{
                    AdComunicationInstitutional{
                      id
                      name
                    }
                  }
                  clasificationCategoriesAdv {
                    id
                    clasificationCategoryCaracterizacion{
                        caracterization{
                          id
                          name
                        }
                      }
                    clasificationCategoryPublicity {
                      id
                      clasification {
                        id
                        name
                        code
                      }
                      categoryPublicity {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }`;
  }

  // info advertising - edit
  queryInfoAdvertisingByTokenForEdit(tokenAdvertising) {
    return `query{ advertising(advertising: {token: "${tokenAdvertising}"}) {
              result
              message
              data {
                advertising { 
                  id 
                  token 
                  fur 
                  numero 
                  dateStart 
                  dateExpires 
                  numeroLicencia 
                  numeroAutorization 
                  digitalizado 
                  createdAt
                  AdActividadEconomicaRuat { nroActividad tipoActividad}
                  AdDataTechnical {
                    id
                    alto
                    ancho
                    dimension
                    AdUbicacions {
                      idAreaHomogenea
                      latitud
                      longitud
                      coordinate
                      comuna
                      zonaTributaria
                      codigoCatastral
                      nroInmueble
                      direccion
                      direccionEntre
                      direccionY
                      urlImagen
                    }
                  }
                  AdTitulars {
                    id
                    natural
                    Persona {
                      nombre
                      apellidoPaterno
                      apellidoMaterno
                      apellidoCasada
                      numeroDocumento
                      expedidoEn
                    }
                    AdTitularJuridico {
                      id
                      razonSocial
                      numeroDocumento
                    }
                  }
                  Estado {
                    id
                    name
                    code
                  }
                  categoryPublicityCategorizationAdv{
                    AdCategorization{
                      id
                      name
                    }
                  }
                  categoryPublicityComunicationAdv{
                    AdComunicationInstitutional{
                      id
                      name
                    }
                  }
                  AdAdvertisingRequirementSite{          
                    id
                    Estado{
                      id
                      name
                      code
                    }
                    AdRequirementSite{
                      id
                      name
                      code
                    }
                  }
                  clasificationCategoriesAdv {
                    id
                    clasificationCategoryCaracterizacion{
                        caracterization{
                          id
                          name
                        }
                      }
                    clasificationCategoryPublicity {
                      id
                      clasification {
                        id
                        name
                        code
                      }
                      categoryPublicity {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }`;
  }

  // info advertisng by numero
  queryInfoAdvertisingByNumero(numeroAdvertising) {
    return `query{ advertisingByNumero(advertising: {numero: "${numeroAdvertising}"}) {
              result
              message
              data {
                advertising { id token fur numero dateStart dateExpires numeroLicencia numeroAutorization digitalizado createdAt
                  AdActividadEconomicaRuat { nroActividad tipoActividad }
                  AdDataTechnical {
                    id
                    alto
                    ancho
                    dimension
                    AdUbicacions {
                      idAreaHomogenea
                      latitud
                      longitud
                      coordinate
                      comuna
                      direccion
                      direccionEntre
                      direccionY
                      urlImagen
                    }
                  }
                  AdTitulars {
                    id
                    natural
                    Persona {
                      nombre
                      apellidoPaterno
                      apellidoMaterno
                      apellidoCasada
                      numeroDocumento
                      expedidoEn
                    }
                    AdTitularJuridico {
                      id
                      razonSocial
                      numeroDocumento
                    }
                  }
                  Estado {
                    id
                    name
                    code
                  }
                  categoryPublicityCategorizationAdv{
                    AdCategorization{
                      id
                      name
                    }
                  }
                  categoryPublicityComunicationAdv{
                    AdComunicationInstitutional{
                      id
                      name
                    }
                  }
                  clasificationCategoriesAdv {
                    id
                    clasificationCategoryCaracterizacion{
                        caracterization{
                          id
                          name
                        }
                      }
                    clasificationCategoryPublicity {
                      id
                      clasification {
                        id
                        name
                      }
                      categoryPublicity {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }`;
  }

  // clasifications
  queryClasifications() {
    return "{clasifications {message result data {clasification { id name code description }}}}";
  }

  // categprias de publicidad por clasificaccion
  queryCategoryPublicity(idClasification) {
    return `query{
            categoryPublicities(clasification: {id: ${idClasification}}) {
              message
              result
              data {
                clasificacionCategoryPublicity {
                  clasification{
                    id
                    name
                  }
                  categoryPublicity {
                    id
                    hasChildren
                    idFatherCategoryPublicity
                    name
                  }
                }
              }
            }
          }`;
  }

  queryCategoryPublicityForClasificationAndCategory(idClasification, idCategoryPublicity) {
    return `query{
            categoryPublicityForClasificationAndCategory(clasification: {id: ${idClasification}}, categoryPublicity:{id: ${idCategoryPublicity}}) {
              message
              result
              data {
                clasificacionCategoryPublicity {
                  clasification{
                    id
                    name
                  }
                  categoryPublicity {
                    id
                    hasChildren
                    idFatherCategoryPublicity
                    name
                  }
                }
              }
            }
          }`;
  }

  // requerimientos por clasificador
  queryRequirementSiteByClasification(clasificationId, codeRequirementSite) {
    // CONDITION
    return `query{
      requirementSites(clasification: {id: ${clasificationId}}, requirementSite: {id: 0, code: "${codeRequirementSite}"}) {
        message
        result
        data {
          requirementSite {
            id
            name
            idFatherRequirementSite
            code
            requiredRequirement
            requiredImagenInstitucional
          }
        }
      }
    }`;
  }

  queryRequirementSiteByClasificationEstructura(clasificationId, codeRequirementSite, esEstructura) {
    return `{
      requirementSitesByClasification(clasification: {id: ${clasificationId}}, requirementSite: {id: 0, code: "${codeRequirementSite}"}, estructura: ${esEstructura}) {
        message
        result
        data {
          requirementSite {
            id
            name
            idFatherRequirementSite
            requiredRequirement
            requiredImagenInstitucional
          }
        }
      }
    }`
  }

  // requerimientos por clasificador y catgeoria
  queryRequirementSiteByClasificationAndCategory(clasificationId, idCategory, codeRequirementSite) {
    return `query{requirementSitesForCategory (clasification: {id: ${clasificationId}}, categoryPublicity: {id: ${idCategory}}, requirementSite: { id: 0, code:"${codeRequirementSite}"} ){message result data {requirementSite{  id  name  idFatherRequirementSite}}}}`
  }

  // update estado advertising
  queryUpdateEstadoAdvertising(codeEstadoActual, codeEstadoNuevo, tokenAdvertising) {
    return `mutation{
      updateEstadoAdvertising(advertising:{codeEstadoOld:"${codeEstadoActual}", codeEstadoNew:"${codeEstadoNuevo}", token:"${tokenAdvertising}"}){
        result
        message
        data{
          advertising{
            token
          }
        }
      }
    }`;
  }

  // delete publicidad en_proceso
  deleteAdvertisingByToken(tokenAdvertising) {
    return `mutation {
      deleteAdvertisingAndRelatedData(advertising: {token: "${tokenAdvertising}"}) {
        message
        result
      }
    }`;
  }

  totalesByAdvertisingAndEstado(tokenUser, nameRol) {
    return `query{
      totalAdvertisingByEstado(usuario: {token: "${tokenUser}"}, rol: {name: "${nameRol}"}) {
        result
        message
        data {
          advertisingByEstadoCount {
            totalPublicidad
            Estado {
              code
              name
            }
          }
        }
      }
    }`;
  }

  // verificar rango de fecha para el elemento-empresa mediante el CRAPE - RANGO DE FECHA
  queryAdvertisingsByRangeDateEmpresarial(numeroCrape, startDate, endDate) {
    return `query{
    advertisingsByRangeDateEmpresarial(advertising:{numeroCrape: "${numeroCrape}", dateStart:"${startDate}", dateExpires:"${endDate}"}){
      result
      message
      data{
        advertising{
          numero
          dateStart
          dateExpires
        }
      }
    }
    }`
  }

  // updateEstadoAdvertisingRequeriment
  updateEstadoAdvertisingRequerimentSite(tokenAdvertising, tokenAdvRequirement, codeEstadoOld, estadoCode, description) {
    return `mutation {
      updateEstadoAdvertisingRequeriment(advertising: {token: "${tokenAdvertising}"}, advertisingRequirementSite: {token: "${tokenAdvRequirement}", codeEstadoOld: "${codeEstadoOld}", codeEstadoNew: "${estadoCode}"}, observation:{description:"${description}"}) {
        result
        message
        data {
          advertisingRequirementSite {id}
        }
      }
    }`;
  }

  // requisito rechazados
  queryRequirementsSiteAdvertisingRechazado(tokenAdvertising) {
    return `query {requirementsSiteAdvertising(advertising: {token: "${tokenAdvertising}"}, requirementSite:{id:0, code:"REQUIREMENT"}, rechazado: true) {
      result
      message
      data {
        advertisingRequirementSite {
          id
          token
          url
          AdRequirementSite {
            id
            name
          }
          Estado{
            id
            name
            code
          }
          AdAdvertisingRequirementObservations{
            description
            observationDate
          }
        }
      }
    }
  }`;
  }

  // numero de licnencia - admision
  queryInfoAdvertisingByNumeroLicenciaAdmision(numeroLicenciaAdvertising) {
    return `query{ advertisingByNumeroLicenciaByAdmision(advertising: {numeroLicencia: ${numeroLicenciaAdvertising}}) {
result
message
data {
advertising {
id
token
        fur
        numero
dateStart
        dateExpires
        numeroLicencia 
        numeroAutorization
        createdAt
AdActividadEconomicaRuat {
nroActividad
tipoActividad
}
AdDataTechnical {
                    id
                    alto
                    ancho
                    dimension
                    AdUbicacions {
                      idAreaHomogenea
            latitud
            longitud
            coordinate
            comuna
            direccion
            direccionEntre
            direccionY
            urlImagen
                    }
                  }
                  AdTitulars {
                    id
                    natural
                    Persona {
                      nombre
                      apellidoPaterno
                      apellidoMaterno
                      apellidoCasada
                      numeroDocumento
                      expedidoEn
                    }
                    AdTitularJuridico {
                      id
                      razonSocial
                      numeroDocumento
                    }
                  }
                  Estado {
                    id
                    name
                    code
                  }
                  categoryPublicityCategorizationAdv{
                    AdCategorization{
                      id
                      name
                    }
                  }
                  categoryPublicityComunicationAdv{
                    AdComunicationInstitutional{
                      id
                      name
                    }
                  }
                  clasificationCategoriesAdv {
                    id
                    clasificationCategoryCaracterizacion{
                        caracterization{
                          id
                          name
                        }
                      }
                    clasificationCategoryPublicity {
                      id
                      clasification {
                        id
                        name
                        code
                      }
                      categoryPublicity {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }`;
  }

  queryUpdateDigitalizacionAdvertising(tokenAdvertising) {
    return `mutation{
      updateDigitalizadoAdvertising(advertising:{token: "${tokenAdvertising}"}){
        result
        message
        data{
          advertising{
            token
            digitalizado
          }
        }
      }
    }`;
  }

  queryStateAdvertising(numero, token) {
    return `query{
        advertisingDataState( advertising: {numero: "${numero}", token: "${token}"} ) {
          result
          message
          data {
            stateAdvertising {
              isCompleteDataTechnical
              isCompleteDataRequirement
              isCompleteDataContribuyente
              isCompleteDataConditionAndProhition
            }
          }
        }
      }`;
  }

  queryDigitalizacion(numero, token) {
    return `mutation{
        digitalizarAdvertising( advertising: {numero: "${numero}", token: "${token}"} ) {
          result
          message
        }
      }`;
  }

  queryAreaHomogeneaRestricction(zona, idCategoryPublicity){
    return `query{
      findRectricctionByZone(areaHomogenea: {zona: "${zona}"}, catetegoryPublicity: {id: ${idCategoryPublicity}}) {
        result
        message
        data {
          areaHomogenea {
            zona
            id
          }
        }
      }
    }`
  }
}

export default Consulta;