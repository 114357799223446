import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Config from '../../data/config';
import Contact from '../Home/Contact';
import Texto from '../../data/es';
import Links from '../../data/link';
//import { useHistory } from 'react-router-dom'
import NewsLetter from '../../components/Home/NewsLetter';
import AuthService from "../../components/Usuario/AuthService";

const Footer = (props) => {

  const auth = new AuthService();

  useEffect(() => {
    if (auth.loggedIn()) {
      let timeoutSession;
      const start = Date.now();
      /*document.onmousemove = function () {
        clearTimeout(timeoutSession);
        timeoutSession = setTimeout(function () {
          console.log("Cerrando session de forma automatica");
          auth.logout();
          window.location.replace("/");
          const end = Date.now();
          console.log(end - start);
        }, 4000000);   //1000000  15 min,  600000  10 min, 3000 30 seg,  300000  5 min
      }*/
    }
  }, []);

  const handleMailClick = (event) => {
    event.preventDefault()
    window.jQuery("#modalFormContacts").modal("show")
  }
  /*
    const handleOpenCertificacion = (event) => {
      event.preventDefault();
      history.push(Links[82].url);
    }*/

  return (
    <>
      <NewsLetter />
      <footer className="footer bg-teklet-deep ">
        <div className="footer-top">
          <div className="container" style={{ borderTop: '1px solid white' }}>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer mobile-app">
                  <div className="app-button">
                    <div className="footer-atm text-center">
                      <Link to={{ pathname: "/" }} className="logo d-inline " >
                        <img src={Config[2].url + '/static/img/logo_atm.png'} alt="logo" className="img-fluid logo-footer mb-3" style={{ width: 100 }} width='100' height='100' />
                      </Link>
                      <p className="h6 text-footer-atm text-white">{Texto.administracion_tributaria_municipal}</p>
                    </div>
                    <div className="footer-atm text-white mt-3 ">
                      <p>Calle Jose Marti y Calle Tarapaca (Edificio HOFFMANN).</p>
                      <p>Telf: +591 4258030</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h3 className="" >{Texto.tramites}</h3>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      <ul>
                        <li><Link to={{ pathname: Links[113].url }} aria-label={Links[113].title} className="" >{Links[113].title}</Link></li>
                        <li><Link to={{ pathname: Links[111].url }} aria-label={Links[111].title} className="" >{Links[111].title}</Link></li>
                        <li><Link to={{ pathname: Links[112].url }} aria-label={Links[112].title} className="" >{Links[112].title}</Link></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-link">
                  <h3>{Texto.departamentos}</h3>
                  <ul>
                    <li><Link to={{ pathname: '' }} aria-label="Ingresos Tributario" className="" >Ingresos Tributarios</Link></li>
                    <li><Link to={{ pathname: '' }} aria-label="Fiscalización" className="" >Fiscalización</Link></li>
                    <li><Link to={{ pathname: Links[94].url }} aria-label="Legal Tributario" className="" >Legal Tributario</Link></li>
                    <li><Link to={{ pathname: Links[94].url }} aria-label="Mobilidad Urbana" className="" >Mobilidad Urbana</Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-footer f-contact">
                  <h3>{Texto.herramientas}</h3>
                  <ul>
                    <li><Link to={{ pathname: Links[22].url }} aria-label="Guías" className="text-white " style={{ fontSize: '14px' }} >{Links[22].title}</Link></li>
                    <li><Link to={{ pathname: Links[55].url }} aria-label="Quienes Somos?" className="text-white " style={{ fontSize: '14px' }} >{Links[55].title}</Link></li>
                    <li><Link to={"#"} aria-label={Texto.contactanos} onClick={handleMailClick} className="text-white " style={{ fontSize: '14px' }}  >{Texto.contactanos}</Link></li>
                    <li><Link to={{ pathname: Links[48].url }} aria-label={Texto.entidades_financieras} className="text-white " style={{ fontSize: '14px' }} target="_blank"  >{Texto.entidades_financieras}</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-12">
                  <div className="content">
                    <ul className="footer-bottom-links">
                      <li>
                        <Link to={{ pathname: '/terminos-condiciones' }} aria-label="Términos y Condiciones" className="" >
                          Términos y Condiciones de Uso
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: '#' }} aria-label="Mapa del Sitio" className="" >
                          Mapa del Sitio
                        </Link>
                      </li>
                    </ul>
                    <p className="copyright-text " >
                      <span>©</span> {Texto.derechos_reservados + " - " + Texto.gobierno_autonomo_municipal_cbba}{" " + (new Date()).getFullYear()}
                    </p>
                    <ul className="footer-social">
                      <li>
                        <Link to={{ pathname: 'https://www.facebook.com/gamcochabamba' }} target="_blank" rel="noreferrer noopener" aria-label="Facebook de GAMC" className="" >
                          <i className="fab fa-facebook-f"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: 'https://twitter.com/gam_cochabamba' }} target="_blank" rel="noreferrer noopener" aria-label="Twitter de GAMC" className="" >
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: 'https://www.instagram.com/cochabambaciudad' }} target="_blank" rel="noreferrer noopener" aria-label="Instagram de GAMC" className="" >
                          <i className="fab fa-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to={"#"} aria-label="Formulario de Contacto de GAMC" className="" >
                          <i className="far fa-envelope" aria-hidden="true" onClick={handleMailClick} ></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" className="scroll-top btn-hover bg-aero" aria-label="scroll-to-top">
        <i className="fas fa-chevron-up"></i>
      </a>
      <div id="mapUbicacionActividadEconomica" className="map-layer" style={{ width: "794px" }}></div>
      <Contact />
    </>
  )
}

export default Footer;