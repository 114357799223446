import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../../components/style/form-wizard.css";
import AuthService from "../Usuario/AuthService";
import Fetch from "../utils/Fetch";
import Links from "../../data/link";
import Constant from "../../data/constant";
import Texto from "../../data/es";
import TitlePage from "../utils/TitlePage";
import Config from "../../data/config";
import Consulta from "./Consulta";
import funcChangeFormatErrorMessageParsley from './../utils/ChangeFormatErrorMessageParsley';
import Step1EventualFormValideDatosTecnicos from "./EditForm/Step1EventualFormValideDatosTecnicos";
import Step2EditFormRequisitos from "./EditForm/Step2EditFormRequisitos";
import Step4FormContribuyente from "./EditForm/Step4FormContribuyente";
import StepFormCongratulation from "./StepFormCongratulation";

import Step1EmpresaFormDatosTecnicos from "./EditForm/Step1EmpresaFormDatosTecnicos";
import Step1ActEconomicaFormDatosTecnicos from "./EditForm/Step1ActEconomicaFormDatosTecnicos";

const breadcrumbs = [
    {
        title: Links[0].title,
        url: Links[0].url,
    },
    {
        title: Links[128].title,
        url: Links[128].url,
    },
    {
        title: Links[129].title,
        url: Links[129].url,
    },
    {
        title: Links[130].title,
        url: Links[130].url,
    },
    {
        title: Links[136].title,
        url: Links[136].url,
    }
];

let dataForm = {};
const Edit = () => {

    const { token, template } = useParams();
    //const { form, token, numero } = useParams();

    const titlePage = Texto.publicity_edit;
    const urlPdfRepositorioMain = `${Config[10].url}`;

    let formnumber = 0;

    const [dataAdvertising, setDataAdvertising] = useState(null);
    const [dataAdTitular, setDataAdTitular] = useState(null);
    const [dataClasification, setDataClasification] = useState(null);
    const [dataCategoryPublicity, setDataCategoryPublicity] = useState(null);
    const [currentStep, setCurrentStep] = useState("");
    //const [dataAdvertisingRequirementSite, setDataAdvertisingRequirementSite] = useState([]);
    // const [dataAdTitular, setDataAdTitular] = useState(null);
    const fetch = new Fetch();
    fetch.setToast(toast);
    const history = useHistory();
    const auth = new AuthService();
    const consultaSQL = new Consulta();

    useEffect(() => {
        debugger
        if (!auth.loggedIn()) {
            history.replace(Links[4].url);
            return;
        }
        loadDataTechnical(template, token);
        loadDataRequirement(template, token);
        loadDataContribuyente(template, token);
        window.scrollTo(0, 0);
    }, []);

    const loadDataTechnical = async (form, token) => {
        debugger;
        if (form === "datos-tecnicos") {
            //setSearchData(true);
            try {
                const query = consultaSQL.queryInfoAdvertisingByTokenForEdit(token);
                const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
                setDataClasification(null);

                if (data && data.advertising && data.advertising.result) {
                    const publicidadinfo = data.advertising.data.advertising;
                    console.log("+++++++++++++ publicidadinfo edit +++++++++++++++++   ", publicidadinfo);
                    setDataAdvertising(publicidadinfo);
                    debugger
                    if (publicidadinfo && publicidadinfo.clasificationCategoriesAdv) {
                        const clasificationCategoriesAdvInfo = publicidadinfo.clasificationCategoriesAdv[0];
                        if (clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification)
                            setDataClasification(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification);
                        setDataCategoryPublicity(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.categoryPublicity);
                        setCurrentStep(2);
                        toast.success(data.advertising.message);
                    }
                } /*else {
                    setDataAdvertising(null);
                    //setDataClasification(null);
                    //setDataCategoryPublicity(null);
                    setCurrentStep(2);
                    //toast.success(data.advertising.message);
                }*/
            } catch (error) {
                toast.error("Error al cargar los datos de la publicidad");
            }
        }
    };

    const loadDataRequirement = async (form, token) => {
        debugger;
        if (form === "requerimientos") {
            //setSearchData(true);
            try {
                const query = consultaSQL.queryInfoAdvertisingByTokenForEdit(token);
                const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
                setDataClasification(null);

                if (data && data.advertising && data.advertising.result) {
                    const publicidadinfo = data.advertising.data.advertising;
                    //console.log("+++++++++++++ publicidadinfo edit +++++++++++++++++   ", publicidadinfo);

                    debugger
                    if (publicidadinfo && publicidadinfo.clasificationCategoriesAdv) {
                        const clasificationCategoriesAdvInfo = publicidadinfo.clasificationCategoriesAdv[0];
                        setDataAdvertising(publicidadinfo);
                        if (clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification)
                            setDataClasification(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification);
                        toast.success(data.advertising.message);
                        setCurrentStep(3);
                    }

                }
            } catch (error) {
                toast.error("Error al cargar los datos de la publicidad");
            }
        }
    };

    const loadDataContribuyente = async (form, token) => {
        debugger;
        if (form === "contribuyente") {
            //setSearchData(true);
            try {
                const query = consultaSQL.queryInfoAdvertisingByTokenForEdit(token);
                const data = await fetch.fetchPostGraphQl(query, "/graphql", null);
                setDataClasification(null);

                if (data && data.advertising && data.advertising.result) {
                    const publicidadinfo = data.advertising.data.advertising;
                    if (publicidadinfo && publicidadinfo.clasificationCategoriesAdv) {
                        const clasificationCategoriesAdvInfo = publicidadinfo.clasificationCategoriesAdv[0];
                        setDataAdTitular(publicidadinfo.AdTitulars.length > 0 ? publicidadinfo.AdTitulars[0] : undefined);
                        setDataAdvertising(publicidadinfo);
                        if (clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification)
                            setDataClasification(clasificationCategoriesAdvInfo.clasificationCategoryPublicity.clasification);
                        setCurrentStep(4);
                        toast.success(data.advertising.message);
                    }
                }
            } catch (error) {
                toast.error("Error al cargar los datos de la publicidad");
            }
        }
    };

    const nextClick = async (event, numForm, submit, data) => {
        debugger
        //let valideForm = valideInputsForm(event);
        let contUpdate = 1;
        if (!valideInputsForm(event))
            return false;

        if (numForm === 1) {
            dataForm.datosTecnicos = data;
            formnumber = numForm;
            contUpdate = 5;
        }

        if (numForm === 2) {
            dataForm.requirement = data;
            formnumber = numForm - 1;
            contUpdate = 5;
        }

        if (numForm === 3) {
            dataForm.contribuyente = data;
            formnumber = numForm - 2;
            contUpdate = 5;
        }

        if (submit) {
            debugger
            const advertisingDbUpdate = await updateAdvertisingInfo(dataForm);
            const requirementUpdate = await updateRequirementInfo(dataForm);
            const contribuyenteUpdate = await updateContribuyenteInfo(dataForm);
            debugger
            if (advertisingDbUpdate || requirementUpdate || contribuyenteUpdate) {
                setTimeout(() => {
                    debugger
                    updateform();
                    progressForward(formnumber + contUpdate);
                    contentchange();
                }, 300);
            }
        }
    };

    const updateAdvertisingInfo = async (dataForm) => {

        if (dataForm && dataForm.datosTecnicos) {
            const dataTechnical = dataForm.datosTecnicos;
            const advertising = dataTechnical.advertising;
            const dateRange = advertising.rangeDate.split("-");
            const dateStart = fechaFormateado(dateRange[0].trim());
            const dateEnd = fechaFormateado(dateRange[1].trim());

            const idCaracterizacion = (advertising.caracterization === undefined ? 0 : advertising.caracterization);
            const idCategorization = (advertising.categorization === undefined ? 0 : advertising.categorization);
            const idComunication = (advertising.comunication === undefined ? 0 : advertising.comunication);

            const inmueble = advertising.inmueble.inmueble ? advertising.inmueble.inmueble : undefined;
            const advDatosCatastro = dataTechnical.modificado.datostecnicos ? (advertising.inmueble.datosCatastro) : (advertising.inmueble.AdUbicacions[0]);
            const actEconomicaRuat = dataTechnical.actividadEconomica.actividadEconomicaRuat;
            if (inmueble)
                advDatosCatastro.codigoCatastral = inmueble.codigoCatastral;

            const area = { id: 0, zona: "" }
            if ("idAreaHomogenea" in advDatosCatastro)
                area.id = advDatosCatastro.idAreaHomogenea
            if ("zonaTributaria" in advDatosCatastro)
                area.zona = advDatosCatastro.zonaTributaria

            let urlImage = (dataTechnical.modificado.datostecnicos ? advDatosCatastro.base64Image : (advDatosCatastro.urlImagen));

            const queryUpdate = `mutation {
            updateAdvertising(advertising: {token: "${token}", dateStart: "${dateStart}", dateExpires:"${dateEnd}", fur:"0"}, 
            clasification: {id: ${advertising.clasification}},
            categoryPublicity: {id:${advertising.category}}, 
            caracterization: {id: ${idCaracterizacion}}, 
            categorization: {id: ${idCategorization}}, 
            comunication: {id: ${idComunication}},
            dataTechnical: { ancho: ${advertising.ancho}, alto:${advertising.alto}},
            areaHomogenea:{ id: ${area.id}, zona: "${area.zona}"},
            ubication:{ latitud:"${advDatosCatastro.latitud}", longitud:"${advDatosCatastro.longitud}", coordinate:"${advDatosCatastro.coordinate}",  
                urlImagen:"${urlImage}", comuna: "${advDatosCatastro.comuna}", direccion:"${advertising.direccion}",  direccionEntre:"${advertising.direccionEntre}",
                direccionY:"${advertising.directionY}", zonaTributaria:"${advDatosCatastro.zonaTributaria}", codigoCatastral:"${advDatosCatastro.codigoCatastral}",  nroInmueble:"${advDatosCatastro.nroInmueble}" },
            actividadEconomica: {
                nroActividad: "${actEconomicaRuat?.numeroActividad}", 
                nroLicencia:"${actEconomicaRuat?.licencia}", 
                codigoClasificador: "${actEconomicaRuat?.codigoClasificador}", 
                codigoActividadEconomica: "${actEconomicaRuat?.codigoActividadEconomica}", 
                tipoLicencia:"${actEconomicaRuat?.tipoLicencia}", 
                tipoActividad:"${actEconomicaRuat?.tipoActividad}"
            }
            ){
              message
              result                        
            }
          }`;
            const respAdvertising = await fetch.fetchPostGraphQl(queryUpdate, "/graphql", null);
            if (respAdvertising && respAdvertising.updateAdvertising && respAdvertising.updateAdvertising.result) {
                toast.success("Titular: " + respAdvertising.updateAdvertising.message);
                return respAdvertising.updateAdvertising.result; //.data.advertising;
            }
        }
        return false;
    }

    const updateRequirementInfo = async (dataForm) => {
        let listAdvRequirementSite = [];

        if (dataForm && dataForm.requirement) {
            debugger
            try {

                const requirement = dataForm.requirement;
                //console.log("++++++++++++++++++ requirement +++ updateRequisitosPublicidad ++++ ", requirement);
                if (requirement.length > 0 && parseInt(dataAdvertising.id) > 0) {
                    debugger
                    let queryPromise = requirement.map(async (rowRequeriment) => {
                        const file = rowRequeriment.file;
                        let idArray = rowRequeriment.id.split("[");  //site[6]
                        idArray = idArray[idArray.length - 1].split("]");
                        const formData = new FormData();
                        formData.append('idAdvertising', dataAdvertising.id);
                        formData.append('idRequirementSite', idArray[0]);
                        formData.append('file', file);
                        debugger
                        //console.log("formdata > _________________", formData);
                        debugger
                        const resqRequisitos = await fetch.fetchPostFormDataGraphQl(formData, "/api/advertising/edit-requirement-site", undefined);
                        //console.log(" ---- resqRequisitos resqRequisitos resqRequisitos -------- ", resqRequisitos);
                        if (resqRequisitos && resqRequisitos.result) {
                            // const dataAdvRequirementSite = 
                            listAdvRequirementSite.push(resqRequisitos);
                        }
                        return listAdvRequirementSite;
                    });

                    toast.success("Se actualizo los requisitos adjuntos");
                    queryPromise = await Promise.all(queryPromise);
                    return queryPromise.filter((requirement) => requirement);
                } else {
                    toast.error("No existe la publicidad, no podrá realizar la operación.");
                }
            } catch (err) {
                console.error(err);
                toast.error("Ocurrio un error al momento de subir los archivos pdf.");
            }
        }
        return false;
    }

    const updateContribuyenteInfo = async (dataForm) => {
        debugger
        if (dataForm && dataForm.contribuyente) {
            try {
                const propietario = dataForm.contribuyente;
                const advertising = dataForm.contribuyente.advertising;
                if (propietario && advertising) {
                    let codigoContribuyente = undefined;
                    if (propietario.isNatural)
                        codigoContribuyente = propietario.natural.dataNatural.codigoContribuyente;
                    else
                        codigoContribuyente = propietario.juridico.dataJuridico.codigoContribuyente;

                    const query = `mutation{
                        addPersona(persona:{codigoRuat:"${codigoContribuyente}"}, advertising: {id: ${advertising.id}} ){
                          message
                          result
                          data{
                            titular{
                              numeroDocumento
                            }
                            titularJuridico{
                              pmc
                            }
                          }
                        }
                      }`;
                    const respAdvertising = await fetch.fetchPostGraphQl(query, "/graphql", null);
                    debugger
                    if (respAdvertising && respAdvertising.addPersona && respAdvertising.addPersona.result) {
                        toast.success("Titular: " + respAdvertising.addPersona.message);
                        return respAdvertising.addPersona.data;
                    }
                } else
                    toast.info('Titular: Datos de inicio requeridos');
                return undefined;
            } catch (err) {
                console.error(err);
            }

            return true;
        }
    }

    const stepDetailInfo = () => {
        return (<>
            {
                Constant[0].publicidad_step_new.map((step, index) => (
                    index == 0 ?
                        <p key={index + 1} className="step-number-content text-justify active-f-w">{step}</p>
                        :
                        <p key={index + 1} className="step-number-content text-justify d-none">{step}</p>

                ))
            }
        </>)
    }

    const calculoSuperficieM2 = (alto, ancho) => {
        let totalM2 = 0;
        if (alto > 0 && ancho > 0) {
            totalM2 = (Math.round((alto * ancho) * 100) / 100);
        }
        return totalM2;
    }

    const valideInputsForm = (event) => {
        event.preventDefault();
        const formHtml = event.target.closest("form")
        window
            .jQuery("#" + formHtml.getAttribute("id"))
            .parsley()
            .validate();

        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroActividad', null);
        funcChangeFormatErrorMessageParsley('input', 'datosTecnicos-nroInmueble', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-category', null);
        funcChangeFormatErrorMessageParsley('select', 'datosTecnicos-caracterization', null);
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_entre', 'error_adyacente_entre');
        funcChangeFormatErrorMessageParsley('input', 'ubicacion-adyacente_y', 'error_adyacente_y');

        if (window.jQuery("#" + formHtml.getAttribute("id")).parsley().isValid())
            return true;  //return JSON.stringify(jsonObject);
        return false;
    }

    const fechaFormateado = (dateParam) => {
        debugger
        let resultado = null;
        const lengthDate = dateParam.trim().length;
        const diaActual = window.moment().format('DD');

        if (lengthDate > 0 && lengthDate === 7) {
            resultado = window.moment(diaActual + "/" + dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        if (lengthDate > 0 && lengthDate === 10) {
            resultado = window.moment(dateParam, "DD/MM/YYYY").format("YYYY-MM-DD");
        }
        return resultado;
    }

    const updateform = () => {
        const mainForm = document.querySelectorAll(".main-wizard");
        mainForm.forEach(function (mainformNumber) {
            mainformNumber.classList.remove('active-f-w');
        })
        mainForm[formnumber] && mainForm[formnumber].classList.add('active-f-w');
    }
    // modificado
    const progressForward = (currentStep) => {
        const stepList = document.querySelectorAll(".progress-bar-f-wizard li");
        const num = document.querySelector(".step-number");

        // Actualizar el número del paso actual
        num.innerHTML = currentStep;
        //console.log("nro de paso ", currentStep);
        // Marcar el paso actual como activo
        if (currentStep > 0 && currentStep <= stepList.length) {
            stepList.forEach((step, index) => {
                if (index < currentStep) {
                    step.classList.add('active-f-w');
                } else {
                    step.classList.remove('active-f-w');
                }
            });
        }
    };

    const contentchange = () => {
        const stepNumContent = document.querySelectorAll(".step-number-content");
        stepNumContent.forEach(function (content) {
            content.classList.remove('active-f-w');
            content.classList.add('d-none');
        });
        if (stepNumContent[formnumber]) {
            stepNumContent[formnumber].classList.add('active-f-w');
            stepNumContent[formnumber].classList.remove('d-none');
        }
    }

    const loadInmuebleCatastro = async (codigo_catrastral) => {
        debugger
        const resInmuebleCatastro = await fetch.fetchGet(
            `api-ruat/inmuebles-catastro/codigo-catastral/${codigo_catrastral}`
        );
        if (resInmuebleCatastro && resInmuebleCatastro.status) {
            if (resInmuebleCatastro.data.inmueble.length > 0) {
                debugger
                return resInmuebleCatastro.data.inmueble[0];
            }
        }
        return undefined;
    };

    const findInmueble = async (nroInmueble, identificador) => {
        let url = undefined;
        if (identificador === "CAT")
            url = `api-ruat/inmuebles/codigo-catastral/${nroInmueble}`;
        if (identificador === "NIM")
            url = `api-ruat/inmuebles/nro-inmueble/${nroInmueble}`;

        if (url) {
            const respInmueble = await fetch.axiosAsyncGet(url);
            if (Boolean(respInmueble) && respInmueble.status)
                return respInmueble;
        }

        return null;
    }

    const findActEcoByNumero = async (numeroAct) => {

        const resActEco = await fetch.fetchGet(
            `api/actividad-economica/get-by-nro-act-eco-ruat/${numeroAct}`
        );
        if (resActEco && resActEco.status) {
            if (resActEco.ActividadEconomica)
                return resActEco.ActividadEconomica;
        }
        return undefined;
    }

    const findContribuyenteByCodContribuyente = async (codigoContribuyente) => {

        const resActEco = await fetch.fetchGet(
            `api-ruat/contribuyentes/cc/${codigoContribuyente}`
        );
        if (resActEco && resActEco.status) {
            debugger
            if (resActEco.data.contribuyente.length > 0) {
                return resActEco.data.contribuyente[0];
            }
        }
        return undefined;
    }

    return (
        <>
            <section className="items-grid section mt-5 ">
                <TitlePage
                    titlePage={titlePage}
                    breadcrumbs={breadcrumbs}
                    position={"left"}
                    leftfull={false}
                />
                <div className="container-f-wizard">
                    <div className="card-w">
                        <div className="form">
                            <div className="left-side bg-teklet-deep">
                                <div className="left-heading">
                                    <h4 className="text-center">Editar Publicidad
                                        <br />
                                        {dataClasification?.name}
                                    </h4>
                                    <p className="text-justify mt-4">
                                        <b>Fur: </b> {dataAdvertising?.fur} <br />
                                        <b>Nº Orden: </b> {dataAdvertising?.numero}
                                    </p>
                                </div>
                                <div className="steps-content">
                                    <h4>Paso <span className="step-number">{currentStep}</span></h4>
                                    {stepDetailInfo()}
                                </div>

                                <ul className="progress-bar-f-wizard ">
                                    <li key={0}>Derecho de Admisión</li>
                                    <li className={currentStep === 2 ? " active-f-w" : ""} key={1}>Datos Técnicos</li>
                                    <li className={currentStep === 3 ? " active-f-w" : ""} key={2}>Requisitos</li>
                                    <li className={currentStep === 4 ? " active-f-w" : ""} key={3}>Condiciones y Prohibiciones</li>
                                    <li key={4}>Contribuyente</li>
                                    <li key={5}>Vista Prevía</li>
                                </ul>
                            </div>

                            <div className="right-side">
                                {
                                    dataAdvertising && dataClasification && dataCategoryPublicity ?
                                        // eventual
                                        <>
                                            {dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_eventual && template === 'datos-tecnicos' ?
                                                <Step1EventualFormValideDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    //backClick={(e) => backClick(e, 1)}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={1}
                                                    loadInmuebleCatastro={loadInmuebleCatastro}
                                                    findInmueble={findInmueble}
                                                    findActEcoByNumero={findActEcoByNumero}
                                                    findContribuyenteByCodContribuyente={findContribuyenteByCodContribuyente}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                /> : ""
                                            }

                                            {/* // estructura - empresa*/}
                                            {(dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_empresarial) && template === 'datos-tecnicos' ?
                                                <Step1EmpresaFormDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    //backClick={(e) => backClick(e, 1)}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={1}
                                                    loadInmuebleCatastro={loadInmuebleCatastro}
                                                    findInmueble={findInmueble}
                                                    findActEcoByNumero={findActEcoByNumero}
                                                    findContribuyenteByCodContribuyente={findContribuyenteByCodContribuyente}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                /> : ""
                                            }

                                            {/* // act economica */}
                                            {(dataClasification && dataClasification.code === Constant[0].publicidad_clasificador.code_actividad) && template === 'datos-tecnicos' ?
                                                <Step1ActEconomicaFormDatosTecnicos
                                                    fetch={fetch}
                                                    toast={toast}
                                                    nextClick={nextClick}
                                                    auth={auth}
                                                    history={history}
                                                    dataClasification={dataClasification}
                                                    dataCategoryPublicity={dataCategoryPublicity}
                                                    dataAdvertising={dataAdvertising}
                                                    calculoSuperficieM2={calculoSuperficieM2}
                                                    stepNumber={1}
                                                    loadInmuebleCatastro={loadInmuebleCatastro}
                                                    findInmueble={findInmueble}
                                                    findActEcoByNumero={findActEcoByNumero}
                                                    findContribuyenteByCodContribuyente={findContribuyenteByCodContribuyente}
                                                    Texto={Texto}
                                                    urlPdfRepositorioMain={urlPdfRepositorioMain}
                                                    Constant={Constant}
                                                /> : ""
                                            }
                                        </>
                                        : ""
                                }

                                {dataAdvertising && dataClasification && !dataCategoryPublicity && template === 'requerimientos' ?
                                    <Step2EditFormRequisitos
                                        fetch={fetch}
                                        toast={toast}
                                        nextClick={nextClick}
                                        backClick={null}
                                        auth={auth}
                                        history={history}
                                        advertising={dataAdvertising}
                                        dataClasification={dataClasification}
                                        stepNumber={2}
                                    /> : ""}

                                {dataAdvertising && template === 'contribuyente' ?
                                    <Step4FormContribuyente
                                        fetch={fetch}
                                        toast={toast}
                                        nextClick={nextClick}
                                        backClick={null}
                                        auth={auth}
                                        history={history}
                                        advertising={dataAdvertising}
                                        dataClasification={dataClasification}
                                        dataAdTitular={dataAdTitular}
                                        stepNumber={3}
                                    /> : ""}

                                <StepFormCongratulation
                                    fetch={fetch}
                                    toast={toast}
                                    backClick={undefined}
                                    auth={auth}
                                    history={history}
                                    dataForm={dataForm}
                                    advertisingDb={dataAdvertising}
                                    stepNumber={4}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Edit;