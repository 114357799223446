
import React from "react";
import PropTypes from "prop-types";

const InfoPublicidad = (props) => {

    const { data, Texto} = props;

    return (
        <>
            <span>
                <strong>{Texto.publicidad.toUpperCase()} </strong>
            </span>
            <hr className="mt-0 " />
            <p>
                <strong>{Texto.numero_orden}: </strong>
                {data.adv_numero}
            </p>
            <p>
                <strong>{Texto.numero_fur}: </strong>
                {data.adv_fur}
            </p>
            <p>
                <strong>{Texto.usuario}: </strong>
                {data.username}
            </p>
            <p>
                <strong>{Texto.tipo}: </strong>
                {data.clasification}
            </p>
            <p>
                <strong>{Texto.categoria}: </strong>
                {data.category_publicity}
            </p>
            <p>
                <strong>{Texto.categorization}: </strong>
                {data.categorization}
            </p>
            <p>
                <strong>{Texto.caracterization}: </strong>
                {data.caracterization}
            </p>
            <p>
                <strong>{Texto.comunicacion_institucional}: </strong>
                {data.comunication_institutinal}
            </p>
            <p>
                <strong>{Texto.creado}: </strong>
                {window.moment(data.created_at).format("DD-MM-YYYY HH:mm")}
            </p>
            <p>
                <strong>{Texto.en_revision}: </strong>
                {window.moment(data.adv_date_created).format("DD-MM-YYYY HH:mm")}
            </p>
        </>
    );
}

export default InfoPublicidad;
