import React, { useState, useEffect } from "react";
import Fetch from "../utils/Fetch";
import { Link } from "react-router-dom";
import AuthService from "../Usuario/AuthService";
import Links from "../../data/link";
import Consulta from "./Consulta";
import Config from "./../../data/config";

const ModalRequisitos = (props) => {
  const { toast, title, userAuth, Constant, advertising, clearRequisitos } =
    props;

  const fetch = new Fetch();
  fetch.setToast(toast);

  const consultaSQL = new Consulta();

  const auth = new AuthService();

  const idModal = "modalRequisitosAdvertising";
  let modal = undefined;

  const [nodeRequirements, setNodeRequirements] = useState([]);

  useEffect(() => {
    debugger
    if (!auth.loggedIn()) window.location.href = Links[4].url;
    openModalRequeriments(advertising.adv_token);
    window.scrollTo(0, 0);
  }, []);

  const openModalRequeriments = (tokenAdvertising) => {
    modal = new window.bootstrap.Modal(document.getElementById(idModal), {
      keyboard: true,
      backdrop: "static",
      focus: true,
    });
    modal.show();
    window.jQuery("#" + idModal).on("shown.bs.modal", function (e) {
      paintRequirements(advertising);
    });
  };

  const paintRequirements = async (advertising) => {
    const requirement = await loadRequirements(advertising.adv_token);
    if (requirement && requirement.length) {
      let cont = 0;
      const tableRequisitos = requirement.map(
        ({ AdRequirementSite, Estado, url, token }) => {
          cont++;
          return (
            <div
              className={"row pointer row-table-custom pt-3 "}
              code={token}
              key={cont + "_" + token}
            >
              <div className="col-6 col-xs-6 col-md-6 ">
                <p className="fs-small9em">{AdRequirementSite.name}</p>
                {advertising.estado_code === Constant.estado.completado &&
                  Estado.code === Constant.estado.rechazado ? (
                  <>
                    <strong>Documento: </strong>
                    <span className={`doc-name-${token}`}></span>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-3 col-xs-3 col-md-3 text-center">
                <p className="fs-small9em">{Estado.name}</p>
                <img
                  className="rounded mx-auto d-block d-none"
                  alt="pulse-loading"
                  src={"/static/img/pulse_200.gif"}
                  id={"img-spinner-" + token}
                  width="50"
                />
              </div>

              <div className="col-3 col-xs-3 col-md-3 text-center">
                <Link
                  to="#"
                  className="pt-3"
                  onClick={(e) => handleOpenFile(e, url)}
                  title={`Visualizar Documento en una nueva ventana`}
                >
                  {url.includes(".pdf") ? (
                    <i className="fas fa-file-pdf h2"></i>
                  ) : (
                    <i className="far fa-image h2"></i>
                  )}
                </Link>
              </div>
            </div>
          );
        }
      );

      setNodeRequirements(tableRequisitos);
    }
/*
    fetch
      .fetchGet(
        `api/saat/requisito-autorizacion-by-token-autorization/${advertising.token}`
      )
      .then((dataJson) => {
        if (dataJson && dataJson.status) {
          let cont = 0;
          const tableRequisitos = dataJson.data.requisitos.map(
            ({ name, status, url, statusCode, mime, token }) => {
              cont++;
              return (
                <div
                  className={"row pointer row-table-custom pt-3 "}
                  code={token}
                  key={cont + "_" + token}
                >
                  <div className="col-5 col-xs-5 col-md-7 ">
                    <p className="fs-small9em">{name}</p>

                    {advertising.estado === Constant.estado.completado &&
                      statusCode === Constant.estado.rechazado ? (
                      <>
                        <strong>Documento: </strong>
                        <span className={`doc-name-${token}`}></span>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-3 col-xs-3 col-md-2 text-center">
                    <p className="fs-small9em">{status}</p>
                    <img
                      className="rounded mx-auto d-block d-none"
                      alt="pulse-loading"
                      src={"/static/img/pulse_200.gif"}
                      id={"img-spinner-" + token}
                      width="50"
                    />
                  </div>

                  <div className="col-2 col-xs-2 col-md-1 text-center">
                    <Link
                      to="#"
                      className="pt-3"
                      onClick={(e) => handleOpenFile(e, url)}
                      title={`Visualizar Documento en una nueva ventana`}
                    >
                      {mime.includes("pdf") ? (
                        <i className="fas fa-file-pdf h2"></i>
                      ) : (
                        <i className="far fa-image h2"></i>
                      )}
                    </Link>
                  </div>
                </div>
              );
            }
          );

          setNodeRequirements(tableRequisitos);
        }
      });*/
  };

  const loadRequirements = async (tokenAdvertising) => {
    const query = consultaSQL.queryRequirementsSiteAdvertising(tokenAdvertising, "REQUIREMENT");
    const requirement = await fetch.fetchPostGraphQl(query, "/graphql", null);
    if (requirement && requirement.requirementsSiteAdvertising && requirement.requirementsSiteAdvertising.result) {
      const respRequisitos = requirement.requirementsSiteAdvertising.data.advertisingRequirementSite;
      return respRequisitos;
    }
    return null;
  };

  /*
  const handleChangeState = (
    event,
    tokenCatReq,
    codeStatus,
    tokenAutorization
  ) => {
    event.preventDefault();
    debugger;
    const butonEvent = event.target;
    const parentContainer = butonEvent.closest(`[code='${tokenCatReq}']`);

    const form = new FormData();
    form.append("saat_cat_req_autorization[token]", tokenCatReq);
    form.append("saat_cat_req_autorization[status]", codeStatus);
    //form.append("saat_cat_req_autorization[description]", 0);
    fetch
      .fetchPost(
        form,
        `api/saat/requisito-autorizacion/change-state`,
        undefined
      )
      .then(async (dataJson) => {
        if (dataJson && dataJson.status) {
          await refrestRow(parentContainer, tokenAutorization, tokenCatReq);

          toast.success(dataJson.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      });
  };
*/
  const handleOpenFile = (event, url) => {
    event.preventDefault();
    window.open( Config[10].url + "/" +url);
  };

  const handleCloseModal = (event) => {
    clearRequisitos();
  };
  
  return (
    <>
      <div
        className="modal fade"
        id={idModal}
        tabIndex="-1"
        aria-labelledby="movalPdfFullLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="titleAdvertisingPdfFullLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close h6 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body me-3 ms-3">
              <div className="row mb-4">
                {advertising && <div className="row mb-4">
                  <div className="col-md-2 col-xs-6"><b>Contribuyente: </b></div>
                  <div className="col-3">{(advertising.titular && advertising.titular.length > 0) ? (advertising.titular[0].titular_juridico_nit_razon_social ? advertising.titular[0].titular_juridico_nit_razon_social : advertising.titular[0].rep_legal_nombre) : ""}</div>
                  <div className="col-md-2 col-xs-6"><b>Fur:</b></div>
                  <div className="col-md-1">{advertising.adv_fur}</div>
                  <div className="col-md-2 col-xs-6"><b>Número:</b></div>
                  <div className="col-md-1">{advertising.adv_numero}</div>
                  <div className="col-md-2 col-xs-6"><b>Clasificación: </b></div>
                  <div className="col-3">{advertising.clasification}</div>
                  <div className="col-md-2 col-xs-6"><b>Categoria: </b></div>
                  <div className="col-3">{advertising.category_publicity}</div>
                </div>
                }
              </div>

              <div className="row">
                <div className={"row pointer row-table-custom pt-3 "}>
                  <div className="col-6 col-xs-6 col-md-6 ">
                    <strong>Requisito</strong>
                  </div>
                  <div className="col-3 col-xs-3 col-md-3 text-center">
                    <strong>Estado</strong>
                  </div>
                  <div className="col-3 col-xs-3 col-md-13 text-center">
                    <strong>Documento</strong>
                  </div>
                </div>

                <div className="col-12 col-md-12 col-lg-12 embed-container overflow-visible">
                  {nodeRequirements}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="col-12 search-btn button text-end">
                <button
                  type="button"
                  className="btn bg-maroon-flush"
                  data-bs-dismiss="modal"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalRequisitos;
