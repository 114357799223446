import Config from "../../data/config";

const NewsLetter = (props) => {
  return (
    <div className="newsletter bg-teklet-deep ">
      <div className="container">
        <div className="inner-content">
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12 col-12 text-center">
              <a href="/" className="">
                <img
                  src={Config[2].url + "/static/img/offcanvas/escudo_cbba.png"}
                  className="img-fluid logo-somos-progre-footer"
                  width="400"
                  height="78"
                  alt="escucho cbba"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetter;
