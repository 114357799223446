import React, { useState, useEffect } from "react";
import Links from "./../../../data/link";
import HeaderTitlecard from "./../_common/HeaderTitleCard";
import Consulta from "./../Consulta";
import Constant from "./../../../data/constant";
import Config from "./../../../data/config";
import Funciones from "./../_common/Funciones";

const Step2EditFormRequisitos = (props) => {

  const { nextClick, fetch, backClick, toast, auth, history, dataClasification, advertising, stepNumber } = props;

  const [nodeRequisitos, setNodeRequisitos] = useState([]);
  const consultaSQL = new Consulta();
  const funciones = new Funciones(fetch, toast);
  const idClasification = dataClasification.id;
  let detalle = Constant[0].publicidad_step_new[2];

  useEffect(() => {
    debugger;
    if (!auth.loggedIn()) history.replace(Links[4].url);

    if (dataClasification) {
      loadRequisitos();
    }
  }, []);

  const loadRequisitos = async () => {
    const esEstructura = ((dataClasification.code === Constant[0].publicidad_clasificador.code_empresarial) && advertising.fur) ? true : false;
    const query = consultaSQL.queryRequirementSiteByClasificationEstructura(idClasification, "REQUIREMENT", esEstructura);
    const respRequistos = await fetch.fetchPostGraphQl(query, "/graphql", null);

    if (respRequistos && respRequistos.requirementSitesByClasification && respRequistos.requirementSitesByClasification.result) {
      const dataRequirementsite = respRequistos.requirementSitesByClasification.data.requirementSite;

      debugger
      const requirmentFiles = await loadRequirements(advertising.token);
      let nodeReq = funciones.requisitosHtml(dataRequirementsite, false);
      if (nodeReq && nodeReq.length > 0) {
        setTimeout(() => {
          for (let index = 1; index <= nodeReq.length; index++) {
            const dataRequitioFile = requirmentFiles.filter((reqint) => reqint.AdRequirementSite.id == dataRequirementsite[index - 1].id);

            if (dataRequitioFile && dataRequitioFile.length > 0) {
              const dataRequisito = dataRequitioFile[0];

              const format = dataRequisito.url.split(".");
              let mimeType = "";
              if (format[format.length - 1] === "pdf") {
                mimeType = "application/pdf";
              }
              if (/^(gif|jpe?g|png)$/i.test(format[format.length - 1])) {
                mimeType = "image/" + format[format.length - 1];
              }

              //console.log("++++++++++++++++++++ ", Config[10].url + "/" + dataRequisito.url);
              const fileDb = new File(["0"], Config[10].url + "/" + dataRequisito.url, {
                type: mimeType,
                lastModified: new Date(),
              });
              const dataTransfer = new DataTransfer();
              dataTransfer.items.add(fileDb);

              const fileInput = document.querySelector(
                'input[id="requirement_site[' +
                dataRequisito.AdRequirementSite.id +
                ']"]'
              );

              if (fileInput) {
                fileInput.files = dataTransfer.files;
                let eInput = new Event("change", { bubbles: true });
                fileInput.dispatchEvent(eInput);

                const linkPdf = document.getElementById("i-link-pdf");
                if (linkPdf) linkPdf.remove();
                else {
                  const linkFileOpen = document.createElement("a");
                  linkFileOpen.href = Config[10].url + "/" + dataRequisito.url;
                  linkFileOpen.id = "i-link-pdf-" + dataRequisito.AdRequirementSite.id;
                  linkFileOpen.target = "_blank";

                  const iFileOpen = document.createElement("i");
                  iFileOpen.classList.add("fas");
                  iFileOpen.classList.add("fa-file-pdf");
                  iFileOpen.classList.add("text-butterfly-bush");
                  iFileOpen.classList.add("h4");

                  linkFileOpen.appendChild(iFileOpen);
                  if (!fileInput.closest(".row").firstChild.querySelector('a[id="i-link-pdf-' + dataRequisito.AdRequirementSite.id + '"]')) {
                    fileInput.closest(".row").firstChild.appendChild(document.createElement("br"));
                    fileInput.closest(".row").firstChild.appendChild(linkFileOpen);
                  }
                }
              }
            }
          }
        }, 600);
      }
      setNodeRequisitos(nodeReq);
    }
  };

  /*const requisitosHtml = (respRequistos) => {
    return respRequistos.map(
      ({ requiredRequirement, requiredImagenInstitucional, name, id }, index) => {
        return (
          <div className="row" key={index + "-" + id} index={index}>
            <div
              className="col-12 col-md-12"
              id={"row-first-requisito-" + id}
            >
              <div
                className={
                  "row row-table-custom pt-2 pb-2  me-1 " +
                  " justify-content-center align-items-center requisitos-" + id
                }
              >
                <div className="col-9 col-md-9">
                  <span className="text-justify"> {name} <br /> url: { }</span>
                  <br />
                  <strong> {requiredRequirement && <span className="text-danger" title="Requisito Obligatorio"> (*) </span>} Archivo: </strong>
                  <span className={`doc-name-${id}`}></span>
                </div>
                <div className="col-3 col-md-3">
                  <div className="form-group files text-center">
                    <label tabIndex={id} className="form-control">
                      <input
                        type="file"
                        className="form-control"
                        id={`requirement_site[${id}]`}
                        name={`requirement_site[]`}
                        //accept={requiredImagenInstitucional ? "image/jpeg, image/png" : ".pdf"}
                        imageinstitucional={requiredImagenInstitucional ? 1 : 0}
                        onChange={handleOnchangeInputFile}
                        data-parsley-required={requiredRequirement}
                        data-parsley-required-message="Por favor, selecciona un archivo"
                        data-parsley-filemimetypes={requiredImagenInstitucional ? "image/jpeg,image/png" : "application/pdf"}
                        data-parsley-filemimetypes-message={requiredImagenInstitucional ? "Solo se permiten imágenes (JPEG/PNG)" : "Solo se permiten archivos PDF"}
                      />
                      {requiredRequirement} {requiredImagenInstitucional}
                    </label>
                    <span className="fs-small8em text-maroon-flush small-description">
                      7MB, Limite Máximo
                    </span>
                    <span className="fas fa-times icon-close" onClick={(e) => funciones.handleClickDeleteFile(e)}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
  };*/

  /*
  const requisitosHtml = (respRequistos) => {
    return respRequistos.map(
      ({ requiredRequirement, requiredImagenInstitucional, name, id }, index) => {
        debugger
        return (
          <div className="row" key={id} index={index}>
            <div
              className="col-12 col-md-12"
              id={"row-first-requisito-" + id}
            >
              <div
                className={
                  "row row-table-custom pt-2 pb-2  me-1 " +
                  " justify-content-center align-items-center requisitos-" + id
                }
              >
                <div className="col-9 col-md-9">
                  <span className="text-justify"> {name} </span>
                  <br />
                  <strong> {requiredRequirement && <span className="text-danger" title="Requisito Obligatorio"> (*) </span>} Archivo: </strong>
                  <span className={`doc-name-${id}`}></span>
                </div>
                <div className="col-3 col-md-3">
                  <div className="form-group files text-center">
                    <label tabIndex={id} className="form-control">
                      <input
                        type="file"
                        className="form-control"
                        multiple=""
                        id={`requirement_site_${id}`}
                        name={`requirement_site[]`}
                        imageinstitucional={requiredImagenInstitucional ? 1 : 0}
                        onChange={handleOnchangeInputFile}
                        data-parsley-required={requiredRequirement}
                        data-parsley-required-message="Por favor, selecciona un archivo"
                        data-parsley-filemimetypes={requiredImagenInstitucional ? "image/jpeg,image/png" : "application/pdf"}
                        data-parsley-filemimetypes-message={requiredImagenInstitucional ? "Solo se permiten imágenes (JPEG/PNG)" : "Solo se permiten archivos PDF"}
                      />
                    </label>
                    <span className="fs-small8em text-maroon-flush small-description">
                      7MB, Limite Máximo
                    </span>
                    <span className="fas fa-times icon-close" onClick={(e) => funciones.handleClickDeleteFile(e)}></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    );
  };
  */

  /*const handleOnchangeInputFile = (event) => {

    debugger
    const inputFile = event.target;
    if (inputFile.files.length > 0) {
      let sizeFileBytes = inputFile.files[0].size;
      const divContainerFather = inputFile.closest(".row");
      let iconFile = inputFile.parentElement.parentElement.querySelector("i");
      if (iconFile) inputFile.parentElement.parentElement.removeChild(iconFile);
      if (sizeFileBytes > 0 && sizeFileBytes <= 7000000) {

        if (inputFile.files[0].name.includes("https") || inputFile.files[0].name.includes("http")) {
          let nameFile = inputFile.files[0].name.split("/");
          nameFile = nameFile[nameFile.length - 1];
          divContainerFather.firstChild.children[3].innerHTML = `${nameFile.substring(
            nameFile.length - 20,
            nameFile.length
          )}`;
        } else {
          divContainerFather.firstChild.children[3].innerHTML = `${inputFile.files[0].name.substring(inputFile.files[0].name.length, 15)}`;
        }

        const format = inputFile.files[0].type.split("/");

        const imagenInstitucional = inputFile.getAttribute("imageinstitucional");
        if (parseInt(imagenInstitucional) === 1 && /^(pdf)$/i.test(format[1])) {
          toast.warning("Imagen es requerido");
          inputFile.value = '';
          return;
        }

        if (/^(pdf|gif|jpe?g|png)$/i.test(format[1])) {
          const icono = document.createElement("i");
          if (format[1] === "pdf") {
            icono.className = "fas fa-file-pdf icon";
          }

          if (/^(gif|jpe?g|png)$/i.test(format[1])) {
            icono.className = "fas fa-file-image icon";
          }
          inputFile.parentElement.parentElement.appendChild(icono);
        }
      } else {
        inputFile.value = null;
        divContainerFather.firstChild.children[3].innerHTML = "";
        toast.error("Formato no valido o excede los 7MB el archivo");
      }
    }
  };*/

  const nextForm = (event) => {
    debugger
    event.preventDefault();
    let inputsDocuments = document.getElementsByName("requirement_site[]");
    const inputFiles = [];
    Array.from(inputsDocuments).filter((input) => {
      if (input.files.length > 0) {
        inputFiles.push({
          id: input.getAttribute("id"),
          file: input.files[0]
        });
      }
    });

    window.scrollTo(0, 0);

    if (inputFiles.length > 0) {
      nextClick(event, stepNumber, true, inputFiles)
    } else {
      toast.error("Adjunte los requisitos solicitados, en formato PDF");
      event.preventDefault();
    }
  }

  const loadRequirements = async (tokenAdvertising) => {
    const query = consultaSQL.queryRequirementsSiteAdvertising(tokenAdvertising, "REQUIREMENT");
    const requirement = await fetch.fetchPostGraphQl(query, "/graphql", null);
    if (requirement && requirement.requirementsSiteAdvertising && requirement.requirementsSiteAdvertising.result) {
      const respRequisitos = requirement.requirementsSiteAdvertising.data.advertisingRequirementSite;
      return respRequisitos;
    }
    return null;
  };

  return (
    <div className="main-wizard active-f-w">
      <HeaderTitlecard titulo={'Requisitos de Emplazamiento'} descripcion={detalle} visibleRequired={true} />

      <form
        className="contact__form"
        id={"formValideRequirement"}
      >
        <div className="mt-4 mb-4">
          {nodeRequisitos}
        </div>

        <div className="buttons text-end">
          <button className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
        </div>
      </form >
    </div>
  );
};

export default Step2EditFormRequisitos;
