import React, { useState, useEffect, useRef } from "react";
import Links from "./../../data/link";
import DatePickerRangeAtm from "./../component/DatePickerRangeAtm";
import HeaderTitlecard from "./_common/HeaderTitleCard";
import Consulta from "./Consulta";
import Config from "./../../data/config";
import Constant from "../../data/constant";

const Step2EmpresaElementoFormValideDatosTecnicos = (props) => {
    const { Texto, fetch, toast, nextClick, backClick, auth, history, dataForm, stepNumber, fechaFormateado } = props;

    /*const idMapAdvertising = "idMapCatastroPublicAdvertising";
    const idMapActEco = "idMapCatastroPublicAdvertising";
    const TYPE_MAP_ACT_ECO = "ACT_ECO";
    const TYPE_MAP_ADV = "ADV";*/
    const consultaSQL = new Consulta();

    const urlPdfRepositorioMain = `${Config[10].url}`;
    const idImgLocationImg = "imgAdvertising01";

    const dataCrape = dataForm.admision.dataAdvetisingCrape;
    const dataTechnical = dataCrape.AdDataTechnical;
    const dataUbicacion = dataTechnical.AdUbicacions;

    const [optionsCategory, setOptionsCategory] = useState([]);
    const [optionsCategorization, setOptionsCategorization] = useState([]);
    const [optionsCaracterization, setOptionsCaracterization] = useState([]);
    const [optionComunicationInstitutional, setOptionComunicationInstitutional] = useState([]);
    const [dataInmuebleAdvertising, setDataInmuebleAdvertising] = useState({});
    const [dataActividadEconomicaRuat, setDataActividadEconomicaRuat] = useState(undefined);
    const [totalDias, setTotalDias] = useState({ mes: 0, dias: 0 });
    const [messageRangeDate, setMessageRangeDate] = useState("");
    const [validateRangeDate, setValidateRangeDate] = useState(null);

    const refSelectCategory = useRef(null);
    const refInputNroActividadEco = useRef(null);
    const refSelectCaracterization = useRef(null);
    const refSelectCategorization = useRef(null);
    const refSelectComunicationInst = useRef(null);
    const refInputAncho = useRef(0);
    const refInputAlto = useRef(0);
    const refInputDimension = useRef(0);
    const refInputDireccion = useRef(null);
    const refInputDireccionY = useRef(null);
    const refInputDireccionEntre = useRef(null);
    const refInputRazonSocial = useRef(null);

    const maxDateDatePicker = new Date();
    maxDateDatePicker.setMonth(maxDateDatePicker.getMonth() + 11);

    const minDateDatePicker = new Date();
    minDateDatePicker.setDate(1);

    useEffect(() => {

        if (!auth.loggedIn()) history.replace(Links[4].url);

        if (dataForm) {
            debugger
            optionsCategoryForClasification();

            refInputAlto.current.value = dataTechnical.alto;
            refInputAncho.current.value = dataTechnical.ancho;
            refInputDimension.current.value = dataTechnical.dimension;
            refInputDireccion.current.value = dataUbicacion[0].direccion;
            refInputDireccionEntre.current.value = dataUbicacion[0].direccionEntre;
            refInputDireccionY.current.value = dataUbicacion[0].direccionY;
            refInputNroActividadEco.current.value = dataCrape.AdActividadEconomicaRuat ? dataCrape.AdActividadEconomicaRuat.nroActividad : ""

            eventInputSearchActEconomica();
            handleImageDefault();
            setDataInmuebleAdvertising(dataTechnical);
        }

    }, [dataForm]);

    const handleImageDefault = () => {
        debugger
        const urlImg = dataUbicacion.length > 0 ? dataUbicacion[0].urlImagen : null;
        const urlFileImage = urlPdfRepositorioMain + urlImg;

        if (urlImg && urlFileImage) {
            const imgIconButton = document.getElementById(idImgLocationImg);
            imgIconButton.src = urlFileImage;
            imgIconButton.classList.remove("d-none");
        }
    }

    const optionsCategoryForClasification = () => {
        debugger

        let idCategory = dataCrape.clasificationCategoriesAdv[0].clasificationCategoryPublicity.categoryPublicity.id;
        let idClasification = (dataForm ? dataForm.admision.clasification.id : null);
        let idFatherCategoryPublicity = null;

        let query = consultaSQL.queryCategoryPublicityForClasificationAndCategory(idClasification, idCategory);

        fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {
            debugger
            if (data.categoryPublicityForClasificationAndCategory && data.categoryPublicityForClasificationAndCategory.result) {

                const categorySelected = data.categoryPublicityForClasificationAndCategory.data.clasificacionCategoryPublicity[0].categoryPublicity;
                const categoryOption = <option
                    key={categorySelected.id}
                    value={categorySelected.id}
                    name={categorySelected.name}
                >
                    {categorySelected.name}
                </option>

                idFatherCategoryPublicity = categorySelected.idFatherCategoryPublicity

                if (idFatherCategoryPublicity > 0) {
                    query = consultaSQL.queryCategoryPublicityForClasificationAndCategory(idClasification, idFatherCategoryPublicity);
                    fetch.fetchPostGraphQl(query, "/graphql", null).then((data) => {
                        if (data.categoryPublicityForClasificationAndCategory && data.categoryPublicityForClasificationAndCategory.result) {
                            const categoryGroup = data.categoryPublicityForClasificationAndCategory.data.clasificacionCategoryPublicity[0].categoryPublicity;
                            const categoryGroupOption = <optgroup
                                key={categoryGroup.id}
                                value={categoryGroup.id}
                                label={categoryGroup.name}>
                                {categoryOption}
                            </optgroup>

                            setOptionsCategory(categoryGroupOption);
                            refSelectCategory.current.value = idCategory;
                        }
                    })
                }

                const idCaracterization = dataCrape.clasificationCategoriesAdv[0].clasificationCategoryCaracterizacion.caracterization.id;
                loadOptionCaracterization(idCategory, idCaracterization);
                const idCategorization = dataCrape.categoryPublicityCategorizationAdv.AdCategorization.id;
                loadCategorization(idCategory, idCategorization)
                const idComunicationInst = dataCrape.categoryPublicityComunicationAdv.AdComunicationInstitutional.id;
                loadComunicationInstitutional(idCategory, idComunicationInst)
            }
        });
    }

    const loadOptionCaracterization = (idCategoryPublicity, caracterizationId) => {
        debugger

        const query = `query{ caracterizationByCategoryPublicity(categoryPublicity: {id: ${idCategoryPublicity}}) { message result data {caracterization {  id  name  description  AdClasificationCatPubliCaracterizations{ id idCaracterization idClasificationCategory valor  }} }  }}`;
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.caracterizationByCategoryPublicity && data.caracterizationByCategoryPublicity.result) {
                    const caracterization = data.caracterizationByCategoryPublicity.data.caracterization;
                    const listFilter = caracterization.filter((row) => row.id === caracterizationId);
                    let listItems = listFilter.map((item, index) => {
                        const clasCatPubli = item.AdClasificationCatPubliCaracterizations[0];
                        return (<option
                            key={index}
                            value={item.id}
                            label={item.name}
                            valor={clasCatPubli.valor}
                            description={item.description}
                        >
                            {item.name}
                        </option>);

                    });
                    setOptionsCaracterization(listItems);
                    refSelectCaracterization.current.value = caracterizationId;
                }
            });
    }

    const loadCategorization = (idCategoryPublicity, idCategorization) => {
        debugger
        const query = `query{  categorizationByCategoryPublicity (categoryPublicity: {id: ${idCategoryPublicity}} ) {    message    result    data {      categorization {        name        id      }    }  }}`;
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                if (data.categorizationByCategoryPublicity && data.categorizationByCategoryPublicity.result) {
                    const categorizationList = data.categorizationByCategoryPublicity.data.categorization;
                    const listItemsFilter = categorizationList.filter(row => row.id === idCategorization)
                    let listItems = listItemsFilter.map((item, index) => {
                        return (<option
                            key={index}
                            value={item.id}
                            label={item.name}
                        >
                            {item.name}
                        </option>);

                    });
                    setOptionsCategorization(listItems);
                    refSelectCategorization.current.value = idCategorization;
                }
            });
    }

    const loadComunicationInstitutional = (idCategoryPublicity, idComunicationInst) => {
        debugger
        const query = `query{comunicationInstitutionalByCategoryPublicity (categoryPublicity: {id: ${idCategoryPublicity}}) {message    result    data {  comunicationInstitutional { name    id}}}}`;
        fetch
            .fetchPostGraphQl(query, "/graphql", null)
            .then((data) => {
                debugger
                if (data.comunicationInstitutionalByCategoryPublicity && data.comunicationInstitutionalByCategoryPublicity.result) {
                    const comunicationInstList = data.comunicationInstitutionalByCategoryPublicity.data.comunicationInstitutional;
                    const listItemsFilter = comunicationInstList.filter(row => row.id === idComunicationInst)
                    let listItems = listItemsFilter.map((item, index) => {
                        return (<option
                            key={index}
                            value={item.id}
                            label={item.name}
                        >
                            {item.name}
                        </option>);
                    });
                    setOptionComunicationInstitutional(listItems);
                    refSelectComunicationInst.current.value = idComunicationInst;
                }
            });
    }

    const handleClickInfoCaracterization = (event) => {
        event.preventDefault();
        debugger
        const button = event.target;
        const selectCaract = button.closest(".input-group").firstChild.selectedOptions[0];
        const description = selectCaract.getAttribute("description");
        if (description)
            window.alertBootBox(description);
    }
   
    const eventInputSearchActEconomica = async () => {
        debugger
        refInputRazonSocial.current.value = "";
        setDataActividadEconomicaRuat(undefined);
        let input = refInputNroActividadEco.current;

        if (input.value && input.value.length >= 3) {
            const actividadEconomicaRuat = await findActEcoByNumero(input.value);

            if (actividadEconomicaRuat) {
                //aqui buscar 
                const propietariosActEco = actividadEconomicaRuat.propiedades;   //solo tomar el primero
                const ubicacionActEco = actividadEconomicaRuat.datosUbicacion;

                debugger
                refInputRazonSocial.current.value = actividadEconomicaRuat.tipoActividad + "(" + actividadEconomicaRuat.razonSocial + ")";
                // actividadEconomicaRuat.razonSocial + "(" + actividadEconomicaRuat.tipoActividad + ")";            

                if (propietariosActEco.length > 0 && propietariosActEco[0].codigoContribuyente) {
                    const contribuyente = await findContribuyenteByCodContribuyente(propietariosActEco[0].codigoContribuyente);
                    let ci = 0;
                    if (contribuyente && contribuyente.natural)
                        ci = contribuyente.natural.numeroDocumento;
                    actividadEconomicaRuat.tipoContribuyente = contribuyente.tipoContribuyente;
                    setDataActividadEconomicaRuat(actividadEconomicaRuat);
                }
                debugger
            }
        }
    }

    const handleOpenImageAdvertisingBase64 = (event) => {
        const link = event.target;
        const image = new Image();
        image.src = link.src;
        const w = window.open("");
        w.document.write(image.outerHTML);
    }

    const findActEcoByNumero = async (numeroAct) => {
        const resActEco = await fetch.fetchGet(
            `api/actividad-economica/get-by-nro-act-eco-ruat/${numeroAct}`
        );
        if (resActEco && resActEco.status) {
            if (resActEco.ActividadEconomica)
                return resActEco.ActividadEconomica;
        }
        return undefined;
    }

    const findContribuyenteByCodContribuyente = async (codigoContribuyente) => {

        const resActEco = await fetch.fetchGet(
            `api-ruat/contribuyentes/cc/${codigoContribuyente}`
        );
        if (resActEco && resActEco.status) {
            if (resActEco.data.contribuyente.length > 0) {
                return resActEco.data.contribuyente[0];
            }
        }
        return undefined;
    }

    const nextForm = (e) => {
        setMessageRangeDate("");
        debugger
        //const superficie = refInputSuperficie.current;
        const selectCategory = refSelectCategory.current;
        const selectCaracterization = refSelectCaracterization.current;
        const selectCategorization = refSelectCategorization.current;
        const selectComunication = refSelectComunicationInst.current;
        const inputAncho = refInputAncho.current;
        const inputAlto = refInputAlto.current;
        const inputDireccion = refInputDireccion.current;
        const inputDireccionEntre = refInputDireccionEntre.current;
        const inputDireccionY = refInputDireccionY.current;
        const inputRangeDate = document.getElementsByName("datosTecnicos-tiempo");
        let next = true;

        const data = {
            advertising: {
                category: selectCategory.selectedOptions[0].value,
                caracterization: selectCaracterization.selectedOptions[0].value,
                categorization: selectCategorization.selectedOptions[0].value,
                comunication: selectComunication.selectedOptions[0].value,
                categoryNombre: selectCategory.options[selectCategory.selectedIndex].text,
                inmueble: dataInmuebleAdvertising,
                ancho: inputAncho.value,
                alto: inputAlto.value,
                direccion: inputDireccion.value,
                direccionEntre: inputDireccionEntre.value,
                directionY: inputDireccionY.value,
                rangeDate: inputRangeDate[0].value
            },
            actividadEconomica: {
                actividadEconomicaRuat: dataActividadEconomicaRuat,   //esto ver de como fusionar
                //inmuebleActividadEconomicaSimat: dataInmuebleActEcoSimat,   //esto ver de como fusionar
                //inmuebleCatastro: dataInmuebleCatastro
            }
        }


        if (dataInmuebleAdvertising && Object.keys(dataInmuebleAdvertising).length === 0 && dataInmuebleAdvertising.constructor === Object) {
            next = false;
            toast.error("Ubicación Geoespacial de la Publicidad, es obligatorio");
        }

        if (dataActividadEconomicaRuat && Object.keys(dataActividadEconomicaRuat).length === 0 && dataActividadEconomicaRuat.constructor === Object) {
            next = false;
            toast.error("Actividad Economica no localizada, debe proceder con el trámite de una licencia.");
        }

        const existeNumeroActividad = refInputNroActividadEco.current.value.length > 0;
        const noExisteFuncionamiento = refInputRazonSocial.current.value.length === 0
        if (existeNumeroActividad && noExisteFuncionamiento) {
            next = false;
            toast.error("El nombre o razón social es obligatorio.");
        }

        if (validateRangeDate != null && !validateRangeDate) {
            next = false;
            toast.error(messageRangeDate);
        }

        window.scrollTo(0, 0);
        if (next)
            nextClick(e, stepNumber, false, data)
        else 
            e.preventDefault();
    }

    const validarRangoFechaElemento = () => {
        setMessageRangeDate("");
        let inputRangeDate = document.getElementsByName("datosTecnicos-tiempo");
        inputRangeDate = inputRangeDate[0].value
        const numeroCrape = dataForm.admision.fur;

        if (numeroCrape.length > 0 && inputRangeDate.length > 0) {
            const dateRange = inputRangeDate.split("-");
            const dateStart = fechaFormateado(dateRange[0].trim());
            const dateEnd = fechaFormateado(dateRange[1].trim());
            const query = consultaSQL.queryAdvertisingsByRangeDateEmpresarial(numeroCrape, dateStart, dateEnd);
            fetch.fetchPostGraphQl(query, "/graphql", null).then((response) => {
                if (response && response.advertisingsByRangeDateEmpresarial && response.advertisingsByRangeDateEmpresarial.result) {
                    const dataFechas = response.advertisingsByRangeDateEmpresarial.data.advertising;
                    const countAdvertising = dataFechas.length;
                    countAdvertising === 0 ? setValidateRangeDate(true) : setValidateRangeDate(false);
                    if (countAdvertising > 0) {
                        setMessageRangeDate("El rango de fecha ingresado ya se encuentro en uso.");
                    }
                }
            });
        }
    }

    return (
        <>
            <div className="main-wizard ">
                <HeaderTitlecard titulo={'Datos Técnicos (Elemento) '} descripcion={Constant[0].publicidad_step_new[1]} visibleRequired={true} />

                <form
                    className="contact__form"
                    id={"formValideDatosTecnicos"}
                >
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-category"} className="form-label">
                                (*) Tipo Publicidad
                            </label>
                            <select className="form-select" ref={refSelectCategory}
                                data-parsley-required="true" required name="datosTecnicos-category">
                                {optionsCategory}
                            </select>
                        </div>

                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-caracterization"} className="form-label">
                                (*) Caracterización
                            </label>
                            <div className="input-group ">
                                <select className="form-select" ref={refSelectCaracterization}
                                    data-parsley-required="true" required name="datosTecnicos-caracterization">
                                    {optionsCaracterization}
                                </select>

                                <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={handleClickInfoCaracterization}
                                >
                                    <i className="fas fa-info-circle" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-categorization"} className="form-label">
                                (*) Categorización
                            </label>
                            <select className="form-select" ref={refSelectCategorization}
                                data-parsley-required="true" required name="datosTecnicos-categorization">
                                <option
                                    key={0}
                                    defaultValue
                                    value=""
                                >
                                    Seleccione Categorización
                                </option>
                                {optionsCategorization}
                            </select>

                        </div>
                        <div className="col-sm-12 col-md-6">
                            <label htmlFor={"datosTecnicos-comunication-institucional"} className="form-label">
                                (*) Comunicación Institucional
                            </label>
                            <select className="form-select" ref={refSelectComunicationInst}
                                data-parsley-required="true" required name="datosTecnicos-comunication-institucional">
                                <option
                                    key={0}
                                    defaultValue
                                    value=""
                                >
                                    Seleccione Comunicación Inst.
                                </option>

                                {optionComunicationInstitutional}
                            </select>
                        </div>
                    </div>

                    <div className="row mt-2 mb-3">
                        <div className="col-sm-12 col-md-6 ">
                            <label htmlFor="datosTecnicos-tiempo" className="form-label">(*) Fechas</label>
                            <DatePickerRangeAtm
                                name={"datosTecnicos-tiempo"}
                                id={"datosTecnicos-tiempo"}
                                mandatory={true}
                                maxDate={maxDateDatePicker}
                                minDate={new Date()}
                                setTotalDias={setTotalDias}
                                validarRangoFechaElemento={(e) => validarRangoFechaElemento()}
                            />
                            {messageRangeDate && <span className="parsley-errors-list filled">{messageRangeDate}</span>}
                        </div>
                        <div className="col-sm-12 col-md-6" >
                            <label htmlFor="total_dias_info" className="form-label">(*) Mes Autorización</label>
                            <input
                                type="text"
                                id="total_dias_info"
                                className="form-control"
                                value={totalDias.mes}
                                data-parsley-required="true"
                                data-parsley-required-message="Este campo es obligatorio."
                                //data-parsley-type="number"
                                data-parsley-pattern="^\d+(.\d{1,2})?$"
                                data-parsley-type-message="Ingrese solo números."
                                min={0.01}
                            />
                        </div>
                    </div>

                    <fieldset className="mt-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark"><label>
                            {Texto.dimension_publicidad}
                        </label>
                        </legend>
                        <div className="row mt-2 mb-2">
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-ancho" className="form-label">(*) Ancho(metros)</label>
                                <input type="text"
                                    id="datosTecnicos-ancho"
                                    name="datosTecnicos-ancho"
                                    ref={refInputAncho}
                                    data-parsley-required="true"
                                    //data-parsley-type="number"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    min={0.2}
                                    max={999}
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                    ata-parsley-trigger="keyup"
                                    readOnly={true}
                                />
                            </div>

                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-largo" className="form-label">(*) Alto (metros)</label>
                                <input type="text"
                                    id="datosTecnicos-alto"
                                    name="datosTecnicos-alto"
                                    ref={refInputAlto}
                                    min={0.2}
                                    max={999}
                                    data-parsley-required="true"
                                    //data-parsley-type="integer"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    readOnly={true}
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                />
                            </div>
                            <div className="col-sm-12 col-md-4 ">
                                <label htmlFor="datosTecnicos-largo" className="form-label">(*) Superficie M2</label>
                                <input type="text"
                                    id="datosTecnicos-superficie-m2"
                                    name="datosTecnicos-superficie-m2"
                                    ref={refInputDimension}
                                    min={0.01}
                                    max={999}
                                    readOnly={true}
                                    data-parsley-required="true"
                                    //data-parsley-type="integer"
                                    data-parsley-pattern="^\d+(.\d{1,2})?$"
                                    data-parsley-integer-message="Ingrese solo ńumeros enteros"
                                />
                            </div>
                        </div>
                    </fieldset>

                    <fieldset className="mb-2">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark">
                            <label>{Texto.ubicacion_publicidad}</label>
                        </legend>

                        <div className="form-group">
                            <img
                                id={idImgLocationImg}
                                className="img-fluid mx-auto d-block pointer rounded border border-secondary d-none"
                                style={{ height: '70%' }}
                                onClick={(e) => handleOpenImageAdvertisingBase64(e)}
                                alt="img-domicilio-advertising"
                                src={""}
                            />
                            <div className="text-center flex">
                                <div id="divDescriptionMapAdv"></div>
                            </div>
                        </div>

                        <div className="">
                            <label htmlFor="datosTecnicos-direccion" className="form-label">(*) Dirección</label>
                            <input
                                type="text"
                                id="datosTecnicos-direccion"
                                name="datosTecnicos-direccion"
                                ref={refInputDireccion}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección donde se emplazará la publicidad"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                // data-parsley-maxlength-message="Debe ser un número de 4 hasta 15 dígitos."
                                autoComplete="one-time-code"
                                placeholder="Calle/Avenida/Pasaje/Parque/Plazuela/etc."
                                readOnly
                            />
                        </div>

                        <div className="mt-2">
                            <label htmlFor="adyacentes" className="form-label">(*) Adyacentes</label>
                        </div>

                        <div className="input-group">
                            <input
                                type="text"
                                id="ubicacion-adyacente_entre"
                                name="ubicacion-adyacente_entre"
                                ref={refInputDireccionEntre}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                                readOnly
                            />

                            <div className="input-group-prepend">
                                <span className="input-group-text" id="">Y</span>
                            </div>
                            <input
                                type="text"
                                id="ubicacion-adyacente_y"
                                name="ubicacion-adyacente_y"
                                ref={refInputDireccionY}
                                className={"form-control text-uppercase"}
                                required
                                data-parsley-required-message="Ingrese la dirección adyancente"
                                data-parsley-minlength="5"
                                data-parsley-maxlength="200"
                                placeholder="Calle/Avenida/Pasaje/etc."
                                autoComplete="one-time-code"
                                readOnly
                            />
                        </div>

                        <div className="row">
                            <div className="col-6" id="error_adyacente_entre"></div>
                            <div className="col-6" id="error_adyacente_y"></div>
                        </div>
                    </fieldset>


                    <fieldset className="mt-3">
                        <legend className="h6 pl-2 pr-2 pt-1 pb-1 mb-2 border-b-dark">
                            <label> Información técnica y ubicación de la Actividad Económica. </label>
                        </legend>

                        <div className="input-text">
                            <div className="input-div">
                                <label htmlFor="datosTecnicos-nroActividad" className="form-label">(*) Licencia Funcionamiento</label>
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control input-uppercase"
                                        name="datosTecnicos-nroActividad"
                                        placeholder="Nro Actividad Económica"
                                        aria-label="Nro Actividad Económica"
                                        aria-describedby="basic-addon2"
                                        readOnly
                                        data-parsley-required="true"
                                        data-parsley-type="number"
                                        ref={refInputNroActividadEco}
                                    />
                                    <div className="input-group-append">
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            title="Buscar actividad econ."
                                            onClick={eventInputSearchActEconomica}
                                        >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="input-div">
                                <label htmlFor="DatosTecnicos-actividad" className="form-label">Nombre o Razón Social</label>
                                <input
                                    type={"text"} 
                                    name="DatosTecnicos-actividad" 
                                    readOnly
                                    data-parsley-required="true"
                                    className="form-control" 
                                    ref={refInputRazonSocial} />
                            </div>
                        </div>
                    </fieldset>


                    <div className="buttons button_space justify-content-between mt-2">
                        <a className="back_button btn btn-fucsia" onClick={backClick}>Volver</a>
                        <button className="next_button btn btn-violeta" onClick={nextForm}>Continuar</button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default Step2EmpresaElementoFormValideDatosTecnicos;
