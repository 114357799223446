import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import AuthService from "../../components/Usuario/AuthService";
import Fetch from "../../components/utils/Fetch";
import { toast } from "react-toastify";
import Links from "../../data/link";
import Config from "../../data/config";
import { useLocation } from "react-router-dom";

const Header = (props) => {
  let Auth = new AuthService();
  let auth = Auth;
  let fetch = new Fetch();
  fetch.setToast(toast);
  const location = useLocation();

  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [thumbail, setThumbail] = useState("");
  let linkActive = false;

  useEffect(() => {
    if (Auth.loggedIn()) {
      let usuario = Auth.getProfile();
      loadDataUser(usuario);
    }

    window.jQuery(".nav-link").on("click", () => {
      window.jQuery(".navbar-collapse").removeClass("open");
    });
    defineActiveLinkHeader();
  }, []);

  const defineActiveLinkHeader = () => {
    const headerActive = sessionStorage.getItem("header_active");
    let mainLink = document.getElementsByClassName("main");
    if (location.pathname.includes(headerActive)) {
      Array.from(mainLink).forEach((element) => {
        element.classList.remove("active");
        if (element.getAttribute("href") === "/" + headerActive) {
          element.classList.add("active");
          linkActive = true;
        }
      });
    }

    if (!linkActive) {
      Array.from(mainLink).forEach((element) => {
        element.classList.remove("active");
        if (location.pathname.includes(element.getAttribute("href"))) {
          element.classList.add("active");
        }
      });
    }
  };

  const loadDataUser = async (usuario) => {
    const response = await fetch.axiosAsyncGet(`api/usuario/get-thumbail`);
    setUsername(usuario.username);
    setRole(usuario.code);
    if (response && response.status === true) {
      if (response.Thumbail) {
        setThumbail(response.Thumbail);
      } else {
        setThumbail(Config[2].url + "/static/img/avatar_default.avif"); //avatar_default
      }
    } else setThumbail(Config[2].url + "/static/img/avatar_default.avif");
  };

  const handleClickLogut = (event) => {
    event.preventDefault();
    auth.logout();
    window.location.href = "/";
  };

  const handleActiveLink = (event) => {
    sessionStorage.setItem("header_active", "");
    let mainLink = document.getElementsByClassName("main");
    Array.from(mainLink).forEach((element) =>
      element.classList.remove("active")
    );
    if (event) {
      event.target.classList.add("active");
      sessionStorage.setItem(
        "header_active",
        event.target.getAttribute("href").replace("/", "")
      );
    }
  };

  const { menu } = props;
  return (
    <header className="header navbar-area bg-aero">
      <nav
        className="navbar navbar-expand-lg fixed-top navbar-dark bg-aero"
        aria-label="Main navigation"
      >
        <div className="container-fluid">
          <Link to={{ pathname: "/" }} className={`navbar-brand`}>
            <img
              src={Config[2].url + "/static/img/escudo_cbba.png"}
              alt="escudo cbba"
              className="img-fluid logo-header"
              width="190"
              height="37"
            />
          </Link>
          <button
            className="navbar-toggler p-0 border-0"
            type="button"
            id="navbarSideCollapse"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="navbar-collapse offcanvas-collapse bg-aero"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {menu.map((item, key) =>
                item.session === 0 ? (
                  <li className="nav-item" key={key} session={item.session}>
                    {!item.url.includes("http") ? (
                      <Link
                        to={item.url}
                        className={`main nav-link text-white  ${key === 0 ? "active" : ""
                          }`}
                        onClick={handleActiveLink}
                      >
                        {item.title}
                      </Link>
                    ) : (
                      <a
                        className={`main nav-link text-white ${key === 0 ? "active" : ""
                          }`}
                        href={item.url}
                        title={item.title}
                        target="_blank"
                        rel="noreferrer noopener"
                        onClick={handleActiveLink}
                      >
                        {item.title}
                      </a>
                    )}
                  </li>
                ) : (
                  ""
                )
              )}

              {Auth.loggedIn()
                ? menu.map((item, key) =>
                  item.session === 1 ? (
                    <li className="nav-item" key={key}>
                      <Link
                        to={item.url}
                        className={`main nav-link text-white`}
                        onClick={handleActiveLink}
                      >
                        {item.title}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )
                )
                : ""}
            </ul>
            {Auth.loggedIn() ? (
              <>
                <div className="avatar-d  d-none .d-sm-block d-md-none d-lg-block">
                  <Link
                    to={{ pathname: "" }}
                    className={`nav-link dropdown-toggle avatar-d`}
                    id="navbarDropdownMenuLink-d"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={thumbail}
                      className="rounded-circle z-depth-0 logo-user"
                      alt="avatar"
                      width="43"
                      height="43"
                    />
                  </Link>

                  <div className="session-container" aria-labelledby="">
                    <div className="session-container-body">
                      <div className="client-card">
                        <div className="client-card__picture">
                          <img
                            alt="gravatar"
                            title="avatar"
                            src={thumbail}
                            className="logo-user"
                          />
                        </div>
                        <div className="client-card__details">
                          <div className="">{role}</div>
                          <div className="">{username}</div>
                        </div>
                      </div>

                      <div className="client-card__profile-routes">
                        <Link
                          to={{ pathname: Links[8].url }}
                          className={`dropdown-item text-butterfly-bush`}
                        >
                          Perfil
                        </Link>
                        <Link
                          to={{ pathname: "#" }}
                          className={`dropdown-item text-butterfly-bush`}
                          onClick={handleClickLogut}
                        >
                          Salir
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-lg-none d-xl-block d-xl-none d-xxl-block d-xxl-none">
                  <li className="nav-item">
                    <Link
                      to={{ pathname: Links[8].url }}
                      className={`nav-link text-white`}
                    >
                      Perfil
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={{ pathname: "#" }}
                      className={`nav-link text-white`}
                      onClick={handleClickLogut}
                    >
                      Salir
                    </Link>
                  </li>
                </ul>
              </>
            ) : (
              <form className="d-flex single-app-button">
                <Link to={"/login"} className={`btn`}>
                  <i className="fas fa-unlock-alt"></i>
                  <span className="text">Ingresar</span>
                </Link>
              </form>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  menu: PropTypes.array.isRequired,
};

export default Header;
